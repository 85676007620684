<app-user-header></app-user-header>
<div class="" *ngIf="feedbackValues">
<div class="bg-container header pnset-6 container-fluid pt-4 ps-4 pe-4">
  <div class="col-12 row pb-4">
      <div class="col-12 col-md-7 page-title">
          <p class="page-title h2 text-white d-inline-block mb-0">Palautekyselyt</p><br>
          <button *ngIf="order && feedbacks.length < order.web.questionNumber" routerLink="/{{businessID}}/create-feedback" class="btn btn-feedbacker mt-4" ><i class="fas fa-plus-circle me-2"></i>Luo kysely</button>
          <button *ngIf="order && !feedbacks.length" routerLink="/{{businessID}}/create-feedback" class="btn btn-feedbacker mt-4" ><i class="fas fa-plus-circle me-2"></i>Luo ensimmäinen kysely</button>

      </div>
      <div class="col">
          <nav class="d-none d-md-inline-block ml-md-4">
              <ol class="breadcrumb breadcrumb-links breadcrumb-dark">
                  <li class="breadcrumb-item">
                      <a routerLink="/{{businessID}}/users/home">Koti</a>
                  </li>
                  <li class="breadcrumb-item active">
                      <a href="#">Palautekyselyt</a>
                  </li>
              </ol>
          </nav>  
      </div>
  </div>
  <div class="col-12 row m-0">
      <div class="col-xl-3 col-md-6">
          <div class="card card-stats p-4">
              <div class="row">
                  <div class="col">
                      <h5 class="card-title text-uppercase text-muted mb-0">Lukumäärä</h5>
                      <span *ngIf="order" class="h2 font-weight-bold mb-0">{{feedbacks.length}} / {{order.web.questionNumber}}</span>
                  </div>
                  <div class="col-auto">
                      <div class="nset-icon icon-shape bg-gradient-red shadow">

                      </div>
                  </div>
                  <p class="mt-3 mb-0 text-sm">
                      <!-- <span class="text-success me-2">10%</span> -->
                      <span class="text-nowrap">Yhteensä</span>
                  </p>
              </div>
          </div>
      </div>
      <div class="col-xl-3 col-md-6">
          <div class="card card-stats p-4">
              <div class="row">
                  <div class="col">
                      <h5 class="card-title text-uppercase text-muted mb-0">Vastausaste</h5>
                      <span class="h2 font-weight-bold mb-0" *ngIf="feedbackValues && feedbackValues.company && feedbackValues.company.answer_rate">{{feedbackValues.company.answer_rate.toFixed(2)*100}}</span>
                  </div>
                  <div class="col-auto">
                      <div class="nset-icon icon-shape bg-gradient-orange shadow">

                      </div>
                  </div>
                  <p class="mt-3 mb-0 text-sm">
                      <!-- <span class="text-success me-2">10%</span> -->
                      <span class="text-nowrap">Yhteensä</span>
                  </p>
              </div>
          </div>
      </div>
      <div class="col-xl-3 col-md-6">
        <div class="card card-stats p-4">
            <div class="row">
                <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">NPS</h5>
                    <span class="h2 font-weight-bold mb-0" *ngIf="feedbackValues && feedbackValues.company && feedbackValues.company.nps">{{(feedbackValues.company.nps * 100).toFixed(2)}}</span>
                </div>
                <div class="col-auto">
                    <div class="nset-icon icon-shape bg-gradient-green shadow">

                    </div>
                </div>
                <p class="mt-3 mb-0 text-sm">
                    <!-- <span class="text-success me-2">10%</span> -->
                    <span class="text-nowrap">Yhteensä</span>
                </p>
            </div>
        </div>
    </div>
    <div class="col-xl-3 col-md-6">
        <div class="card card-stats p-4">
            <div class="row">
                <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">KESKIARVO</h5>
                    <span class="h2 font-weight-bold mb-0" *ngIf="feedbackValues && feedbackValues.company && feedbackValues.company.average">{{feedbackValues.company.average.toFixed(2)}}</span>
                </div>
                <div class="col-auto">
                    <div class="nset-icon icon-shape bg-gradient-info shadow">

                    </div>
                </div>
                <p class="mt-3 mb-0 text-sm">
                    <!-- <span class="text-success me-2">10%</span> -->
                    <span class="text-nowrap">Yhteensä</span>
                </p>
            </div>
        </div>
    </div>
  </div>
</div>

<div class="col-12 p-0 p-md-4">
  <div class="card ">
      <div class="card-header">
          <div class="row align-items-center">
              <div class="col">
                  <h6 class="text-dark text-uppercase ls-1 mb-1">Palautekyselyt</h6>
                  <h5 class="h3 text-black mb-0">Tiedot</h5>
              </div>
              <div class="col" *ngIf="order && order.web.payingCustomer">
                <ul class="nav nav-pills justify-content-end">
                    <li class="nav-item mr-2 mr-md-0">
                        <button (click)="compare()" class="btn-feedbacker">Vertaa</button>
                    </li>
                </ul>
            </div>
          </div>
          <div class="table-responsive" *ngIf="feedbacks.length > 0;else no_answers_content">
              <table class="table align-items-center table-flush">
                  <thead class="thead-light">
                      <tr>
                          <th>Valitse</th>
                          <th>Kysymys</th>
                          <th>Vastauksia</th>
                          <th>Nimi</th>
                          <th>Tyyppi</th>
                          <th>Tiedot</th>
                      </tr>
                  </thead>
                  <tbody class="tbody-light">
                      <tr *ngFor="let data of feedbacks">
                          <td><input (click)="addType(data, $event)" [disabled]="type != 'none' && data.type != type" class="nset-input" id="{{data._id}}" type="checkbox"></td>
                          <th class="pointer" routerLink="/{{businessID}}/feedback/{{data._id}}">{{data.question}}</th>
                          <td>{{data.answers.length}}</td>
                          <td>{{data.identifier}}</td>
                          <td>
                            <span *ngIf="data.type == 'smile'">Hymynaama</span>
                            <span *ngIf="data.type == 'nps'">NPS</span>
                            <span *ngIf="data.type == 'thumb'">Peukalo</span>
                            
                          </td>
                          <td><button routerLink="/{{businessID}}/feedback/{{data._id}}" class="btn-feedbacker">Tiedot</button></td>
                      </tr>
                  </tbody>

              </table>
          </div>
          <ng-template #no_answers_content>
              <div class="col-12 row m-0">
                  <div class="col-3"></div>
                  <div class="col-12 col-md-6">
                      <div class="alert alert-warning">
                          <p class="normal-text text-center">Et ole luonut kyselyitä.</p>
                      </div>
                  </div>
                  <div class="col-3"></div>
              </div>
          </ng-template>
      </div>
      
  </div>
</div>
</div>







