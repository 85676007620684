import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';


@Component({
  selector: 'app-business-spinners',
  templateUrl: './business-spinners.component.html',
  styleUrls: ['./business-spinners.component.css']
})
export class BusinessSpinnersComponent implements OnInit {

  

  errMessage = "";
  successMessage = "";
  token:string = this.route.snapshot.params.token;
  businessID: string = this.route.snapshot.params.businessID;
  type: string = this.route.snapshot.params.type;
  selection: string = this.route.snapshot.params.selection;
  company: string = this.route.snapshot.params.company;
  interval: string = this.route.snapshot.params.interval;
  typeText;
  myUUID;
  spinner;
  closeText = false;
  timer;
  slideIndex = 0;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    withCredentials: false,
  };

  constructor(
    private router: Router,
    private http: HttpClient,
    private route:ActivatedRoute,
    private authService: AuthService) {
      this.authService.validateBusiness(this.businessID)

     }

  ngOnInit(): void {
    this.getSpinner();

  }

  getSpinner() {
    var req = {
      businessID: this.businessID,
      token: this.token,
    }

    this.http.post(environment.OPEN_SPINNER,req, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {
          

          this.spinner = res.message[0];
          console.log(this.spinner.answers);
          console.log(this.spinner)

          this.waitStyle();



        }
        else if (res.status == 203){
          this.typeText = "Authentication failed"
        }
        else {
          var anElm = document.getElementById('notadded');
          anElm.style.display="block";
          console.log('ei ole kyseistä käyttäjää')
        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })

  }

  waitStyle() {
    var that = this;

    setTimeout(() =>{
      that.startLoop()
      that.intervalStart()
      that.styleItem()
      
    },100)


  }
  styleItem(){
    document.getElementById('nset-bg').style.backgroundColor = this.spinner.backgroundColor;

    for (let i = 0; i < this.spinner.answers.length; i++) {
      document.getElementById('nset-text-'+i).style.color = this.spinner.textColor;
      document.getElementById('nset-author-'+i).style.color = this.spinner.textColor;
  
      
    }
  }

  startLoop(){
    this.slideIndex = 1;
    this.showSlides(this.slideIndex);
  }

  showSlides(n){
    var i;
    var slides = document.getElementsByClassName("mySlides");
    var dots = document.getElementsByClassName("dot");
    if (n > slides.length) {this.slideIndex = 1}    
    if (n < 1) {this.slideIndex = slides.length}
    for (i = 0; i < slides.length; i++) {
        var elm = <HTMLDivElement>slides[i];
        elm.style.display = "none";  
    }
    for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
    }
    var h = <HTMLDivElement>slides[this.slideIndex-1];
    h.style.display = "block";  
    dots[this.slideIndex-1].className += " active";
    
  }

  intervalStart(){

    var that = this;
    
      setInterval(function(){ 
          that.plusSlides(1);
          console.log(1);
      }, 4000);
  }

  plusSlides(n) {
    this.showSlides(this.slideIndex += n);
  }

  currentSlide(n) {
    this.showSlides(this.slideIndex = n);
  }

}
