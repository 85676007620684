import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';

@Component({
  selector: 'app-create-type',
  templateUrl: './create-type.component.html',
  styleUrls: ['./create-type.component.css']
})
export class CreateTypeComponent implements OnInit {

  form: FormGroup;
  message: string;
  authToken = localStorage.getItem('auth');
  passwordErr = "";
  messageErr = "";
  businessID = this.authService.getTokenData().businessID;
  userID: string = this.route.snapshot.params.id;
  btnText;
  user = [];

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'auth': `${this.authToken}` }),
    withCredentials: true,
  };

  constructor( 
    private fb: FormBuilder,
    private router: Router,
    private http: HttpClient,
    private route:ActivatedRoute,
    private authService: AuthService) { }

    ngOnInit(): void {
      this.form = this.fb.group({
        name: ['', [
          Validators.required
        ]],
  
      });
  
      if(this.userID){
        this.btnText = "Muokkaa flowta"
        //this.fetchUserData();
      }
      else{
        this.btnText = "Lisää flow"
      }
    }
    get name() { return this.form.get('name') };
    onSubmit(){
      if(this.userID){
        this.editUser();
      }
      else{
        this.addType()
      }
    }

    editUser(){
/*       var t = false;
  
      console.log('Submitting');
      if (!this.form.valid) {
        console.log('Form not valid. Please check that fields are correctly filled in');
        return;
      }
  
      var formAc = document.getElementById('form-container');
      formAc.classList.add("d-none");
      document.getElementById('loader').style.display="block";
  
  
      var req = {
        email: this.form.get('email').value,
        userName: this.form.get('email').value,
        firstName: this.form.get('firstName').value,
        lastName: this.form.get('lastName').value,
      }
  
        this.http.put(environment.USER, req, this.httpOptions).subscribe(
          (res: any) => {
            if (res.status == 200) {
              alert('Muokkaus onnistui. Paina OK siirtyäksesi katsomaan tietoja');
              this.router.navigate(['/'+this.businessID+'/company-details']);
            } else {
              alert('Wopsaa virhe tapahtui muokastessa käyttäjää')
              document.getElementById('loader').style.display="none";
              formAc.classList.remove("d-none");
            }
          }, (err: any) => {
  
            console.log("Error while login :: ", err);
          }
        ) */
  
    }

    addType() {

      var t = false;
  
      console.log('Submitting');
      if (!this.form.valid) {
        console.log('Form not valid. Please check that fields are correctly filled in');
        return;
      }
  
      var formAc = document.getElementById('form-container');
      formAc.classList.add("d-none");
      document.getElementById('loader').style.display="block";
  
  
      var req = {
        name: this.form.get('name').value,
      }
  
        this.http.post(environment.TYPE, req, this.httpOptions).subscribe(
          (res: any) => {
            console.log("Register success");
            if (res.status == 200) {
              alert('Tyyppi lisätty onnistuneesti');
              this.router.navigate(['/'+this.businessID+'/types']);
            } else {
              alert('Virhe lisätessä flowta')
              document.getElementById('loader').style.display="none";
              formAc.classList.remove("d-none");
            }
          }, (err: any) => {
  
            console.log("Error while login :: ", err);
          }
        )
  
      }
  

}
