<app-not-auth-header></app-not-auth-header>


<div class="wrapperi">
  <div class="container h-75">
    <div class="wrap">

      <div class="col-12 m-0 row">
        <div class="col-3"></div>
        <div class="col-12 col-md-6 p-0 mt-4">
          <div class="col-12 text-center p-0">
            <h4 class="header-text mt-4">Vaihda salasana!</h4>
            <p class="desc-text">Syötä haluamasi uusi salasana.</p>
            <div class="alert alert-success" *ngIf="successMessage != '' ">
              <p class="text-center">{{successMessage}}</p>
              <div class="d-flex justify-content-center">
                <button routerLink="/{{businessID}}/login" class=" btn btn-feedbacker"><i class="fas fa-sign-in-alt me-2"></i>Kirjaudu sisään tästä</button>
              </div>
            </div>
            <div class="alert alert-danger" *ngIf="errMessage != '' ">
              <p class="text-center">{{errMessage}}</p>
            </div>
          </div>
        </div>
        <div class="col-3"></div>

      </div>

      <div class="col-12 p-0 row m-0">
        <div class="col-3"></div>
        <div class="col-12 col-md-6 p-0">
          <div class="col-12 p-0 ">
            <div class="col-12 login-form-bg">
              <div id="loader" class="loader-container mt-4 mb-4" style="display:none">
                <div class="loader mx-auto"></div>
                <p class="body2b-b text-center mt-4 mb-4">Salansanaasi uusitaan...</p>
              </div>
              <form class="flex-row " id="new-pwd-form" [formGroup]="form" (ngSubmit)="changePassword()">
              <!-- Salasana -->
              <div class="col-12 row m-0 pt-2">
                <div class="form-floating mb-2  col-12 col-md-12 p-0">
                    <input type="password" placeholder="Salasana" formControlName="password" id="password" class="form-control" [class.invalid-input]="password.errors && password.touched" required>
                    <label for="password">Salasana (8 merkkiä)</label>
                    <div class="my-alerts" *ngIf="password.invalid && password.touched">
                    <p class="mt-2" *ngIf="password.errors.required">Salasana on pakollinen</p>
                    <p *ngIf="password.errors.password"> Vaaditaan kunnollinen salasana</p>
                    </div>
                </div>
              </div>
              <!-- Salasana uudelleen -->
            <div class="col-12 row m-0 pt-2">
              <div class="form-floating mb-2  col-12 col-md-12 p-0">
                  <input type="password" placeholder="Salasana uudelleen" formControlName="password2" id="password2" class="form-control" [class.invalid-input]="password2.errors && password2.touched" required>
                  <label for="password2">Salasana uudelleen (8 merkkiä)</label>
                  <div class="my-alerts" *ngIf="password2.invalid && password2.touched">
                  <p class="mt-2" *ngIf="password2.errors.required">Salasana on pakollinen</p>
                  <p *ngIf="password2.errors.password2"> Vaaditaan kunnollinen salasana</p>
                  </div>
              </div>
            </div>

              <div class=" mx-auto mt-4">
                <div class="text-center" >
                  <button  [disabled]="form.invalid" class="btn btn-feedbacker w-100"   ><i class="far fa-paper-plane me-2"></i> Uusi salasana</button>
                </div>
              </div>
            </form>
          
        </div>
          </div>
        </div>
        <div class="col-3"></div>

      </div>

      <div class="d-flex justify-content-center flex-row ">
        <div class="link-container">

        </div>
      </div>
      </div>
    </div>
  </div>
