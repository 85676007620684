
<!--Navbar-->
<nav id="main-nav" class="navy-blue navbar navbar-expand-lg original-nav navbar-light pb-0 height">
    <div class="container-fluid">
    <!-- Navbar brand -->
    <a class="navbar-brand ps-md-4 me-auto pb-0 pt-0" routerLink="/{{businessID}}/users/home"><img width="25%" src="../../assets/images/moodyset_logo_w.png"> </a>
  
    <!-- Collapse button -->
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#basicExampleNav"
      aria-controls="basicExampleNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="line"></span>
      <span class="line"></span>
      <span class="line3"></span>
    </button>
  
    <!-- Collapsible content -->
  
    <div class="collapse navbar-collapse " id="basicExampleNav">
  
      <!-- Links -->
      <ul class="navbar-nav ms-auto">
        <li class="nav-item single-item p-2">
          <a class="nav-link" id="home" routerLink="/{{this.businessID}}/users/home">Koti
          </a>
        </li>
        <li class="nav-item single-item p-2">
          <a class="nav-link" id="feedbacks" routerLink="/{{businessID}}/payments">Maksut</a>
        </li>
        <li class="nav-item single-item p-2">
          <a class="nav-link" id="devices" routerLink="/{{businessID}}/reservations">Varaukset</a>
        </li>
        <li class="nav-item single-item p-2">
          <a class="nav-link" id="business" routerLink="/{{businessID}}/calendars">Kalenterit</a>
        </li>
        <li class="nav-item single-item p-2">
          <a class="nav-link" id="flow" routerLink="/{{businessID}}/types">Flowt</a>
        </li>
        <li class="nav-item single-item p-2">
          <a class="nav-link" id="flow" routerLink="/{{businessID}}/company-details">Yritys</a>
        </li>

<!--         <li class="nav-item single-item p-2">
          <a class="nav-link" id="q-a" routerLink="/{{this.businessID}}question-answer">Q&A</a>
        </li> -->
        <li class="nav-item single-item p-2 ms-0 ms-md-4">
          <a class="nav-link ms-0 logout-btn" (click)="logout()" id="logout-btn"><i class="fas fa-sign-out-alt"></i></a>
        </li>
  
      </ul>
      <!-- Links -->
  
    </div>
    <!-- Collapsible content -->
    </div>
  </nav>
  
  
  