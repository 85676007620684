<app-not-auth-header></app-not-auth-header>


<div class="wrapperi">
	<div class="container h-75">
		<div class="wrap">

			<div class="col-12 m-0 row">
				<div class="col-3"></div>
				<div class="col-12 col-md-6 p-0 mt-4">
					<div class="col-12 text-center p-0">
						<h4 class="header-text mt-4">Salasana unohtunut</h4>
						<p class="desc-text">Saat sähköpostiisi aktivointilinkin</p>
            <div class="alert alert-success" *ngIf="successMessage != '' ">
              <p class="text-center">{{successMessage}}</p>
            </div>
					</div>
				</div>
				<div class="col-3"></div>

			</div>

			<div class="col-12 p-0 row m-0">
				<div class="col-3"></div>
				<div class="col-12 col-md-6 p-0">
					<div class="col-12 p-0 ">
						<div class="col-12 login-form-bg">
							<div id="loader" class="loader-container mt-4 mb-4" style="display:none">
								<div class="loader mx-auto"></div>
								<p class="body2b-b text-center mt-4 mb-4">Linkkiä lähetetään...</p>
							</div>
							<form class="flex-row " id="forgot-form" [formGroup]="forgotForm"
							(ngSubmit)="forgotPassword()">
							<div class="col-12 row m-0 pt-2">
								<div class="form-floating mb-2  col-12 col-md-12 p-0">
									<input type="email" placeholder="Sähköposti" formControlName="userName" id="userName" class="form-control" [class.invalid-input]="userName.errors && userName.touched" required>
									<label for="userName">Sähköposti</label>
								</div>
							</div>
							<div class=" mx-auto mt-4">
								<div class="text-center" >
									<button  [disabled]="forgotForm.invalid" class="btn btn-feedbacker w-100"><i class="far fa-paper-plane me-2"></i>Lähetä</button>
								</div>
							</div>
						</form>
					
				</div>
					</div>
				</div>
				<div class="col-3"></div>

			</div>

			<div class="d-flex justify-content-center flex-row ">
				<div class="link-container">

				</div>
			</div>
			</div>
		</div>
	</div>