import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router,ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../service/auth.service';
import { JwtHelperService } from "@auth0/angular-jwt";


const helper = new JwtHelperService;


@Injectable({
  providedIn: 'root'
})


export class NotAuthenticatedGuard implements CanActivate {

  authToken = localStorage.getItem('auth');
  currentUserId="";
  designation="";
  clinicID: string = this.route.snapshot.params.clinicID;


  constructor(
    private router: Router, 
    private route: ActivatedRoute,
    private authService: AuthService) { }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (this.authService.isUserLoggedIn()) {

      var token = localStorage.getItem('auth');
      if(token){
        var decodedToken = helper.decodeToken(token);
    
    
        this.currentUserId= decodedToken.result.id;
        this.designation = decodedToken.result.designation;
        
        console.log("User already logged in ");
        if(this.designation == "user") {
          this.router.navigate(['/'+decodedToken.result.businessID+'/users/home']);
          return false;
        }
        else {
          this.router.navigate(['/select-business']);
          return false;
        }
      }
      else{
        this.router.navigate(['/select-business'])
        return false;
      }
    }


    return true;
  }
}
