import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";

const helper = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _userLoggedIn: boolean = false;
  private _userName:any = null;
  private invalidLogin: boolean = false;
  private authToken:any;
  private color:any;
  private isAdmin: boolean = false;
  

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json'}),
    withCredentials: true,
  };


  constructor(private http: HttpClient, private router: Router,private zone: NgZone) {
    var loggedIn = localStorage.getItem('loggedIn');
    if (loggedIn != null) {
      this._userLoggedIn = loggedIn == 'true' ? true : false;
    }
    var userName = localStorage.getItem('userName');
    if (userName != null) {
      this._userName = userName;
    }
    this.getTokenData();
  }

  validateLogin() {
    console.log('testataan');
    
     this.http.get(environment.CURRENT_USER_END_POINT, this.httpOptions).subscribe(
      (res: any) => {
        console.log('validate loigin ',res);
        if (res.status == 200) {
          //this.router.navigate(['/'+this.getTokenData().businessID+'/users/home']);
          
        } else {
          //this.doLogOut(true);
        }
      }, (err: any) => {
        console.log("current user error :: ", err);
      }
    ) 
  }

  validateBusiness(businessID:any,) {

    var req = {
      businessID:businessID
    }
    
     this.http.post(environment.VALIDATE_BUSINESS, req,this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200){
          if(res.message == true){
            console.log('business validated')
          }
          else {
            alert('Määrittämäsi yritys on väärä');
            return;
          }
        }
        else{
          this.router.navigate(['/select-business'])
        }
      }, (err: any) => {
        console.log("current user error :: ", err);
      }
    ) 
  }

  doDocLogin(user:any,){

    localStorage.setItem('userName', user);
    localStorage.setItem('loggedIn', "true");
    this._userLoggedIn = true;
    this._userName = user.email;
  }
  doLogin(user:any,) {
    var result = user.email;

    localStorage.setItem('userName', result);
    localStorage.setItem('loggedIn', "true");
    this._userLoggedIn = true;
    this._userName = user.email;

  }
  auth(token:any,businessID:any,) {
    localStorage.setItem('userName','TEST');
    localStorage.setItem('loggedIn', "true");
    localStorage.setItem('auth', JSON.stringify(token));
    localStorage.removeItem('TEMP_AUTH');
    this._userLoggedIn = true;
    this.zone.run(() => {
      this.router.navigate(['/'+businessID+'/users/home']);
     });


  }

  adminAuth(token:string) {
    localStorage.setItem('userName','TEST');
    localStorage.setItem('loggedIn', "true");
    localStorage.setItem('auth', JSON.stringify(token));
    this._userLoggedIn = true;
    this.zone.run(() => {
      this.router.navigate(['/admin-home']);
     });
  }

  doLogOut(redirectToLogin?: boolean) {
    console.log("Doing logout :: ", redirectToLogin);
    //this.http.get(environment.LOGOUT_END_POINT, this.httpOptions).subscribe();
    var businessID = this.getTokenData().businessID;
    this.http.get(environment.LOGOUT_END_POINT, this.httpOptions).subscribe(
      (res: any) => {
        var mode = this.getTokenData().isModerator;
        
        if(mode == true){
          this.router.navigate(['/moderator-login'])
          localStorage.clear();
        }
        else{

          if(this._userLoggedIn == true){
            if(businessID){
              this.router.navigate(['/'+businessID+'/login'])
            }
            console.log(businessID)
            this._userLoggedIn = false;
            this._userName = "";
            localStorage.clear();
          }
        }
        if (redirectToLogin == true) {
          if(businessID){
            this.router.navigate(['/'+businessID+'/login'])
          }
          //console.log("Redirecting to login page");
          localStorage.clear();
        }

      }, (err: any) => {
        console.log("current user error :: ", err);
      }
    ) 
        
  }


  isUserLoggedIn(): boolean {
    return this._userLoggedIn;
  }

  getUsername(): string {
    return this._userName;
  }

  isDocAdmin(){
    var ed = localStorage.getItem('auth');
    const decodedToken = helper.decodeToken(ed);
    return decodedToken.result.isAdmin;
  }

  getTokenData(){
    var token = localStorage.getItem('auth');
    if(token){
    var decodedToken = helper.decodeToken(token).result;
    this.authToken = decodedToken;
    return this.authToken;
    }
    else{
      return 1;
    }
      
  }


  



}
