<app-not-auth-header></app-not-auth-header>


<div class="wrapperi">
	<div class="container h-75">
		<div class="wrap">

			<div class="col-12 m-0 row">
				<div class="col-3"></div>
				<div class="col-12 col-md-6 p-0 mt-4">
					<div class="col-12 text-center p-0">
						<h1 *ngIf="business && business.length > 0">{{business[0].businessName}}</h1>
						<h4 class=" mt-4">Tervetuloa</h4>
						<p class="">Syötä käyttäjätunnus ja salasana</p>
					</div>
				</div>
				<div class="col-3"></div>

			</div>

			<div class="col-12 p-0 row m-0">
				<div class="col-3"></div>
				<div class="col-12 col-md-6 p-0">
					<div class="col-12 p-0 ">
						<div class="col-12 login-form-bg">
							<div class="alert alert-warning mt-3 mb-3" id="login-err" style="display: none;" >
								<p class="body2b-b">Käyttäjätunnus tai salasana on väärin</p>
							</div>
							<div class="alert alert-danger" id="lock-err" style="display: none;" >
								<p class="body2b-b">Käyttäjätili lukittu 15min ajaksi</p>
							</div>
							<div id="loader" class="loader-container mt-4 mb-4" style="display:none">
								<div class="loader mx-auto"></div>
								<p class="body2b-b text-center mt-4 mb-4">Kirjaudutaan sisään...</p>
							</div>
							<form class="flex-row " id="login-form" [formGroup]="loginFormi"
							(ngSubmit)="renderReCaptch()">
							<!-- Sähköposti -->
							<div class="col-12 row m-0 pt-2">
								<div class="form-floating mb-2  col-12 col-md-12 p-0">
									<input type="email" placeholder="Sähköposti" formControlName="userName" id="userName" class="form-control" [class.invalid-input]="userName.errors && userName.touched" required>
									<label for="userName">Sähköposti</label>
								</div>
							</div>
							<!-- Salasana -->
							<div class="col-12 row m-0 pt-2">
							<div class="form-floating mb-2  col-12 col-md-12 p-0">
								<input type="password" placeholder="Salasana" formControlName="password" id="password" class="form-control" [class.invalid-input]="password.errors && password.touched" required>
								<label for="password">Salasana</label>
							</div>
						</div>
							<div class=" mx-auto mt-4">
								<div class="text-center" >
									<button #recaptcha [disabled]="loginFormi.invalid" class="btn btn-feedbacker w-100"   ><i class="fas fa-sign-in-alt me-2"></i>Kirjaudu</button>
								</div>
							</div>
						</form>
					
				</div>
					</div>
				</div>
				<div class="col-3"></div>

			</div>

			<div class="d-flex justify-content-center flex-row ">
				<div class="link-container">
					<p class="mt-4  ">Unohditko salasanan? Uusi se 
						<a  routerLink="/{{businessID}}/forgot-password">tästä</a>
					</p>

				</div>
			</div>
			</div>
		</div>
	</div>