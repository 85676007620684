// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//Testi

/* const BASE_HOST = "192.168.0.14"
const BASE_PORT = "3010";
const BASE_URL = "http://" + BASE_HOST + ":" + BASE_PORT; */

const BASE_HOST = window.location.hostname; 
const BASE_URL = "";

/**
 * 
 * easyhealth: 6LfF90AfAAAAAN4qhyJ0vkh7RDt8EJS84vaniySe
 */
export const env_data = {
  google_site_key: "6LfF90AfAAAAAN4qhyJ0vkh7RDt8EJS84vaniySe"
};

export const base_host = {
  base_host: window.location.hostname
};
export const my_protocol = {
  protocol: 'http'
};
export const my_port = {
  port: ':4200'
};


export const environment = {
  production: false,


  //-----------------------------------------------------------------------------------------
  //  USERS
  //-----------------------------------------------------------------------------------------

  CURRENT_USER_END_POINT: BASE_URL + "/api/users/currentUser",
  LOGIN_END_POINT: BASE_URL + "/api/users/login",
  REGISTER_END_POINT: BASE_URL + "/api/users/register",
  LOGOUT_END_POINT: BASE_URL + "/api/users/logout",
  USER_REGISTER_END_POINT: BASE_URL + "/api/users/register",
  ACTIVATE_USER_AFTER_REGISTER:BASE_URL+ "/api/users/activateAfterRegister",
  ADD_PASSWORD_FOR_USER:BASE_URL+ "/api/users/setPassword",
  VALIDATE_TOKEN:BASE_URL+ "/api/users/validateActivationToken",
  USERS:BASE_URL+ "/api/users/users",
  USER:BASE_URL+ "/api/users/user",

  VALIDATE_USER_END_POINT:BASE_URL + '/api/users/validate',
  FORGOT_PASSWORD_END_POINT: BASE_URL + '/api/users/forgotPassword',
  RESET_PASSWORD_END_POINT: BASE_URL + '/api/users/resetPassword',
  CHANGE_ON_RESET_PASSWORD_END_POINT:BASE_URL + '/api/users/changePasswordOnReset',
  EDIT_USER_END_POINT:BASE_URL + '/api/users/editUser',
  GOOGLE_PATIENT_LOGIN_VERIFICATION:BASE_URL+ "/api/users/googleLoginCheckupUser",

  ADMIN_FETCH_USER_DETAILS:BASE_URL+ "/api/users/admin/user",

  //-----------------------------------------------------------------------------------------
  //  ADMINS
  //-----------------------------------------------------------------------------------------

  GOOGLE_ADMIN_LOGIN_VERIFICATION:BASE_URL+ "/api/admins/login",
  ADMIN_SMS_VALIDATION: BASE_URL + '/api/admins/admin/login/sms',
  VALIDATE_ADMIN_TEMP_AUTH: BASE_URL + '/api/admins/admin/validate/tempauth',

  //-----------------------------------------------------------------------------------------
  //  BUSIENSS
  //-----------------------------------------------------------------------------------------

  VALIDATE_BUSINESS:BASE_URL+ "/api/businesses/business/validate",
  BUSINESS_DETAILS_NO_AUTH:BASE_URL+ "/api/businesses/open/business/details",
  FETCH_ADMIN_BUSINESSES:BASE_URL+'/api/businesses/admin/businesses',
  FETCH_ADMIN_SINGLE_BUSINESS:BASE_URL+'/api/businesses/admin/business',
  ADMIN_EDIT_BUSINESS:BASE_URL+'/api/businesses/admin/business',
  USER_BUSINESS:BASE_URL+'/api/businesses/user/business',
  GENERATE_CERTIFICATE:BASE_URL+'/api/businesses/open/certificate',
  ORDER_DATA:BASE_URL+'/api/businesses/business/orders',
  REMOVE_CERT:BASE_URL+'/api/businesses/business/orders/certificates/remove',
  ADD_CERT:BASE_URL+'/api/businesses/business/orders/certificates/add',
  GOALS:BASE_URL+'/api/businesses/business/goals',
  FETCH_OPEN_BUSINESS:BASE_URL+'/api/businesses/business/open/colors',
  SEND_OPEN_CONTACTS:BASE_URL+'/api/businesses/business/open/contact',


  //-----------------------------------------------------------------------------------------
  //  FEEDBACKS
  //-----------------------------------------------------------------------------------------

  FEEDBACK:BASE_URL+ "/api/feedbacks/feedback",
  FEEDBACK_VALUES:BASE_URL+ "/api/feedbacks/feedback/values",
  GET_FEEDBACK_FOR_NO_AUTH:BASE_URL+ "/api/feedbacks/open/feedback",
  SUBMIT_FEEDBACK_NO_AUTH:BASE_URL+ "/api/feedbacks/open/feedback/submit",
  SUBMIT_TEXT_FEEDBACK_NOT_AUTH:BASE_URL+ "/api/feedbacks/open/feedback/submit/text",
  FEEDBACKS:BASE_URL+ "/api/feedbacks/feedbacks",
  FEEDBACK_EMAIL_CAMPAIGN:BASE_URL+ "/api/feedbacks/feedback/emailCampaign",
  FEEDBACK_DATA:BASE_URL+ "/api/feedbacks/feedback/company/values",
  GENERATE_QUESTION_CERTIFICATE:BASE_URL+'/api/feedbacks/open/certificate',
  DAILYS_DATA:BASE_URL+'/api/feedbacks/averages',
  FEEDBACK_COMPARE:BASE_URL+'/api/feedbacks/compare',


    //-----------------------------------------------------------------------------------------
  //  MESSAGES
  //-----------------------------------------------------------------------------------------

  MESSAGE_LIST_END_POINT: BASE_URL + "/api/messages/messageList",
  MESSAGE_LIST_BY_USER_END_POINT: BASE_URL + "/api/messages/messageListByUser",
  ANSWER_SELECTED_END_POINT: BASE_URL + "/api/messages/answerSelected",
  FETCH_FIRST_QUESTION: BASE_URL + "/api/messages/first",

  USER_MESSAGE_LIST_END_POINT_BY_DATE: BASE_URL + '/api/messages/userFetchMessagesByDate',
  
  //-----------------------------------------------------------------------------------------
  //  DEVICES
  //-----------------------------------------------------------------------------------------


  DEVICES:BASE_URL+ "/api/devices/devices",
  DEVICE:BASE_URL+ "/api/devices/device",
  DEVICE_HUB_VALIDATE:BASE_URL+ "/api/devices/hub/validate",


  //-----------------------------------------------------------------------------------------
  //  SPINNERS
  //-----------------------------------------------------------------------------------------

  SPINNER:BASE_URL+ "/api/spinners/spinner",
  SPINNERS:BASE_URL+ "/api/spinners/spinners",
  UPDATE_SPINNER_ANSWER:BASE_URL+ "/api/spinners/spinner/updateAnswer",
  REMOVE_SPINNER_ANSWER:BASE_URL+ "/api/spinners/spinner/removeAnswer",
  OPEN_SPINNER:BASE_URL+ "/api/spinners/open/spinner",

  //-----------------------------------------------------------------------------------------
  //  BOOKINGS
  //-----------------------------------------------------------------------------------------

  BOOKING:BASE_URL+ "/api/bookings/booking",
  SLOT_BOOKINGS:BASE_URL+ "/api/bookings/slotBookings",
  FREE_BOOKINGS:BASE_URL+ "/api/bookings/bookings/free",
  RESERVED_BOOKINGS:BASE_URL+ "/api/bookings/bookings/reserved",
  PROGGRESS_BOOKINGS:BASE_URL+ "/api/bookings/bookings/proggress",
  ALL_BOOKINGS:BASE_URL+ "/api/bookings/bookings/all",



  //-----------------------------------------------------------------------------------------
  //  BOOKINGS
  //-----------------------------------------------------------------------------------------

  METHODS:BASE_URL+ "/api/payments/methods",
  GET_PAYMET:BASE_URL+ "/api/payments/get-payment",

  //-----------------------------------------------------------------------------------------
  //  CALENDAR
  //-----------------------------------------------------------------------------------------

  CALENDAR:BASE_URL+ "/api/calendars/calendar",
  CALENDARS:BASE_URL+ "/api/calendars/calendars",
  OPEN_CALENDAR_DATA:BASE_URL+ "/api/calendars/open/calendar",

  //-----------------------------------------------------------------------------------------
  //  TYPES
  //-----------------------------------------------------------------------------------------

  TYPE:BASE_URL+ "/api/types/type",
  TYPES:BASE_URL+ "/api/types/types",

    //-----------------------------------------------------------------------------------------
  //  TYPES
  //-----------------------------------------------------------------------------------------

  ADD_ENDCOME:BASE_URL+ "/api/trees/endcome",
  EDIT_ENDCOME:BASE_URL+ "/api/trees/endcome/edit",
  ADD_FLOW:BASE_URL+ "/api/trees/flow",
  DELETE_FLOW:BASE_URL+ "/api/trees/flow/delete",
  

 
  

  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
