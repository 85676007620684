import { Component, OnInit, ElementRef, ViewChild, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment, env_data } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { JwtHelperService } from "@auth0/angular-jwt";
import {ActivatedRoute, Router } from '@angular/router';


const helper = new JwtHelperService;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  @ViewChild('recaptcha', { static: true }) recaptchaElement: ElementRef;

  loginFormi: FormGroup;
  errMessage = false;
  botErr = false;
  lockErr = false;
  loginErr = false;
  serviceOpenErr = false;
  payload;
  authToken = localStorage.getItem('auth');
  currentUserId="";
  designation="";
  businessID: string = this.route.snapshot.params.businessID;
  business = [];

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    withCredentials: true,
  };

  constructor(
    private router: Router,
    private http: HttpClient,
    private fb: FormBuilder,
    private zone: NgZone,
    private route: ActivatedRoute,
    private authService: AuthService) { 
      this.authService.validateBusiness(this.businessID)
    }

  ngOnInit() {

    this.loginFormi = this.fb.group({
      userName: ['', [
 
        Validators.required,
        Validators.email, 

      ]],
      password:  ['',[
        Validators.required,
        Validators.minLength(5),

      ]]
    })
    this.routeCheck()

    this.fetchBusinessDetails();
  }

  routeCheck(){
    if (this.authService.isUserLoggedIn()) {

      var token = localStorage.getItem('auth');
      if(token){
        var decodedToken = helper.decodeToken(token);
    
        this.currentUserId= decodedToken.result.id;
        this.designation = decodedToken.result.designation;
        
        console.log("user is already logged in. sending to home page");
        if(this.designation == "user") {
          this.router.navigate(['/'+this.businessID+'/users/home']);
          return false;
        }
        else {
          this.router.navigate(["/admin/dashboard"]);
        }
      }
      else{
        this.router.navigate(["/login"]);
      }
    }

    this.addRecaptchaScript();
  }


  renderReCaptch() {

    
    let element = this.recaptchaElement.nativeElement;
    window['grecaptcha'].render(element, {
      'sitekey' : env_data.google_site_key, //TUOT= 6LfXmE8aAAAAAPAi2nun0eTRgSOYm7uSDvrJl9zX, MUSTI_DEV = 6LcPEDUaAAAAAP3y86cC2MHX0dKoJ2dhqc707-Q9
      'callback': (response) => {
          this.sendGoogleVerification(response);
          
      }
    });
  }




 
  addRecaptchaScript() {
 
    window['grecaptchaCallback'] = () => {
      this.renderReCaptch();

      //this.deleteCookies()
    }
 
    var that = this;
    (function(d, s, id, obj){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { obj.renderReCaptch(); return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
      fjs.parentNode.insertBefore(js, fjs);
      
    }(document, 'script', 'recaptcha-jssdk', this));
 
  }

  get userName() { return this.loginFormi.get('userName') };
  get password() { return this.loginFormi.get('password') };

  sendGoogleVerification(response) {


    

    var password = this.loginFormi.get('password').value;
    var userName = this.loginFormi.get('userName').value;
    this.errMessage=false;
    this.loginErr = false;


     if(password == "" || userName == "") {
      return;
    }
    if(password.length < 4) {
      return;
    }
    if(userName.lenght < 4 ) {
      return;
    }
    if(!userName.includes('@')) {
      return;
    } 

     document.getElementById('login-err').style.display = "none";
    document.getElementById('lock-err').style.display = "none";
    
    var elm = document.getElementById('login-form');
    document.getElementById('login-err').style.display = "none";
    document.getElementById('loader').style.display="block"; 
    elm.classList.add('reg-form-success');

    var req = {
      response:response,
      userName: userName,
      password: password,
      businessID: this.businessID,
    }




    this.http.post(environment.LOGIN_END_POINT, req, this.httpOptions).subscribe(
      (res: any) => { 

        if (res.status == "success") {
          this.authService.auth(res.token,this.businessID);
          //this.authService.doLogin(form);
          
        } else if(res.status == 201 || res.status == 202){
           elm.classList.remove("reg-form-success");
          document.getElementById('loader').style.display="none";
          document.getElementById('login-err').style.display = "block"; 

          this.loginFormi.get('password').setValue("");

          location.reload();

          this.loginErr = true;

        }
        else if(res.status == 204) {
           elm.classList.remove("reg-form-success");
          document.getElementById('loader').style.display="none";
          document.getElementById('lock-err').style.display = "block"; 

          this.loginFormi.get('password').setValue("");
          this.lockErr = true
        }
        else if(res.status == 205) {
          elm.classList.remove("reg-form-success");
         document.getElementById('loader').style.display="none";
         document.getElementById('service-open').style.display = "block"; 

         this.loginFormi.get('password').setValue("");
       }

        else if(res.status == 200 && res.consentExpired == true) {
          localStorage.setItem('TEMP_AUTH', res.token);
          this.zone.run(() => {
           this.router.navigate(['/accept-terms-of-service/'+res.vetevaID]);
          });

        }
        else if(res.status == 200 && res.token != "") {
          this.authService.auth(res.token,this.businessID);
          
        }
        if(res.status == 400){
          this.botErr == true;
        }
      
      }, (err: any) => {
          
        console.log("Error while login :: ", err);
      })

  }

  fetchBusinessDetails() {

    var req = {
      businessID: this.businessID
    }

    this.http.post(environment.BUSINESS_DETAILS_NO_AUTH,req, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {
          this.business = res.message;
          console.log(this.business)

        }
        else {
          console.log('failed to fetch business details')
        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })


  }


}
