import { AfterContentInit, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';

@Component({
  selector: 'app-activate-user',
  templateUrl: './activate-user.component.html',
  styleUrls: ['./activate-user.component.css']
})
export class ActivateUserComponent implements OnInit {

  errMessage = "";
  successMessage = "";
  form: FormGroup;
  myAccessToken:string = this.route.snapshot.params.token;
  businessID: string = this.route.snapshot.params.businessID;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    withCredentials: true,
  };

  constructor(
    private router: Router,
    private http: HttpClient,
    private route:ActivatedRoute,
    private fb: FormBuilder,
    private authService: AuthService) { }


  ngOnInit() {
    this.activateUser();

    this.form = this.fb.group({
      password: ['', [
        Validators.required,
        Validators.minLength(8)
      ]],
      password2: ['', [
        Validators.required,
        Validators.minLength(8)
      ]]
    });
  }



  activateUser() {
    var req = {
      activationToken: this.myAccessToken
    }

    this.http.post(environment.VALIDATE_TOKEN,req, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {
          console.log('käyttäjä on nyt aktivoitu');

        }
        else {
          alert('Aktivointi koodisi on virheellinen tai sitä ei ole olemassa. Paina OK siirtyäksesi kirjautumissivulle.')
          this.router.navigate(['/'+this.businessID+'/login']);
        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })
  }

  get password() { return this.form.get('password').value };
  get password2() { return this.form.get('password2').value };

  changePassword(){
    if(this.password == this.password2){

      var elm = document.getElementById('new-pwd-form');
      elm.classList.add("reg-form-success");
      document.getElementById('loader').style.display="block";

      var req = {
        password: this.password,
        token: this.myAccessToken
      }

      this.http.post(environment.ADD_PASSWORD_FOR_USER, req, this.httpOptions).subscribe(
        (res: any) => {
          if (res.status == 200) {
            this.successMessage = "";
            this.successMessage = res.message;
            console.log('Validi token')
            elm.classList.remove("reg-form-success");
            document.getElementById('loader').style.display="none";
            alert('Salasanan asettaminen onnistui')
            this.router.navigate(['/'+this.businessID+'/login'])
          } else {
            this.errMessage = ""
            this.errMessage = res.message;
            
            
          }
          
        }, (err: any) => {

          console.log("Error while login :: ", err);
        }
      )

    }
    else {
      this.errMessage = "Salasanat eivät täsmää, ole hyvä ja kokeile uudestaan."
      console.log('salasanat eivät täsmää')

      var elm = document.getElementById('new-pwd-form');
      elm.classList.remove("reg-form-success");
      document.getElementById('loader').style.display="none";
      this.form.reset();

    }


  }
}
