<div class="row text-center">
  <button class="btn btn-feedbacker" data-bs-toggle="modal" data-bs-target="#delete-cert-nps">Tee slotteja</button>
  <div class="col-md-4">
    <div class="btn-group">
      <div
        class="btn btn-primary"
        mwlCalendarPreviousView
        [view]="view"
        [(viewDate)]="viewDate"
        (viewDateChange)="closeOpenMonthViewDay()"
      >
        Edellinen
      </div>
      <div
        class="btn btn-outline-secondary"
        mwlCalendarToday
        [(viewDate)]="viewDate"
      >
        Nykyinen
      </div>
      <div
        class="btn btn-primary"
        mwlCalendarNextView
        [view]="view"
        [(viewDate)]="viewDate"
        (viewDateChange)="closeOpenMonthViewDay()"
      >
        Seuraava
      </div>
    </div>
  </div>
  <div class="col-md-4">
    <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'fi' }}</h3>
  </div>
  <div class="col-md-4">
    <div class="btn-group">
      <div
        class="btn btn-primary"
        (click)="setView(CalendarView.Month)"
        [class.active]="view === CalendarView.Month"
      >
        Kuukausi
      </div>
      <div
        class="btn btn-primary"
        (click)="setView(CalendarView.Week)"
        [class.active]="view === CalendarView.Week"
      >
        Viikko
      </div>
      <div
        class="btn btn-primary"
        (click)="setView(CalendarView.Day)"
        [class.active]="view === CalendarView.Day"
      >
        Päivä
      </div>
    </div>
  </div>
</div>
<br />
<div [ngSwitch]="view">
  <mwl-calendar-month-view
    *ngSwitchCase="CalendarView.Month"
    [viewDate]="viewDate"
    [events]="events"
    [refresh]="refresh"
    [locale]="locale"
    [activeDayIsOpen]="activeDayIsOpen"
    (dayClicked)="dayClicked($event.day)"
    (eventClicked)="handleEvent('Klikattu', $event.event)"
    (eventTimesChanged)="eventTimesChanged($event)"
  >
  </mwl-calendar-month-view>
  <mwl-calendar-week-view
    *ngSwitchCase="CalendarView.Week"
    [viewDate]="viewDate"
    [events]="events"
    [locale]="locale"
    [refresh]="refresh"
    [dayStartHour]="6"
    [dayEndHour]="20"
    (eventClicked)="handleEvent('Clicked', $event.event)"
    (eventTimesChanged)="eventTimesChanged($event)"
  >
  </mwl-calendar-week-view>
  <mwl-calendar-day-view
    *ngSwitchCase="CalendarView.Day"
    [viewDate]="viewDate"
    [events]="events"
    [locale]="locale"
    [refresh]="refresh"
    [dayStartHour]="6"
    [dayEndHour]="20"
    (eventClicked)="handleEvent('Clicked', $event.event)"
    (eventTimesChanged)="eventTimesChanged($event)"
  >
  </mwl-calendar-day-view>
</div>

<!-- Everything you see below is just for the demo, you don't need to include it in your app -->

<br /><br /><br />

<h3>
  Muokkaa tapahtumia
  <button class="btn btn-primary float-right" (click)="addEvent()">
    Add new
  </button>
  <div class="clearfix"></div>
</h3>

<div class="table-responsive">
  <table class="table table-bordered">
    <thead>
      <tr>
        <th>Title</th>
        <th>Primary color</th>
        <th>Secondary color</th>
        <th>Starts at</th>
        <th>Ends at</th>
        <th>Remove</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let event of events">
        <td>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="event.title"
            (keyup)="refresh.next()"
          />
        </td>
        <td>
          <input
            type="color"
            [(ngModel)]="event.color.primary"
            (change)="refresh.next()"
          />
        </td>
        <td>
          <input
            type="color"
            [(ngModel)]="event.color.secondary"
            (change)="refresh.next()"
          />
        </td>
        <td>
          <input
            class="form-control"
            type="text"
            mwlFlatpickr
            [(ngModel)]="event.start"
            (ngModelChange)="refresh.next()"
            [altInput]="true"
            [convertModelValue]="true"
            [enableTime]="true"
            dateFormat="Y-m-dTH:i"
            altFormat="F j, Y H:i"
            placeholder="Not set"
          />
        </td>
        <td>
          <input
            class="form-control"
            type="text"
            mwlFlatpickr
            [(ngModel)]="event.end"
            (ngModelChange)="refresh.next()"
            [altInput]="true"
            [convertModelValue]="true"
            [enableTime]="true"
            dateFormat="Y-m-dTH:i"
            altFormat="F j, Y H:i"
            placeholder="Not set"
          />
        </td>
        <td>
          <button class="btn btn-danger" (click)="deleteEvent(event)">
            Delete
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<ng-template #modalContent let-close="close">
  <div class="modal-header">
    <h5 class="modal-title">Event action occurred</h5>
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      Action:
      <pre>{{ modalData?.action }}</pre>
    </div>
    <div>
      Event:
      <pre>{{ modalData?.event | json }}</pre>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="close()">
      OK
    </button>
  </div>
</ng-template>

<div class="modal fade" id="delete-cert-nps" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Tee slotteja</h5>
        <!-- <p>Kaikki tähdellä merkityt kentät ovat pakollisia</p> -->
        <button type="button" class="close" id="closeDeleteModal" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h5 class="text-center">Tee slotteja</h5>
        <div class="col-12 row m-0 pt-4 pb-4">

          <form [formGroup]="form"
          (ngSubmit)="onSubmit()" class="col-12 row m-0 pt-2">


          <div class="form-floating mb-2 col-12 col-md-12 p-0">
            <input type="date"  placeholder="Alkupäivä" formControlName="startDay" id="startDay" class="form-control" >
            <label for="startDay">Alkupäivä (pakollinen)</label>
          </div>
          <div class="form-floating mb-2 col-12 col-md-12 p-0">
            <input type="date"  placeholder="Loppupäivä" formControlName="endDay" id="endDay" class="form-control" >
            <label for="endDay">Loppupäivä (pakollinen)</label>
          </div>
            <div class="form-floating mb-2 col-12 col-md-12 p-0">
                <input type="number"  placeholder="Slotin pituus min" formControlName="slotInterval" id="slotInterval" class="form-control" >
                <label for="slotInterval">Slotin pituus min (pakollinen)</label>
            </div>
            <div class="form-floating mb-2 col-12 col-md-12 p-0">
              <input type="time"  placeholder="Avausaika" formControlName="openTime" id="openTime" class="form-control" >
              <label for="openTime">Avausaika (pakollinen)</label>
          </div>
          <div class="form-floating mb-2 col-12 col-md-12 p-0">
            <input type="time"  placeholder="Viimeinen aika" formControlName="closeTime" id="closeTime" class="form-control" >
            <label for="closeTime">Viimeinen aika</label>
          </div>

          <div class="form-floating mb-2 col-12 col-md-12 p-0">
            <input type="number"  placeholder="Huoltoaika" formControlName="slotPrice" id="slotPause" class="form-control" >
            <label for="slotPause">Huoltoaika (pakollinen)</label>
          </div>

          <div class="form-floating mb-2 col-12 col-md-12 p-0">
            <input type="number"  placeholder="Hinta" formControlName="slotPrice" id="slotPrice" class="form-control" >
            <label for="slotPrice">Hinta (pakollinen)</label>
          </div>


          <div class="col-12 text-center">
              <button class="btn btn-feedbacker" [disabled]="form.invalid" >Tallenna</button>
            </div>
          </form>
          <div class="col-12 text-center">
            <a class="pointer"  id="close-delete-nps" data-bs-dismiss="modal">Peruuta</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>