import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { base_host, environment, my_port, my_protocol } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';

@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.css']
})
export class DeviceComponent implements OnInit {

  errMessage = "";
  successMessage = "";
  deviceID:string = this.route.snapshot.params.id;
  businessID: string = this.route.snapshot.params.businessID;
  feedbackID;
  device;
  feedback;
  authToken = localStorage.getItem('auth');
  pathUrl;
  ready = false;
  feedbackValues;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'auth': `${this.authToken}`  }),
    withCredentials: true,
  };

  constructor(
    private router: Router,
    private http: HttpClient,
    private route:ActivatedRoute,
    private authService: AuthService) {
      this.authService.validateBusiness(this.businessID)

     }

  ngOnInit(): void {
    this.getDevice();
  }

  getDevice() {


    this.http.get(environment.DEVICE+'/'+this.deviceID, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {
          this.device = res.message[0];
          console.log(this.device);
          this.feedbackID = this.device.feedbackID;
          this.getFeedback()
          

        }
        else {
          var anElm = document.getElementById('notadded');
          anElm.style.display="block";
          console.log('ei ole kyseistä käyttäjää')
        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })


  }

  getFeedback() {

    this.http.get(environment.FEEDBACK+'/'+this.feedbackID, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {
          this.feedback = res.message[0];

          console.log(this.feedback);
          

        }
        else {
          var anElm = document.getElementById('notadded');
          anElm.style.display="block";
          console.log('ei ole kyseistä käyttäjää')
        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })


  }

}
