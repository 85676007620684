import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {

  errMessage = "";
  successMessage = "";
  token:string = this.route.snapshot.params.token;
  businessID: string = this.route.snapshot.params.businessID;
  spot: string = this.route.snapshot.params.spot;
  myUUID;
  feedback;
  closeText = false;
  timer;
  answeredValue;
  currentQustion;


  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    withCredentials: false,
  };

  constructor(
    private router: Router,
    private http: HttpClient,
    private route:ActivatedRoute,
    private authService: AuthService) {
      this.authService.validateBusiness(this.businessID)

     }

  ngOnInit(): void {
    this.getFeedback()
  }

  getFeedback() {
    var req = {
      businessID: this.businessID,
      token: this.token,
    }

    this.http.post(environment.GET_FEEDBACK_FOR_NO_AUTH,req, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {

          this.feedback = res.message[0];
          this.currentQustion = this.feedback.question;

          
          this.waitStyle();

        }
        else {
          var anElm = document.getElementById('notadded');
          anElm.style.display="block";
          console.log('ei ole kyseistä käyttäjää')
        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })

  }

  submitAnswer(value){
    var req = {
      businessID: this.businessID,
      token: this.token,
      value: parseInt(value)
    }

    this.http.post(environment.SUBMIT_FEEDBACK_NO_AUTH,req, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {

          this.myUUID = res.message;

          if(this.feedback.pretemplates){
            this.answeredValue = req.value;
            this.pretemplatesallowed();
          }
          else if(this.feedback.allowTextFeedback){
            this.myUUID = res.message;
            this.textFeedbackAllowed();
          }
          else{
            if(this.spot == "pos"){
              this.waitClear();
            }
            this.textFeedbackNotAllowed()
          }

          var textarea = document.getElementById('em');

        }
        else {
          var anElm = document.getElementById('notadded');
          anElm.style.display="block";
          console.log('ei ole kyseistä käyttäjää')
        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })
  }

  countTime(){
    if(this.timer != null){
      var that = this;
      var x = setInterval(function() {
          var now = Date.now();
          console.log(that.timer)
          if(that.timer == null || that.timer == undefined || !that.timer){
            clearInterval(x);
          }
          if((now - that.timer) > 8000){
            that.clearWindow();
            that.timer = null;
            //hide textarea

            (<HTMLTextAreaElement>document.getElementById('text-feedback-pos')).value = "";
            document.getElementById('text-area-pos').style.display = "none";
            return;


          }

          if(that.timer == null || that.timer == undefined || !that.timer){
            clearInterval(x);
          }

      }, 1000);
    }
  }

  textFeedbackAllowed(){
    if(this.feedback.type == 'smile'){
      var elm = document.getElementById('icons');
      elm.classList.add('d-none')

      if(this.spot != "pos"){
        var text = document.getElementById('text-area');
        text.style.display = "block"
      }
      else{
        var text = document.getElementById('text-area-pos');
        text.style.display = "block"

        this.timer = Date.now();
        console.log(this.timer);
        this.countTime();
      }

    }
    else{

      if(this.spot != "pos"){
        var elm = document.getElementById('nps-container');
        elm.classList.add('d-none')

        var text = document.getElementById('text-area');
        text.style.display = "block"
      }
      else{
        var elm = document.getElementById('nps-container-pos');
        elm.classList.add('d-none')

        var text = document.getElementById('text-area-pos');
        text.style.display = "block"

        this.timer = Date.now();
        console.log(this.timer);
        this.countTime();
      }
    }
  }

  pretemplatesallowed(){

    if(this.answeredValue >= 80){
      this.currentQustion = ""
      this.currentQustion = this.feedback.pretemplates.high.question;
    }
    else if(this.answeredValue < 50){
      this.currentQustion = ""
      this.currentQustion = this.feedback.pretemplates.low.question
    }
    else{
      this.currentQustion = ""
      this.currentQustion = this.feedback.pretemplates.mid.question
    }



    if(this.feedback.type == 'smile'){
      var elm = document.getElementById('icons');
      elm.classList.add('d-none')

      if(this.spot != "pos"){
        var text = document.getElementById('pretemplates');
        text.style.display = "block"
      }
      else{
        var text = document.getElementById('pretemplates-pos');
        text.style.display = "block"

        this.timer = Date.now();
        console.log(this.timer);
        this.countTime();
      }

    }
    else{

      if(this.spot != "pos"){
        var elm = document.getElementById('nps-container');
        elm.classList.add('d-none')

        var text = document.getElementById('text-area');
        text.style.display = "block"
      }
      else{
        var elm = document.getElementById('nps-container-pos');
        elm.classList.add('d-none')

        var text = document.getElementById('text-area-pos');
        text.style.display = "block"

        this.timer = Date.now();
        console.log(this.timer);
        this.countTime();
      }
    }
  }

  textFeedbackNotAllowed(){
    if(this.feedback.type == 'smile'){
      var elm = document.getElementById('icons');
      elm.classList.add('d-none')
    }
    else if(this.feedback.type == 'nps'){
      if(this.spot == "web"){
        var elm = document.getElementById('nps-container');
        elm.classList.add('d-none');
      }
      else if(this.spot == "pos"){
        var elm = document.getElementById('nps-container-pos');
        elm.classList.add('d-none');
      }
    }
    var thanks = document.getElementById('thanks');
    thanks.style.display = "block"
  }

  clearWindow(){
    if(this.feedback.type == 'smile'){
      var elm = document.getElementById('icons');
      elm.classList.remove('d-none');
      var pre = document.getElementById('pretemplates-pos');
      pre.style.display = "none";
    }
    else if(this.feedback.type == 'nps'){
        if(this.spot == 'web'){
          var elm = document.getElementById('nps-container');
          elm.classList.remove('d-none');

        }
        else if(this.spot == 'pos'){
          var elm = document.getElementById('nps-container-pos');
          var pre = document.getElementById('pretemplates-pos');
          elm.classList.remove('d-none');
          pre.style.display = "none";
        }
    }

    var thanks = document.getElementById('thanks');
    thanks.style.display = "none";

  }

  userTyping(e){
    console.log(2);
    this.timer = Date.now();
    console.log('kirjotan')
  }


  submitTextAnswer(){

  //hide textarea
  if(this.spot != 'pos'){
    var txt = (<HTMLTextAreaElement>document.getElementById('text-feedback')).value
  }
  else{
    var txt = (<HTMLTextAreaElement>document.getElementById('text-feedback-pos')).value
  }

    
    var req = {
      businessID: this.businessID,
      token: this.token,
      UUID: this.myUUID,
      text: txt
    }

    this.http.post(environment.SUBMIT_TEXT_FEEDBACK_NOT_AUTH,req, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {

            //show thankyou text
            document.getElementById('thanks').style.display = "";
            this.myUUID = "";

            //hide textarea
            if(this.spot != 'pos'){
              (<HTMLTextAreaElement>document.getElementById('text-feedback')).value = "";
              document.getElementById('text-area').style.display = "none";
            }
            else{
              (<HTMLTextAreaElement>document.getElementById('text-feedback-pos')).value = "";
              document.getElementById('text-area-pos').style.display = "none";
            }

            if(this.spot == "pos"){
              this.waitClear();
            }

        }
        else {
          var anElm = document.getElementById('notadded');
          anElm.style.display="block";
          console.log('ei ole kyseistä käyttäjää')
        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })
  }

  submitPretemplateAnswer(value){

    //hide textarea
    if(this.spot != 'pos'){
      var txt = (<HTMLTextAreaElement>document.getElementById('text-feedback')).value
    }
    else{
      var txt = (<HTMLTextAreaElement>document.getElementById('text-feedback-pos')).value
    }
  
      
      var req = {
        businessID: this.businessID,
        token: this.token,
        UUID: this.myUUID,
        text: value
      }

      this.http.post(environment.SUBMIT_TEXT_FEEDBACK_NOT_AUTH,req, this.httpOptions).subscribe(
        (res: any) => {
          if(res.status == 200 ) {
  
              //show thankyou text
              document.getElementById('thanks').style.display = "";
              this.myUUID = "";
              this.answeredValue;

              var elms = document.getElementsByClassName('checkbox-pretemplate');

              for (let i = 0; i < elms.length; i++) {
                const elm = <HTMLInputElement>elms[i];

                if(elm.checked){
                  elm.checked = false;
                }
                
              }
  
              //hide textarea
              if(this.spot != 'pos'){
                document.getElementById('pretemplates').style.display = "none"; 
              }
              else{
                document.getElementById('pretemplates-pos').style.display = "none"; 
              }
  
              if(this.spot == "pos"){
                this.waitClear();
              }
  
          }
          else {
            var anElm = document.getElementById('notadded');
            anElm.style.display="block";
            console.log('ei ole kyseistä käyttäjää')
          }
          
        }, (err: any) => {
    
          console.log("Error while finding status :: ", err);
        })
    }

  styleWanted() {
    //change question color
    document.getElementById('main-text').style.color = this.feedback.textColor;
    //change secure text color
    document.getElementById('secure-text').style.color = this.feedback.textColor;
    
    if(document.getElementById('pre-text-label-pos')){
      document.getElementById('pre-text-label-pos').style.color = this.feedback.textColor;
    }
    
    //chanhe thaks text color
    //document.getElementById('thankyou-text').style.color = this.feedback.textColor;
    //change backgroundcolor
    if(this.spot != 'pos'){
      console.log(this.feedback)
      document.getElementById('full-area').style.backgroundColor = this.feedback.backgroundColor;

    }
    else{
      document.getElementById('full-area-pos').style.backgroundColor = this.feedback.backgroundColor;

    }
    var arr = document.getElementsByClassName('text-feedbacker');

    for (let i = 0; i < arr.length; i++) {
      const elm = arr[i];
      
      var text = (<HTMLParagraphElement>elm)
      text.style.color = this.feedback.textColor;
      
    }
  }

  waitStyle() {
    var that = this;

    setTimeout(() =>{
      that.styleWanted();
      
    },400)

  }

  waitClear() {
    var that = this;

    setTimeout(() =>{
      console.log('waitingf')
      that.clearWindow();
      
    },2000)

  }


  



}
