import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
  OnInit,
} from '@angular/core';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
  addMinutes,
  isThursday,
} from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import 'flatpickr/dist/flatpickr.css';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fi';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../service/auth.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment-timezone';
import 'moment-timezone';
registerLocaleData(localeFr);
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';
import { tokenize } from '@angular/compiler/src/ml_parser/lexer';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
  green: {
    primary: '#08e33f',
    secondary: '#08e33f',
  },
};

@Component({
  selector: 'app-customer-calendar',
  templateUrl: './customer-calendar.component.html',
  styleUrls: ['./customer-calendar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      h3 {
        margin: 0 0 10px;
      }

      pre {
        background-color: #f5f5f5;
        padding: 15px;
      }
    `,
  ],
})
export class CustomerCalendarComponent implements OnInit {

  locale: string = 'fi';

  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  view: CalendarView = CalendarView.Week;

  CalendarView = CalendarView;

  viewDate: Date = new Date();
  form: FormGroup;
  selectedTimes = [];
  totalPrice;

  modalData: {
    action: string;
    event: CalendarEvent;
  };
  authToken = localStorage.getItem('auth');
  activeDayIsOpen: boolean = true;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'auth': `${this.authToken}`  }),
    withCredentials: true,
  };

  businessID: string = this.route.snapshot.params.businessID;
  token: string = this.route.snapshot.params.token;
  paymentMethods;
  selectedMethod;



  refresh = new Subject<void>();
  events: CalendarEvent[] = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private modal: NgbModal,
    private http: HttpClient,
    private route:ActivatedRoute,
    private authService: AuthService) {
      this.authService.validateBusiness(this.businessID)

     }

     get firstName() { return this.form.get('firstName') };
     get address() { return this.form.get('address') };
     get lastName() { return this.form.get('lastName') };
     get postNumber() { return this.form.get('postNumber') };
     get postOffice() { return this.form.get('postOffice') };
     get email() { return this.form.get('email') };
     get phone() { return this.form.get('phone') };
     get addInfo() { return this.form.get('addInfo') };

     ngOnInit(): void {

      this.fetchBookings();
      this.fetchPaymentMethods();
  
      this.form = this.fb.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        postOffice: ['', Validators.required],
        postNumber: ['', Validators.required],
        address: ['', Validators.required],
        phone: ['', Validators.required],
        email: ['', Validators.required],
        addInfo: "",
      });
    }

    handleEvent(action: string, event: CalendarEvent): void {
      this.modalData = { event, action };
      this.modal.open(this.modalContent, { size: 'lg' });
    }

    setView(view: CalendarView) {
      this.view = view;
    }
  
    closeOpenMonthViewDay() {
      this.activeDayIsOpen = false;
    }

    fetchBookings(){


      this.http.get(environment.FREE_BOOKINGS, this.httpOptions).subscribe(
        (res: any) => {
          if(res.status == 200 ) {
  
            for (let i = 0; i < res.message.length; i++) {
              const event = res.message[i];
  
              console.log(event);
              var d = new Date(event.start);
              var e = new Date(event.end);
              event.start = d;
              event.end = e;

              delete event.actions;
              
            }
            this.events = res.message;
            this.refresh.next();
          }
          else {
  
          }
          
        }, (err: any) => {
    
          console.log("Error while finding status :: ", err);
        })
    }

    fetchPaymentMethods(){


      this.http.get(environment.METHODS, this.httpOptions).subscribe(
        (res: any) => {
          console.log(res);
          if(res.status == 200 ) {
  
            this.paymentMethods = res.message;
          }
          else {
  
          }
          
        }, (err: any) => {
    
          console.log("Error while finding status :: ", err);
        })
    }

    fetchCalendarData(){

      var req = {
        token: this.token,
        businessID: this.businessID,
      }


      this.http.post(environment.OPEN_CALENDAR_DATA, this.httpOptions).subscribe(
        (res: any) => {
          console.log(res);
          if(res.status == 200 ) {
  
            for (let i = 0; i < res.message.length; i++) {
              const event = res.message[i];
  
              console.log(event);
              var d = new Date(event.start);
              var e = new Date(event.end);
              event.start = d;
              event.end = e;

              delete event.actions;
              
            }
            this.events = res.message;
            this.refresh.next();
          }
          else {
  
          }
          
        }, (err: any) => {
    
          console.log("Error while finding status :: ", err);
        })
    }

    timeSelected(e){
      console.log(e);
      this.selectedTimes.push(e);
      console.log(e);
      alert('Aika valittu')
      document.getElementById('close-modal').click();
      document.getElementById('open-modal').click();
      this.countPrice();

    }

    countPrice(){
      this.totalPrice = 0;
      for (let i = 0; i < this.selectedTimes.length; i++) {
        const time = this.selectedTimes[i];

        this.totalPrice = this.totalPrice + parseFloat(time.slotPrice);
        
      }
    }

    selectMethod(name){
      this.selectedMethod = name;
      this.onSubmit();
    }

    onSubmit(){

      var idarr = [];

      for (let i = 0; i < this.selectedTimes.length; i++) {
        const time = this.selectedTimes[i];

        idarr.push(time._id);
        
      }

      var request = {
        firstName: this.form.get('firstName').value,
        lastName: this.form.get('lastName').value,
        address: this.form.get('address').value,
        zip: this.form.get('postNumber').value,
        city: this.form.get('postOffice').value,
        email: this.form.get('email').value,
        addInfo: this.form.get('addInfo').value,
        phone: this.form.get('phone').value,
        selected: this.selectedMethod,
        bookings: idarr,
        businessID: this.businessID, 
      }


      this.http.post(environment.GET_PAYMET, request, this.httpOptions).subscribe(
        (res: any) => {

          if(res.status == 200){
            window.location.href = res.message;
          }

        

      }, (err: any) => {
        console.log('Tuli virhe..');
      }
    );

      console.log(request);
    }


}
