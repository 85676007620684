

<nav class="navbar navy-blue navbar-expand-lg navbar-light pb-0 height">
    <div class="container-fluid">
      <a class="navbar-brand ps-md-4 me-auto pb-0 pt-0" routerLink="/home"><img width="25%" src="../../assets/images/moodyset_logo_w.png"> </a>
  
      <!-- Collapse button -->
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#basicExampleNav"
        aria-controls="basicExampleNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="line"></span>
        <span class="line"></span>
        <span class="line3"></span>
      </button>
      <div class="collapse navbar-collapse d-md-flex justify-content-md-end" id="basicExampleNav">
  
        <ul class="navbar-nav ms-auto">
<!--           <li class="nav-item">
            <a class="nav-link" routerLink="/{{businessID}}login">Kirjaudu
          </a>
          </li> -->
  <!--         <li class="nav-item">
            <a target="_blank" routerLink="/register" class="nav-link eeli">REKISTERÖIDY</a>
          </li> -->
    
    
    
    
        </ul>
    
    
      </div>
    </div>
  </nav>
  
  