<app-user-header></app-user-header>


<div class="wrapperi">

  <div class="bg-container header pnset-6 container-fluid pt-4 ps-4 pe-4">
    <div class="col-12 row pb-4">
        <div class="col-12 col-md-7 page-title">
            <p class="page-title h2 text-white d-inline-block mb-0">Muokkaa yrityksen tietoja</p> <br>
            <p class="desc-text text-white">Muokkaa tietoja täyttämällä alla olevat tiedot.</p>
        </div>
        <div class="col">
            <nav class="d-none d-md-inline-block ml-md-4">
                <ol class="breadcrumb breadcrumb-links breadcrumb-dark">
                    <li class="breadcrumb-item">
                        <a routerLink="/{{businessID}}/users/home">Koti</a>
                    </li>
                    <li class="breadcrumb-item">
                      <a routerLink="/{{businessID}}/company-details">Yrityksen tiedot</a>
                    </li>
                    <li class="breadcrumb-item active">
                        <a href="#">Muokkaa yrityksen tietoja</a>
                    </li>
                </ol>
            </nav>  
        </div>
    </div>
    <div class="col-12 row">

    </div>
  </div>


  <div class="col-12 p-0 row m-0">
    <div class="col-3"></div>
    <div class="col-12 col-md-6 p-0 input-cover mt-4 p-4">
      <div class="col-12 p-0 mt-4">
        <div class="col-12 text-center p-0">
          <h1 class="mt-4">Muokkaa tietoja</h1>
          <p class="desc-text">Muokkaatietoja täyttämällä alla olevat tiedot.</p>
        </div>
      </div>

      <div id="loader" class="loader-container mt-4" style="display:none">
        <div class="loader mx-auto"></div>
        <p class="text-center pt-3">Tietoja muokataan...</p>
      </div>

      <div style="display: block;" id="form-container" class="">
        <form class="flex-row add-pet-form" [formGroup]="form"
        (ngSubmit)="onSubmit()">

        <!-- Yrityksen nimi ja sukunimi -->
        <div class="col-12 row m-0 pt-2">

            <div class="form-floating mb-2  col-12 col-md-12 p-0">
                <input type="text" placeholder="Yrityksen nimi" formControlName="businessName" id="businessName" class="form-control" [class.invalid-input]="businessName.errors && businessName.touched" required>
                <label for="businessName">Yrityksen nimi</label>
                <div class="my-alerts" *ngIf="businessName.invalid && businessName.touched">
                <p class="mt-2" *ngIf="businessName.errors.required">Yritys on pakollinen</p>
                <p *ngIf="businessName.errors.businessName"> Vaaditaan kunnollinen yritys</p>
                </div>
            </div>

        </div>
          <!-- Sähköposti -->
          <div class="col-12 row m-0 pt-2">
                <div class="form-floating mb-2  col-12 col-md-12 p-0">
                    <input type="email" placeholder="Sähköposti" formControlName="email" id="email" class="form-control" [class.invalid-input]="email.errors && email.touched" required>
                    <label for="email">Sähköposti</label>
                    <div class="my-alerts" *ngIf="email.invalid && email.touched">
                    <p class="mt-2" *ngIf="email.errors.required">email on pakollinen</p>
                    <p *ngIf="email.errors.email"> Vaaditaan kunnollinen businessName</p>
                    </div>
                </div>
            </div>
            <!-- Puhelin -->
            <div class="col-12 row m-0 pt-2">

                <div class="form-floating mb-2  col-12 col-md-12 p-0">
                    <input type="phone" placeholder="Puhelinnumero" formControlName="phone" id="phone" class="form-control" [class.invalid-input]="phone.errors && phone.touched" required>
                    <label for="phone">Puhelinnumero</label>
                    <div class="my-alerts" *ngIf="phone.invalid && phone.touched">
                    <p class="mt-2" *ngIf="phone.errors.required">Puhelinnumero on pakollinen</p>
                    <p *ngIf="phone.errors.phone"> Vaaditaan kunnollinen puhelinnumero</p>
                    </div>
                </div>
            </div>
            <!-- OVT ja Operator -->
            <div class="col-12 row m-0 pt-2">

                <div class="form-floating mb-2 col-12 col-md-6 p-0">
                    <input type="text" placeholder="OVT-tunnus" formControlName="ovt" id="ovt" class="form-control" [class.invalid-input]="ovt.errors && ovt.touched" required>
                    <label for="ovt">OVT-tunnus</label>
                    <div class="my-alerts" *ngIf="ovt.invalid && ovt.touched">
                    <p class="mt-2" *ngIf="ovt.errors.required">OVT-tunnus on pakollinen</p>
                    <p *ngIf="ovt.errors.ovt"> Vaaditaan kunnollinen OVT-tunnus</p>
                    </div>
                </div>
                <div class="form-floating mb-2 ps-md-2 col-12 col-md-6 p-0">
                    <input type="text" placeholder="Verkkolaskutusoperaattori" formControlName="operator" id="operator" class="form-control" [class.invalid-input]="operator.errors && operator.touched" required>
                    <label for="operator">Verkkolaskutusoperaattori</label>
                    <div class="my-alerts" *ngIf="operator.invalid && operator.touched">
                    <p class="mt-2" *ngIf="operator.errors.required">Verkkolaskutusoperaattori on pakollinen</p>
                    <p *ngIf="operator.errors.operator"> Vaaditaan kunnollinen Verkkolaskutusoperaattori</p>
                    </div>
                </div>
            </div>

            <!-- Puhelin -->
            <div class="col-12 row m-0 pt-2">

              <div class="form-floating mb-2  col-12 col-md-12 p-0">
                  <input type="phone" placeholder="Yrityksen puh" formControlName="businessPhone" id="businessPhone" class="form-control" [class.invalid-input]="businessPhone.errors && businessPhone.touched" required>
                  <label for="businessPhone">Yrityksen puh</label>
                  <div class="my-alerts" *ngIf="businessPhone.invalid && businessPhone.touched">
                  <p class="mt-2" *ngIf="businessPhone.errors.required">Yrityksen puh on pakollinen</p>
                  <p *ngIf="businessPhone.errors.businessPhone"> Vaaditaan kunnollinen Yrityksen puh</p>
                  </div>
              </div>
          </div>

          <!-- Puhelin -->
          <div class="col-12 row m-0 pt-2">

            <div class="form-floating mb-2  col-12 col-md-12 p-0">
                <input type="email" placeholder="Yrityksen sähköposti" formControlName="businessEmail" id="businessEmail" class="form-control" [class.invalid-input]="businessEmail.errors && businessEmail.touched" required>
                <label for="businessEmail">Yrityksen sähköposti</label>
                <div class="my-alerts" *ngIf="businessEmail.invalid && businessEmail.touched">
                <p class="mt-2" *ngIf="businessEmail.errors.required">Yrityksen sähköposti on pakollinen</p>
                <p *ngIf="businessEmail.errors.businessEmail"> Vaaditaan kunnollinen Yrityksen sähköposti</p>
                </div>
            </div>
        </div>


            <!-- Vasen -->
            <h5>Vasemman kuplan värit</h5>
            <div class="col-12 row m-0 pt-2">

              <div class="form-floating mb-2 col-12 col-md-6 p-0">
                  <input type="color" placeholder="Taustaväri" formControlName="leftBackground" id="leftBackground" class="form-control">
                  <label for="leftBackground">Taustaväri</label>

              </div>
              <div class="form-floating mb-2 col-12 col-md-6 p-0">
                <input type="color" placeholder="Tekstin väri" formControlName="leftText" id="leftText" class="form-control">
                <label for="leftText">Tekstin väri</label>

            </div>
          </div>


          <!-- Oikea  -->
          <h5>Oikean kuplan värit</h5>
          <div class="col-12 row m-0 pt-2">

            <div class="form-floating mb-2 col-12 col-md-6 p-0">
                <input type="color" placeholder="Taustaväri" formControlName="rightBackground" id="rightBackground" class="form-control">
                <label for="rightBackground">Taustaväri</label>

            </div>
            <div class="form-floating mb-2 col-12 col-md-6 p-0">
              <input type="color" placeholder="Tekstin väri" formControlName="rightText" id="rightText" class="form-control">
              <label for="rightText">Tekstin väri</label>

          </div>
        </div>

          <!-- Painike -->
          <h5>Painikkeiden värit</h5>
          <div class="col-12 row m-0 pt-2">

            <div class="form-floating mb-2 col-12 col-md-6 p-0">
                <input type="color" placeholder="Taustaväri" formControlName="buttonBackground" id="buttonBackground" class="form-control">
                <label for="buttonBackground">Taustaväri</label>

            </div>
            <div class="form-floating mb-2 col-12 col-md-6 p-0">
              <input type="color" placeholder="Tekstin väri" formControlName="buttonText" id="buttonText" class="form-control">
              <label for="buttonText">Tekstin väri</label>

          </div>
        </div>
        <h5>Taustaväri</h5>
        <div class="col-12 row m-0 pt-2">

          <div class="form-floating mb-2 col-12 p-0">
              <input type="color" placeholder="Taustaväri" formControlName="backgroundColor" id="backgroundColor" class="form-control">
              <label for="backgroundColor">Taustaväri</label>

          </div>
          </div>

          
                
            <div class="form-inline mt-2">
              <div class="form-group mb-2 flex-row">
                <button [disabled]="form.invalid" class="btn btn-feedbacker w-100"><i class="fas fa-sign-in-alt me-2"></i>Muokkaa tietoja</button>
              </div>
            </div>
          </form>

        </div>
      </div>
      <div class="col-3"></div>
</div>