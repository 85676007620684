<app-user-header></app-user-header>


<div class="wrapperi">

  <div class="bg-container header pnset-6 container-fluid pt-4 ps-4 pe-4">
    <div class="col-12 row pb-4">
        <div class="col-12 col-md-7 page-title">
            <p class="page-title h2 text-white d-inline-block mb-0">{{btnText}}</p> <br>
            <p class="desc-text text-white">{{btnText}} täyttämällä alla olevat tiedot.</p>
        </div>
        <div class="col">
            <nav class="d-none d-md-inline-block ml-md-4">
                <ol class="breadcrumb breadcrumb-links breadcrumb-dark">
                    <li class="breadcrumb-item">
                        <a routerLink="/{{businessID}}/users/home">Koti</a>
                    </li>
                    <li class="breadcrumb-item">
                      <a routerLink="/{{businessID}}/company-details">Yrityksen tiedot</a>
                    </li>
                    <li class="breadcrumb-item active">
                        <a href="#">{{btnText}}</a>
                    </li>
                </ol>
            </nav>  
        </div>
    </div>
    <div class="col-12 row">

    </div>
  </div>


  <div class="col-12 p-0 row m-0">
    <div class="col-3"></div>
    <div class="col-12 col-md-6 p-0 input-cover mt-4 p-4">
      <div class="col-12 p-0 mt-4">
        <div class="col-12 text-center p-0">
          <h1 class="mt-4">{{btnText}}</h1>
          <p class="desc-text">{{btnText}} täyttämällä alla olevat tiedot.</p>
        </div>
        <div class="alert alert-warning" *ngIf="messageErr">
          <p class="text-center">Samalla tunnisteella löyti jo palautekysely</p>
        </div>
      </div>

      <div id="loader" class="loader-container mt-4" style="display:none">
        <div class="loader mx-auto"></div>
        <p class="text-center pt-3">Käyttäjää lisätään...</p>
      </div>

      <div style="display: block;" id="form-container" class="">
        <form class="flex-row add-pet-form" [formGroup]="form"
        (ngSubmit)="onSubmit()">


          <!-- Sähköposti -->
          <div class="col-12 row m-0 pt-2">

            <div class="form-floating mb-2  col-12 col-md-12 p-0">
                <input type="email" placeholder="Nimi" formControlName="name" id="name" class="form-control" [class.invalid-input]="name.errors && name.touched" required>
                <label for="name">Nimi</label>
                <div class="my-alerts" *ngIf="name.invalid && name.touched">
                <p class="mt-2" *ngIf="name.errors.required">name on pakollinen</p>
                <p *ngIf="name.errors.name"> Vaaditaan kunnollinen firstName</p>
                </div>
            </div>

        </div>

          
                
            <div class="form-inline mt-2">
              <div class="form-group mb-2 flex-row">
                <button [disabled]="form.invalid" class="btn btn-feedbacker w-100"><i class="fas fa-sign-in-alt me-2"></i>{{btnText}}</button>
              </div>
            </div>
          </form>

        </div>
      </div>
      <div class="col-3"></div>
</div>