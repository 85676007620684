<app-user-header></app-user-header>
<div class="" *ngIf="device" >

    <div class="bg-container header pnset-6 container-fluid pt-4 ps-4 pe-4">
        <div class="col-12 row pb-4">
            <div class="col-12 col-md-7 page-title">
                <p class="page-title h2 text-white d-inline-block mb-0">Laite {{device.name}}</p> <br>
                <button class="btn btn-feedbacker me-4 mt-4" routerLink="/{{businessID}}/edit-device/{{deviceID}}"><i class="fas fa-edit me-2"></i>Muokkaa</button>
            </div>
            <div class="col">
                <nav class="d-none d-md-inline-block ml-md-4">
                    <ol class="breadcrumb breadcrumb-links breadcrumb-dark">
                        <li class="breadcrumb-item">
                            <a routerLink="/{{businessID}}/users/home">Koti</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a routerLink="/{{businessID}}/devices">Laitteet</a>
                        </li>
                        <li class="breadcrumb-item active">
                            <a href="#">{{device.name}}</a>
                        </li>
                    </ol>
                </nav>  
            </div>
        </div>
        <div class="col-12 row m-0" >
            
        </div>
      </div>

      <div class="col-12 ps-4 row nset-card pb-4">
        <div class="col-xl-8">
            <div class="card card-bg-question p-0 p-md-4">
                <div class="card-header">
                    <div class="row-align-items-center">
                        <div class="col">
                            <h6 class="text-light text-uppercase ls-1 mb-1">Laitteen kysymys</h6>
                            <h5 class="h3 text-white mb-0">Kysymys</h5>
                        </div>
                    </div>
                </div>
                <div class="table-responsive" *ngIf="feedback">
                    <table class="table align-items-center table-flush">
                        <thead class="thead-dark">
                            <tr>
                                <th>Kysymys</th>
                                <th>Katselumäärä</th>
                                <th>Tutustu</th>
                            </tr>
                        </thead>
                        <tbody class="tbody-dark">
                            <tr >
                                <td>{{feedback.question}}</td>
                                <td>{{feedback.visitors}}</td>
                                <td><button routerLink="/{{businessID}}/feedback/{{this.feedbackID}}" class="btn btn-feedbacker"><i class="fa fa-info-circle"></i></button></td>
                            </tr>
                        </tbody>

                    </table>
                </div>

                
            </div>

        </div>
        <div class="col-xl-4">
            <div class="card ">
                <div class="card-header">
                    <div class="row-align-items-center">
                        <div class="col">
                            <h6 class="text-dark text-uppercase ls-1 mb-1">Laitteen tiedot</h6>
                            <h5 class="h3 text-black mb-0">Tiedot</h5>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table align-items-center table-flush">
                            <thead class="thead-light">
                                <tr>
                                    <th>Tieto</th>
                                    <th>Vastaus</th>
                                </tr>
                            </thead>
                            <tbody class="tbody-light">
                                <tr>
                                    <th width="25%">Nimi</th>
                                    <td width="75%">{{device.name}}</td>
                                </tr>
                                <tr>
                                    <th width="25%">Merkki</th>
                                    <td width="75%">
                                        {{device.brand}}
                                    </td>
                                </tr>
                                <tr>
                                    <th width="25%">Malli</th>
                                    <td width="75%">{{device.model}}</td>
                                </tr>
                            </tbody>
    
                        </table>
                    </div>
                </div>
                
            </div>
        </div>
    </div>

    <div class="col-12 p-0 p-md-4">
        <div class="card ">
            <div class="card-header">
                <div class="row-align-items-center">
                    <div class="col">
                        <h6 class="text-dark text-uppercase ls-1 mb-1">Palautekysely</h6>
                        <h5 class="h3 text-black mb-0">Tiedot</h5>
                    </div>
                </div>
                <div class="table-responsive" *ngIf="feedback && feedback.answers && feedback.answers.length > 0;else no_answers_content">
                    <table class="table align-items-center table-flush">
                        <thead class="thead-light">
                            <tr>
                                <th>Aika</th>
                                <th>Arvosana</th>
                                <th>Palaute</th>
                            </tr>
                        </thead>
                        <tbody class="tbody-light">
                            <tr *ngFor="let answer of feedback.answers">
                                <th>{{answer.createdDate | formateDate}}</th>
                                <td>{{answer.value}}</td>
                                <td>{{answer.text}}</td>
                            </tr>
                        </tbody>

                    </table>
                </div>
                <ng-template #no_answers_content>
                    <div class="col-12 row m-0">
                        <div class="col-3"></div>
                        <div class="col-12 col-md-6">
                            <div class="alert alert-warning">
                                <p class="normal-text text-center">Kyselysi ei ole vielä saanut vastauksia.</p>
                            </div>
                        </div>
                        <div class="col-3"></div>
                    </div>
                </ng-template>
            </div>
            
        </div>
    </div>
</div>

