import { AfterContentInit, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../service/auth.service';
import { base_host, environment, my_port, my_protocol } from 'src/environments/environment';


@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.css']
})
export class CompanyDetailsComponent implements OnInit {

  errMessage = "";
  successMessage = "";
  feedbackID:string = this.route.snapshot.params.id;
  businessID: string = this.route.snapshot.params.businessID;
  authToken = localStorage.getItem('auth');
  company = [];
  users = [];
  pathUrl;
  order;
  isAverageCert = false;
  isNPSCert = false;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'auth': `${this.authToken}`  }),
    withCredentials: true,
  };

  constructor(
    private router: Router,
    private http: HttpClient,
    private route:ActivatedRoute,
    private authService: AuthService) {
      this.authService.validateBusiness(this.businessID)

     }
  ngOnInit(): void {
    this.getBusinessDetails();
    this.getUsers()
    this.fetchOrderDetails()
  }

  fetchOrderDetails() {

    this.http.get(environment.ORDER_DATA, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {
          this.order = [];
          this.isAverageCert = false;
          this.isNPSCert = false;
          this.order = res.message;
          console.log(this.order);

          for (let i = 0; i < this.order.certificates.selectedCertificates.length; i++) {
            const cert = this.order.certificates.selectedCertificates[i];

            if(cert == 'businessNPS'){
              this.isNPSCert = true;
            }

            if(cert == 'businessAverage'){
              this.isAverageCert = true;
            }
          }

        }
        else {
          var anElm = document.getElementById('notadded');
          anElm.style.display="block";
          console.log('ei ole kyseistä käyttäjää')
        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })


  }

  getBusinessDetails() {

    this.http.get(environment.USER_BUSINESS, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {
          this.company = res.message;

          this.pathUrl = my_protocol.protocol+'://' + base_host.base_host + my_port.port+ '/#/certificates/'+this.company[0].businessName+'/' + this.businessID + '/' + this.company[0].token;


          console.log(this.company);

        }
        else {
          var anElm = document.getElementById('notadded');
          anElm.style.display="block";
          console.log('ei ole kyseistä käyttäjää')
        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })


  }

  getUsers() {

    this.http.get(environment.USERS, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {
          this.users = res.message;

          console.log(this.users);

        }
        else {
          var anElm = document.getElementById('notadded');
          anElm.style.display="block";
          console.log('ei ole kyseistä käyttäjää')
        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })


  }

  generateCodeNps(){
    var style = '<style>.nset-feedbacker{width: 400px!important;}@media(max-width:500px) {.nset-feedbacker{width: 100%!important;}}</style>'
    var str = ""

      var str = '<iframe height="340"  src="'+this.pathUrl+'/nps/12/business" > </iframe>';
    

    
    (<HTMLTextAreaElement>document.getElementById('code-area')).value = str;
    console.log('Upotuskoodisi: '+style+'\n'+str)
  }

  generateCodeAverage(){
    var style = '<style>.nset-feedbacker{width: 400px!important;}@media(max-width:500px) {.nset-feedbacker{width: 100%!important;}}</style>'
    var str = ""

      var str = '<iframe height="340"  src="'+this.pathUrl+'/average/12/business" > </iframe>';


    
    (<HTMLTextAreaElement>document.getElementById('code-area')).value = str;
    console.log('Upotuskoodisi: '+style+'\n'+str)
  }

  removeCert(type){

    var req = {
      certificate: type,
    }


    this.http.post(environment.REMOVE_CERT, req,this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {
          document.getElementById('close-delete-nps').click();
          document.getElementById('close-delete-average').click();
          alert('Sertifikaatti on poistettu onnistuneesti. Paina OK jataaksesi');

          this.fetchOrderDetails()



        }
        else {
          var anElm = document.getElementById('notadded');
          anElm.style.display="block";
          console.log('ei ole kyseistä käyttäjää')
        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })
  }

  addCert(type){

    var req = {
      certificate: type,
    }


    this.http.post(environment.ADD_CERT, req,this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {

          this.fetchOrderDetails();
          if(type == 'businessAverage'){
            this.generateCodeAverage();
          }
          else if(type == 'businessNPS'){
            this.generateCodeNps()
          }


          console.log(document.getElementById('show-btn'))
          document.getElementById('show-btn').click();



        }
        else {
          alert('Lisäys epäonnistui. Ehkä maksimimäärä on täynä')
          console.log('ei ole kyseistä käyttäjää')
        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })
  }

  saveReferences(){
    var req = {
      npsc: (Number((<HTMLInputElement>document.getElementById('npsc')).value) / 100).toFixed(2),
      npss:(Number((<HTMLInputElement>document.getElementById('npss')).value) / 100).toFixed(2),
      csatc:(Number((<HTMLInputElement>document.getElementById('csatc')).value) / 100).toFixed(2),
      csats:(Number((<HTMLInputElement>document.getElementById('csats')).value) / 100).toFixed(2),
      csec:(Number((<HTMLInputElement>document.getElementById('cesc')).value) / 100).toFixed(2),
      cess: (Number((<HTMLInputElement>document.getElementById('cess')).value) / 100).toFixed(2),
    }



    this.http.put(environment.GOALS, req,this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {
          this.getBusinessDetails();
          alert('Muokkaaminen onnistui');




        }
        else {
          alert('Muokkaaminen epäonnistui.')
          console.log('ei ole kyseistä käyttäjää')
        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })
  }

}
