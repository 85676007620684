import { Component, OnInit, OnDestroy, ViewChild, AfterContentInit, ElementRef, AfterViewChecked } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { Router,ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from "@auth0/angular-jwt";


declare var $:any;

const helper = new JwtHelperService;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy, AfterContentInit,AfterViewChecked {

  doctorList: any = []; // Doctor list
  globalMessages: any = []; // Map of doctor and message
  currentActiveMessageList: any = []; // Current Acitve message list
  defaultDocterName = 'patient';
  bamboraPaymentMethods: any = []; // bambora maksutavat
  currentUserRole ='';//nykyisen käyttäjän rooli
  currentUserId=''; 
  currentUserName = ''; // nykyisen käyttäjän käyttäjänimi
  currentUserCredit= 0; // alku credit oletus --> tämä tarkastetaan CheckMyCredit func, kutsuttu nginit
  lastMessage=''; //viimeisin toisen käyttäjän lähettämä viesti
  clinicOpen:any = [];
  imgOutput = "";
  connErr = false;
  today;
  sessionStatus = "";
  activeConsultantData = {};
  reasonsArr=[];
  visitHistory: any = [];
  currentActiveDoctorName: string = "Autan alkuun"; // Current Acitve message list
  authToken = localStorage.getItem('auth');
  showReplyBox: boolean = false;
  HELPER_NAME = 'Autan alkuun'
  DR_ASSISTANT_NAME: string = '';
  currentTemplate = "";
  messages = [];
  typeID: string = this.route.snapshot.params.typeID;
  messageSuccess="";
  messageErr="";
  checkTimeInterval;
  businessToken = this.route.snapshot.params.businessToken;
  colors;
  
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'auth': `${this.authToken}` }),
    withCredentials: true,
  };

  replyMessage: string = '';

  addFeedback: FormGroup;


  @ViewChild("chatMessageContainer", { static: true }) chatMessageContainer: ElementRef;
  @ViewChild('openModal', { static: true }) openModal:ElementRef;
  @ViewChild('product-info-modal') productInfoModal:ElementRef;
  @ViewChild('start-consultation-modal') startConsultationModal:ElementRef;
  @ViewChild("discountCodeInput") discountCodeInput: ElementRef;

  disableScrollDown: boolean = false;
  bamboraPaymentButtonVisible: boolean = false;
  paypalButtonVisible: boolean = false;
  finalAmount: number = 1;
  selectedMessage;
  currentUserIp;
  ipchecker;
  pinger;


  constructor(
     private router: Router,
     private authService: AuthService, 
     private http: HttpClient, 
     private fb: FormBuilder,
     private route: ActivatedRoute,) {
    this.authService.validateLogin();
  }

  ngOnInit() {

    this.handleAppConnectivityChanges();
    this.waitStart();
    


    this.addFeedback = this.fb.group({
      npsRange: "",
      npsText: "",
      acceptedContact: "",
    });
    }

  ngOnDestroy() {

  }
  ngAfterContentInit(){
  }







  replaceURLWithHTMLLinks(text)
  {
    var exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    return text.replace(exp,'<a class="my-link"  target="_blank" href="$1">$1</a>'); 


  }


  
//----------------------------------------------------------------------------------------------------------
// viestilista
//----------------------------------------------------------------------------------------------------------


  setLinksForMessages(msgObj) {
    if(this.currentActiveDoctorName != this.DR_ASSISTANT_NAME ){
      if(msgObj[this.currentActiveDoctorName] != undefined) {
        var msgList = msgObj[this.currentActiveDoctorName]['messageList'];
        
        for(var i = 0; i < msgList.length; i++ ) {
          if(msgList[i].template == 'TEMPLATE_14' || msgList[i].template == 'TEMPLATE_203' || msgList[i].template == 'TEMPLATE_202') {
            msgList[i].actMessage = this.replaceURLWithHTMLLinks(msgList[i].actMessage);
          }
          if(msgList[i].template == 'TEMPLATE_27'){
            msgList[i].actMessage = JSON.parse(msgList[i].actMessage);

          }
        }
      }
    }
    else{
      if(msgObj[this.currentActiveDoctorName] != undefined) {
        var msgList = msgObj[this.currentActiveDoctorName]['messageList'];
        for(var i = 0; i < msgList.length; i++ ) {
          if(msgList[i].template == 'TEMPLATE_203' || msgList[i].template == 'TEMPLATE_202') {
            msgList[i].actMessage = this.replaceURLWithHTMLLinks(msgList[i].actMessage);
          }
        }
      }
    }
  }


  waitHeight() {

    setTimeout(() =>{
      if(document.getElementById('chat-window')){
         document.getElementById('chat-window').classList.add('chat-window-main-container-no-replybox');
      }
    },2000)

  }

  waitStart() {

    setTimeout(() =>{
      this.publicBusinessColors();
      this.startQuestionnaire()
    },1000)

  }

  waitColor() {

    setTimeout(() =>{
      this.colorService()
    },100)

  }

  waitHeightSm() {

    if(this.currentActiveDoctorName != this.DR_ASSISTANT_NAME) {

      setTimeout(() =>{
        if(document.getElementById('chat-window') && document.getElementById('chat-window').classList.contains('chat-window-main-container-no-replybox')) {
          document.getElementById('chat-window').classList.remove('chat-window-main-container-no-replybox');
        }
        else{
        }
      },1000)

    }
  }


  waitScroll() {
    var that = this;

    setTimeout(() =>{
      that.scrollBottomOfChatContainer();
    },1000)

  }

  waitScrollSm() {
    var that = this;

    setTimeout(() =>{
      that.scrollBottomOfChatContainer();
    },100)

  }

  waitScrollPageSm() {
    var that = this;

    setTimeout(() =>{
      that.scrollBottomOfPage();
    },100)

  }


  logOut() {
    this.authService.doLogOut();
    this.router.navigate(['login']);
  }


  ngAfterViewInit(){
    //this.findMySessionStatus()
    this.waitScroll();
  }


  ngAfterViewChecked() {

    if(this.connErr == true){
      window.location.reload();
    }

  }

  handleAppConnectivityChanges(): void {

    var that = this;
    window.addEventListener('load', function(e) {
      if (navigator.onLine) {
          console.log('We\'re online!');
          //that.notificationService('Verkkoyhteys palautui', 5000);
      } else {
          alert('Sinulla ei ole toimivaa verkkoyhteyttä');
          window.location.reload();
      }
  }, false);

    window.addEventListener('offline', () => {
      alert('Verkkoyhteytesi on katkennut. Onnistuneen konsultaation saamiseksi, mene paikkaan, jossa internetyhteytesi toimii. Painamalla "OK" järjestelmä yrittää muodostaa yhteyden uudelleen.');
      window.location.reload();
      })

      window.addEventListener('online', () => {
        this.notificationService('Verkkoyhteys palautunut', 5000)
      })
    
  }

  
  notificationService(text,delay){
    var elm = document.getElementById('toaster');
    elm.setAttribute('data-delay',delay);
    document.getElementById('toast-text').innerHTML = "";
    document.getElementById('toast-text').innerHTML = text;
    $('.toast').toast('show');
    var g = document.getElementById('toaster')
    g.classList.remove('d-none');
    this.waitToast(g,delay);
  }

  waitToast(g,delay) {
    setTimeout(() =>{
      g.classList.add('d-none');
      //that.ErrMessage = false;
    },delay)
  }

  onScroll() {
    let element = this.chatMessageContainer.nativeElement;
    let currentWindowPos = element.scrollHeight - element.scrollTop;
    let diff = currentWindowPos - element.clientHeight;
    diff = Math.abs(diff);
    if (this.disableScrollDown && diff <= 1) {
      this.disableScrollDown = false
    } else {
      this.disableScrollDown = true
    }
  }

  private scrollToBottom(): void {


    if (this.disableScrollDown) {
      return
      
    }
    try {
      //smoothscroll.polyfill();
      this.chatMessageContainer.nativeElement.scrollTo({ top: this.chatMessageContainer.nativeElement.scrollHeight, behavior: 'smooth' })
      //this.SmoothVerticalScrolling(this.chatMessageContainer, 300, "bottom")
      // this.myScrollContainer.nativeElement.scroll
      //this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }


  setAnswer(message, answer) {
    message.answer = answer;


    return true;

  }


  OnAnswerSelected(message) {
    console.log(message);

    var nxt = "";
    for (let i = 0; i < message.answers.length; i++) {
      const ans = message.answers[i];
      console.log(ans)
      
      if(ans.name == message.answer){
        nxt = ans.nextQuestionID
      }
    }
    let reqBody = {
      next: nxt,
      answer: message.answer,
      isEnd: message.isEnd
    } 

    if (reqBody.answer == null || reqBody.answer == undefined ) {
      message.oldMessage = false;
      alert('Unhodit lisätä vastauksen!');
      message.oldMessage = false;
      return;
    }

    if(reqBody.answer != false){
      if(!reqBody.answer){
        message.oldMessage = false;
        alert('Vastaus tulee olla');
        message.oldMessage = false;
        return;
      }
    }

    this.http.post(environment.ANSWER_SELECTED_END_POINT, reqBody, this.httpOptions).subscribe(
      (res: any) => {
        console.log(res.endcomes);
        this.currentActiveMessageList.push(res);
        this.waitScrollSm();
        this.waitScroll()
        this.waitColor()
        console.log(this.currentActiveMessageList);
      }, (err: any) => {
        console.log("Error while answer selected : ", err);
      }
    )
  }





  openNav() {

      document.getElementById("mySidenav").classList.add('kolmonen');
      document.getElementById("main-nav").classList.add('d-none');
      document.getElementById("mySidenav").classList.remove('korkeus');
      document.getElementById("main-chat-container").classList.add('disable-scroll');
      document.getElementById("section-two-container").classList.add('overlay-sidenav');
      document.getElementById("mySidenav").classList.add('kolmonen-active');
      document.getElementById("mySidenav").classList.remove('kolmonen');
  }


  closeNav() {
    document.getElementById("mySidenav").classList.remove('kolmonen-active');
    document.getElementById("mySidenav").classList.remove('kolmonen');
    document.getElementById("main-nav").classList.remove('d-none');
    document.getElementById("mySidenav").classList.add('korkeus');
    document.getElementById("main-chat-container").classList.remove('disable-scroll');
    document.getElementById("section-two-container").classList.remove('overlay-sidenav');
  }





  scrollBottomOfChatContainer() {
    var objDiv = document.getElementById("main-chat-container");
    //objDiv.scrollTop = objDiv.scrollHeight;'


    if(objDiv){
      objDiv.scroll({
        top: objDiv.scrollHeight,
        behavior: 'smooth'
      });
    }
  }

  scrollBottomOfPage() {

    var objDiv = document.getElementById("full-chat-wrap");


    if(objDiv){
      objDiv.scroll({
        top: objDiv.scrollHeight + 62,
        behavior: 'smooth'
      });
    }
  }

  startQuestionnaire(){
    this.http.get(environment.FETCH_FIRST_QUESTION+'/'+this.typeID, this.httpOptions).subscribe(
      (res: any) => {
        console.log(res.message)
        Object.assign(res.message, {oldMessage: false});
         this.currentActiveMessageList.push(res.message)
         this.waitScrollSm();
         this.waitColor()
      }, (err: any) => {
        console.log("Error while answer selected : ", err);
      })
    }

    publicBusinessColors(){
      var req = {
        token: this.businessToken,
      }
      this.http.post(environment.FETCH_OPEN_BUSINESS,req, this.httpOptions).subscribe(
        (res: any) => {
          if(res.status == 200){
            this.colors = res.message;
            this.waitColor()
            console.log(this.colors)
          }
        }, (err: any) => {
          console.log("Error while answer selected : ", err);
        })
      }

      colorService(){
        let btns = document.getElementsByClassName('btn-secondary');
        
        if(btns.length > 0){
          for (let i = 0; i < btns.length; i++) {
            const btn = <HTMLElement>btns[i];
            btn.style.backgroundColor = this.colors.buttonBackground;
            btn.style.color = this.colors.buttonText;
          }
    
        }
    
        var right = document.getElementsByClassName('text-message-right');
        
        if(right.length > 0){
          for (let i = 0; i < right.length; i++) {
            const btn = <HTMLElement>right[i];
            btn.style.backgroundColor = this.colors.rightBackground;
          }
    
        }

        var left = document.getElementsByClassName('text-message-left');
        
        if(left.length > 0){
          for (let i = 0; i < left.length; i++) {
            const btn = <HTMLElement>left[i];
            btn.style.backgroundColor = this.colors.leftBackground;
          }
    
        }

        var leftText = document.getElementsByClassName('text-message-left-text');
        
        if(leftText.length > 0){
          for (let i = 0; i < leftText.length; i++) {
            const btn = <HTMLElement>leftText[i];
            btn.style.color = this.colors.leftText;
          }
    
        }

        document.getElementById('section-two-container').style.backgroundColor = this.colors.backgroundColor;

        var rightText = document.getElementsByClassName('text-message-right-text');
        
        if(rightText.length > 0){
          for (let i = 0; i < rightText.length; i++) {
            const btn = <HTMLElement>rightText[i];
            btn.style.color = this.colors.rightText;
          }
    
        }
      }

      sendContactDetails(message){
        var email =(<HTMLInputElement>document.getElementById('user-email')).value;
        var text = (<HTMLTextAreaElement>document.getElementById('user-text')).value;


        var isEmail = this.validateEmail(email);
        console.log(isEmail);

        if(isEmail){
          var req = {
            email: email,
            text: text,
            message: message,
            businessToken: this.businessToken,
          }

          this.http.post(environment.SEND_OPEN_CONTACTS, req, this.httpOptions).subscribe(
            (res: any) => {
              console.log(res.message)
              Object.assign(res.message, {oldMessage: false});
               this.currentActiveMessageList.push(res.message)
               this.waitScrollSm();
               this.waitColor()
            }, (err: any) => {
              console.log("Error while answer selected : ", err);
            })
        }
        else{
          alert('sähköpostiosoitteesi on väärin kirjoitettu')
        }
      }

      validateEmail(email) 
      {
          var re = /\S+@\S+\.\S+/;
          return re.test(email);
      }
}













  
  


