import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { base_host, environment, my_port, my_protocol } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';

@Component({
  selector: 'app-edit-device',
  templateUrl: './edit-device.component.html',
  styleUrls: ['./edit-device.component.css']
})
export class EditDeviceComponent implements OnInit {

  errMessage = "";
  successMessage = "";
  deviceID:string = this.route.snapshot.params.id;
  businessID: string = this.route.snapshot.params.businessID;
  authToken = localStorage.getItem('auth');
  feedbacks = [];
  device;
  form: FormGroup;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'auth': `${this.authToken}`  }),
    withCredentials: true,
  };

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private http: HttpClient,
    private route:ActivatedRoute,
    private authService: AuthService) {
      this.authService.validateBusiness(this.businessID)

     }

  ngOnInit(): void {
    this.getDevice();

    this.form = this.fb.group({
      name: ['', Validators.required],

    }); 
  }

  getDevice() {


    this.http.get(environment.DEVICE+'/'+this.deviceID, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {
          this.device = res.message[0];
          this.getFeedbacks()

          this.form = this.fb.group({
            name: [this.device.name, Validators.required],
          });
          

        }
        else {
          var anElm = document.getElementById('notadded');
          anElm.style.display="block";
          console.log('ei ole kyseistä käyttäjää')
        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })


  }

  getFeedbacks() {

    this.http.get(environment.FEEDBACKS, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {
          this.feedbacks = res.message;
          console.log(this.feedbacks);

          this.waitStyle()

        }
        else {
          var anElm = document.getElementById('notadded');
          anElm.style.display="block";
          console.log('ei ole kyseistä käyttäjää')
        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })


  }
  get name() { return this.form.get('name') };
  onSubmit(){

    var select = (<HTMLSelectElement>document.getElementById('feedback-select')).value

    if(!select || select == undefined || select == null){
      alert('Sinun täytyy merkitä laitteeseen palautekysely');
      return;
    }

    const request = {
      name: this.form.get('name').value,
      feedbackID: select,
      deviceID: this.deviceID,            
    }

    this.http.put(environment.DEVICE, request, this.httpOptions).subscribe(
      (res: any) => {
        console.log(res);
        if (res.status == 200) {
          this.router.navigate(['/'+this.businessID+'/device/'+this.deviceID])
        } 
        
        else {

          alert('Päivitys epäonnistui')
          //loginError = true;
        }
      }, (err: any) => {

        console.log("Error while login :: ", err);
      }
    )

  }

  selectValue(){
    
    for (let i = 0; i < this.feedbacks.length; i++) {
      const feedback = this.feedbacks[i];

      if(feedback._id.toString() == this.device.feedbackID.toString()){
        

        (<HTMLSelectElement>document.getElementById('feedback-select')).value = feedback._id
        
      }
      
    }
  }



  waitStyle() {
    var that = this;

    setTimeout(() =>{
      that.selectValue();
      
    },100)

  }
}
