import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';

@Component({
  selector: 'app-calendars',
  templateUrl: './calendars.component.html',
  styleUrls: ['./calendars.component.css']
})
export class CalendarsComponent implements OnInit {

  errMessage = "";
  successMessage = "";
  businessID: string = this.route.snapshot.params.businessID;
  calendars = [];
  authToken = localStorage.getItem('auth');
  feedbackValues;
  order;
  type = 'none';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'auth': `${this.authToken}`  }),
    withCredentials: true,
  };

  constructor(
    private router: Router,
    private http: HttpClient,
    private route:ActivatedRoute,
    private authService: AuthService) {
      this.authService.validateBusiness(this.businessID)

     }

  ngOnInit(): void {
    this.getCalendars();
    this.fetchOrderDetails();
  }

  fetchOrderDetails() {

    this.http.get(environment.ORDER_DATA, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {
          this.order = res.message;
          console.log(this.order);

        }
        else {

        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })


  }

  getCalendars() {

    this.http.get(environment.CALENDARS, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {
          this.calendars = res.message;
          console.log(this.calendars);

        }
        else {
          this.calendars = [];
          console.log(this.calendars.length)
          

        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })


  }

}
