import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';

@Component({
  selector: 'app-device-hub',
  templateUrl: './device-hub.component.html',
  styleUrls: ['./device-hub.component.css']
})
export class DeviceHubComponent implements OnInit {

  errMessage = "";
  successMessage = "";
  deviceID:string = this.route.snapshot.params.deviceID;
  businessID: string = this.route.snapshot.params.businessID;
  myUUID;
  feedback;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    withCredentials: false,
  };

  constructor(
    private router: Router,
    private http: HttpClient,
    private route:ActivatedRoute,
    private authService: AuthService) {
      this.authService.validateBusiness(this.businessID)

     }

  ngOnInit(): void {
    this.validate()
  }

  validate() {
    var req = {
      businessID: this.businessID,
      deviceID: this.deviceID,
    }

    this.http.post(environment.DEVICE_HUB_VALIDATE,req, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {
          console.log(res.message);
        

          this.router.navigate(['/feedback/'+this.businessID+'/'+res.message[0].token+'/pos'])

          

        }
        else {
          console.log(res.message);
          var anElm = document.getElementById('notadded');
          anElm.style.display="block";
          console.log('ei ole kyseistä käyttäjää')
        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })


  }

}
