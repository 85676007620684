

    <div class="" *ngIf="feedback != null && spot != 'pos'">
        <div class="col-12  full-area" id="full-area">
<h1 class="text-center pt-4" id="main-text"><span>{{currentQustion}}</span></h1>

<div class="col-12 row m-0 pt-4 smile-icon-row" id="icons" *ngIf="feedback.type == 'smile'">
    <div class="col-xs-15 icon-container pointer love-icon" (click)="submitAnswer(100)">
        <img class="not-pos-img" src="../../assets/images/super_happy.png" alt="" srcset="">
    </div>
    <div class="col-xs-15 icon-container pointer smile-icon text-center" (click)="submitAnswer(75)">
        <img class="not-pos-img" src="../../assets/images/quite_happy.png"  alt="" srcset="">
    </div>
    <div class="col-xs-15 icon-container pointer meh-icon text-center"(click)="submitAnswer(50)">
        <img class="not-pos-img" src="../../assets/images/smile_meh.png"  alt="" srcset="">
    </div>
    <div class="col-xs-15 icon-container pointer meh-icon text-center"(click)="submitAnswer(25)">
        <img class="not-pos-img" src="../../assets/images/smile_sad.png"  alt="" srcset="">
    </div>
    <div class="col-xs-15 icon-container pointer angry-icon text-end"(click)="submitAnswer(0)">
        <img class="not-pos-img" src="../../assets/images/smile_extrasad.png"  alt="" srcset="">
    </div>
</div>

<div class="col-12 row w-100 m-0" id="icons" *ngIf="feedback.type == 'thumb'">
    <div class="col-6 icon-container pointer love-icon" (click)="submitAnswer(100)">
        <img class="not-pos-img" src="../../assets/images/thumb_up.png" alt="" srcset="">
    </div>
    <div class="col-6 icon-container pointer smile-icon text-center" (click)="submitAnswer(0)">
        <img class="not-pos-img" src="../../assets/images/thumb_down.png"  alt="" srcset="">
    </div>
</div>

<div class="col-12 row w-100 m-0 d-flex d-iniline-flex nps-body ps-0 pe-0" id="nps-container" *ngIf="feedback.type == 'nps'">
        <div class="nps-container">
            <button class="detractor" style="background-color: #ff9eae" (click)="submitAnswer(0)">0</button>
            <button class="detractor" style="background-color: #ffafbc" (click)="submitAnswer(10)">1</button>
            <button class="detractor" style="background-color: #ffafbc" (click)="submitAnswer(20)">2</button>
            <button class="detractor" style="background-color: #ffc0cb" (click)="submitAnswer(30)">3</button>
            <button class="detractor" style="background-color: #ffd1d9" (click)="submitAnswer(40)">4</button>
            <button class="detractor" style="background-color: #ffe2e7" (click)="submitAnswer(50)">5</button>
            <button class="detractor" (click)="submitAnswer(60)">6</button>
            <button class="passive" (click)="submitAnswer(70)">7</button>
            <button class="passive" (click)="submitAnswer(80)">8</button>
            <button class="promoter" style="background-color: #adecba" (click)="submitAnswer(90)">9</button>
            <button class="promoter" style="background-color: #5ad974" (click)="submitAnswer(100)">10</button>
        </div>
</div>



<div id="text-area" class="my-area ps-3 pe-3" style="display: none;">
    <textarea maxlength="399" id="text-feedback" class="form-control my-area" height="100%" placeholder="Haluaisitko antaa meille lisää palautetta?"></textarea >
    <button class="btn btn-feedbacker mt-4" (click)="submitTextAnswer()">Lähetä</button>
</div>

<div id="pretemplates" class="my-area ps-3 pe-3" style="display: none;">
    <div class="" *ngIf="answeredValue < 50">
        <div class="col-12 p-0 p-md-4">
            <div class="cutsom-checkbox-row" *ngFor="let data of feedback.pretemplates.low.answers; let index = j">
                <input id="checkbox{{j}}" class="checkbox-pretemplate" type="checkbox">
                <label for="checkbox1{{j}}" class="pre-text-label" (click)="submitPretemplateAnswer(data)">{{data}}</label>
            </div>
        </div>
    </div>
    <div class="" *ngIf="answeredValue >= 50 && answeredValue < 80">
        <div class="col-12 p-0 p-md-4">
            <div class="cutsom-checkbox-row" *ngFor="let data of feedback.pretemplates.mid.answers; let index = z">
                <input id="checkbox1{{z}}" class="checkbox-pretemplate" type="checkbox">
                <label for="checkbox1{{z}}" class="pre-text-label" (click)="submitPretemplateAnswer(data)">{{data}}</label>
            </div>
        </div>
    </div>
    <div class="" *ngIf="answeredValue >= 80">
        <div class="col-12 p-0 p-md-4">
            <div class="cutsom-checkbox-row" *ngFor="let data of feedback.pretemplates.high.answers;let index = n">
                <input id="checkbox1{{n}}" class="checkbox-pretemplate" type="checkbox">
                <label for="checkbox1{{n}}" class="pre-text-label" (click)="submitPretemplateAnswer(data)">{{data}}</label>
            </div>
        </div>
    </div>
</div>

<div id="thanks" class="ps-3 pe-3" style="display: none;">
    <div class="alert alert-success">
        <p class="text-center">{{feedback.thankYouText}}</p>
    </div>
</div>
<p id="secure-text" class="ps-3">{{feedback.securityText}}</p>
<div class="my-box">
    <img class="nset-img-fb" src="../../assets/images/moodysetlogo_b.png" width="120px">
 </div>
</div>

</div>
<!--POINT OF SALEq-->
<div class="" *ngIf="feedback && spot == 'pos'">
    <div class="col-12 p-4 h-100 full-area" id="full-area-pos">
    <h1 class="text-center mt-4 pt-4 pos-header" id="main-text">{{currentQustion}}</h1>

<div class="col-12 row w-100 me-0 col-pos-row" id="icons" *ngIf="feedback.type == 'smile'">
    <div class="col-xs-15 icon-container pointer love-icon" (click)="submitAnswer(100)">
        <img class="pos-img" src="../../assets/images/super_happy.png" alt="" srcset="">
    </div>
    <div class="col-xs-15 icon-container pointer smile-icon text-center" (click)="submitAnswer(75)">
        <img class="pos-img" src="../../assets/images/quite_happy.png"  alt="" srcset="">
    </div>
    <div class="col-xs-15 icon-container pointer meh-icon text-center"(click)="submitAnswer(50)">
        <img class="pos-img" src="../../assets/images/smile_meh.png"  alt="" srcset="">
    </div>
    <div class="col-xs-15 icon-container pointer meh-icon text-center"(click)="submitAnswer(25)">
        <img class="pos-img" src="../../assets/images/smile_sad.png"  alt="" srcset="">
    </div>
    <div class="col-xs-15 icon-container pointer angry-icon text-end"(click)="submitAnswer(0)">
        <img class="pos-img" src="../../assets/images/smile_extrasad.png"  alt="" srcset="">
    </div>
</div>

<div class="col-12 row w-100 m-0 d-flex d-iniline-flex nps-body ps-0 pe-0" id="nps-container-pos" *ngIf="feedback.type == 'nps'">
    <div class="nps-container-pos">
        <button class="detractor" style="background-color: #ff9eae" (click)="submitAnswer(0)">0</button>
        <button class="detractor" style="background-color: #ffafbc" (click)="submitAnswer(10)">1</button>
        <button class="detractor" style="background-color: #ffafbc" (click)="submitAnswer(20)">2</button>
        <button class="detractor" style="background-color: #ffc0cb" (click)="submitAnswer(30)">3</button>
        <button class="detractor" style="background-color: #ffd1d9" (click)="submitAnswer(40)">4</button>
        <button class="detractor" style="background-color: #ffe2e7" (click)="submitAnswer(50)">5</button>
        <button class="detractor" (click)="submitAnswer(60)">6</button>
        <button class="passive" (click)="submitAnswer(70)">7</button>
        <button class="passive" (click)="submitAnswer(80)">8</button>
        <button class="promoter" style="background-color: #adecba" (click)="submitAnswer(90)">9</button>
        <button class="promoter" style="background-color: #5ad974" (click)="submitAnswer(100)">10</button>
    </div>

</div>


<div id="text-area-pos" class="my-area" style="display: none;">
    <textarea maxlength="399" id="text-feedback-pos"  (keydown)="userTyping($event)"class="form-control my-area mt-4" height="100%" placeholder="Haluaisitko antaa meille lisää palautetta?"></textarea >
    <button class="btn btn-feedbacker mt-4 w-100 pos-btn" (click)="submitTextAnswer()">Lähetä</button>
</div>

<div id="pretemplates-pos" class="my-area ps-3 pe-3" style="display: none;">
    <div class="" *ngIf="answeredValue < 40">
        <div class="col-12 p-0 p-md-4">
            <div class="cutsom-checkbox-row-pos" *ngFor="let data of feedback.pretemplates.low.answers; let i = index">
                <input id="checkbox{{i}}" class="checkbox-pretemplate" type="checkbox">
                <label for="checkbox{{i}}" class="pre-text-label-pos" (click)="submitPretemplateAnswer(data)">{{data}}</label>
            </div>
        </div>
    </div>
    <div class="" *ngIf="answeredValue >= 40 && answeredValue < 80">
        <div class="col-12 p-0 p-md-4">
            <div class="cutsom-checkbox-row-pos" *ngFor="let data of feedback.pretemplates.mid.answers; let l = index">
                <input id="checkbox{{l}}" class="checkbox-pretemplate" type="checkbox">
                <label for="checkbox{{l}}" class="pre-text-label-pos" (click)="submitPretemplateAnswer(data)">{{data}}</label>
            </div>
        </div>
    </div>
    <div class="" *ngIf="answeredValue >= 80">
        <div class="col-12 p-0 p-md-4">
            <div class="cutsom-checkbox-row-pos" *ngFor="let data of feedback.pretemplates.high.answers; let w = index">
                <input id="checkbox1{{w}}" class="checkbox-pretemplate" type="checkbox">
                <label for="checkbox1{{w}}" class="pre-text-label-pos" (click)="submitPretemplateAnswer(data)">{{data}}</label>
            </div>
        </div>
    </div>
</div>

<div id="thanks" style="display: none;">
    <div class="alert alert-success mt-4" >
        <p class="text-center pos-size">{{feedback.thankYouText}}</p>
    </div>
</div>
<p id="secure-text">{{feedback.securityText}}</p>

<div class="my-box">
    <img class="nset-img-fb" src="../../assets/images/moodysetlogo_b.png" width="120px">
 </div>

</div>
</div>
