<app-user-header></app-user-header>
<div class="" >
    <div class="bg-container header pnset-6 container-fluid pt-4 ps-4 pe-4">
    <div class="col-12 row pb-4">
        <div class="col-12 col-md-7 page-title">
            <p class="page-title h2 text-white d-inline-block mb-0">{{spinner['name']}}</p><br>
            <button class="btn btn-feedbacker me-4 mt-4" routerLink="/{{businessID}}/edit-spinner/{{spinnerID}}"><i class="fas fa-edit me-2"></i>Muokkaa</button>
            <button class="btn btn-feedbacker-delete me-4 mt-4" data-bs-toggle="modal"  data-bs-target="#delete-spinner"><i class="fas fa-trash-alt me-2"></i>Poista</button>
            
        </div>
        <div class="col">
            <nav class="d-none d-md-inline-block ml-md-4">
                <ol class="breadcrumb breadcrumb-links breadcrumb-dark">
                    <li class="breadcrumb-item">
                        <a routerLink="/{{businessID}}/users/home">Koti</a>
                    </li>
                    <li class="breadcrumb-item">
                        <a routerLink="/{{businessID}}/spinners">Esittelyt</a>
                    </li>
                    <li class="breadcrumb-item active">
                        <a href="#">Esittely</a>
                    </li>
                </ol>
            </nav>  
        </div>
    </div>
    <div class="col-12 row m-0">
        <div class="col-xl-3 col-md-6">
            <div class="card card-stats p-4">
                <div class="row">
                    <div class="col">
                        <h5 class="card-title text-uppercase text-muted mb-0">Palautteita</h5>
                        <span class="h2 font-weight-bold mb-0">{{spinner.answers.length}}</span>
                    </div>
                    <div class="col-auto">
                        <div class="nset-icon icon-shape bg-gradient-red shadow">

                        </div>
                    </div>
                    <p class="mt-3 mb-0 text-sm">
                        <!-- <span class="text-success me-2">10%</span> -->
                        <span class="text-nowrap">Yhteensä</span>
                    </p>
                </div>
            </div>
        </div>
<!--         <div class="col-xl-3 col-md-6">
            <div class="card card-stats p-4">
                <div class="row">
                    <div class="col">
                        <h5 class="card-title text-uppercase text-muted mb-0">Vastausaste</h5>
                        <span class="h2 font-weight-bold mb-0" *ngIf="feedbackValues && feedbackValues.company && feedbackValues.company.answer_rate">{{feedbackValues.company.answer_rate.toFixed(2)*100}}</span>
                    </div>
                    <div class="col-auto">
                        <div class="nset-icon icon-shape bg-gradient-orange shadow">

                        </div>
                    </div>
                    <p class="mt-3 mb-0 text-sm">
                        <span class="text-nowrap">Yhteensä</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6">
            <div class="card card-stats p-4">
                <div class="row">
                    <div class="col">
                        <h5 class="card-title text-uppercase text-muted mb-0">NPS</h5>
                        <span class="h2 font-weight-bold mb-0" *ngIf="feedbackValues && feedbackValues.company && feedbackValues.company.nps">{{feedbackValues.company.nps.toFixed(2) * 100}}</span>
                    </div>
                    <div class="col-auto">
                        <div class="nset-icon icon-shape bg-gradient-green shadow">

                        </div>
                    </div>
                    <p class="mt-3 mb-0 text-sm">
                        <span class="text-nowrap">Yhteensä</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6">
            <div class="card card-stats p-4">
                <div class="row">
                    <div class="col">
                        <h5 class="card-title text-uppercase text-muted mb-0">KESKIARVO</h5>
                        <span class="h2 font-weight-bold mb-0" *ngIf="feedbackValues && feedbackValues.company && feedbackValues.company.average">{{feedbackValues.company.average.toFixed(2)}}</span>
                    </div>
                    <div class="col-auto">
                        <div class="nset-icon icon-shape bg-gradient-info shadow">

                        </div>
                    </div>
                    <p class="mt-3 mb-0 text-sm">
                        <span class="text-nowrap">Yhteensä</span>
                    </p>
                </div>
            </div>
        </div> -->
    </div>

    </div>

    <div class="col-12 ps-4 row nset-card">
        <div class="col-xl-8">
            <div class="card card-bg-question">
                <div class="card-header">
                    <div class="row align-items-center">
                        <div class="col">
                            <h6 class="text-light text-uppercase ls-1 mb-1">Palautteet</h6>
                            <h5 class="h3 text-white mb-0">Esittelyssä</h5>
                        </div>
                        <div class="col">
                            <ul class="nav nav-pills justify-content-end">
                                <li class="nav-item mr-2 mr-md-0">
                                    <button routerLink="/{{businessID}}/feedbacks" class="btn-feedbacker">Kyselyt</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="table-responsive">
                    <div class="table-responsive" *ngIf="spinner['answers'].length > 0;else no_answers_content">
                        <table class="table align-items-center table-flush">
                            <thead class="thead-dark">
                                <tr>
                                    <th>Päivämäärä</th>
                                    <th>Palaute</th>
                                    <th>Tiedot</th>
                                    <th>Poista</th>
                                </tr>
                            </thead>
                            <tbody class="tbody-dark">
                                <tr *ngFor="let data of spinner['answers']">
                                    <td class="pointer" >{{data.createdDate | formateDate}}</td>
                                    <td class="pointer" >{{data.text}}</td>
                                    <td><button routerLink="/{{businessID}}/feedback/{{data.feedbackID}}" class="btn-feedbacker">Tiedot</button></td>
                                    <td><button (click)="selectDeletable(data)" data-bs-toggle="modal" id="checkDeleteBtn" data-bs-target="#delete-feedback"  class="btn-feedbacker-delete"><i class="fas fa-trash-alt me-2"></i>Poista</button></td>
                                </tr>
                            </tbody>
          
                        </table>
                    </div>
                    <ng-template #no_answers_content>
                        <div class="col-12 row m-0">
                            <div class="col-3"></div>
                            <div class="col-12 col-md-6">
                                <div class="alert alert-warning">
                                    <p class="normal-text text-center">Sinulla ei ole vielä palaute-esittelyitä.</p>
                                </div>
                            </div>
                            <div class="col-3"></div>
                        </div>
                    </ng-template>
                        </div>
                
            </div>

        </div>
        <div class="col-xl-4">
            <div class="card ">
                <div class="card-header">
                    <div class="row align-items-center">
                        <div class="col">
                            <h6 class="text-dark text-uppercase ls-1 mb-1">Tehokkuus</h6>
                            <h5 class="h3 text-black mb-0"> NPS</h5>
                        </div>
                        <div class="col">
                            <ul class="nav nav-pills justify-content-end">
                                <li class="nav-item mr-2 mr-md-0">
                                    <button routerLink="/{{businessID}}/feedbacks" class="btn-feedbacker-dark">Kaikki</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table align-items-center table-flush">
                            <thead class="thead-light">
                                <tr>
                                    <th>Tieto</th>
                                    <th>Vastaus</th>
                                </tr>
                            </thead>
                            <tbody class="tbody-light">

                                <tr>
                                    <th width="25%">Tekstinväri</th>
                                    <td width="75%">{{spinner['textColor']}}</td>
                                </tr>
                                <tr>
                                    <th width="25%">Taustaväri</th>
                                    <td width="75%">{{spinner['backgroundColor']}}</td>
                                </tr>
                                <tr>
                                    <th width="25%">Linkki</th>
                                    <td width="75%"><a target="_blank" href="{{pathUrl}}">Näytä</a></td>
                                </tr>
                                <tr >
                                    <th width="25%">Upotuskoodi</th>
                                    <td width="75%"><button class="btn btn-feedbacker" data-bs-toggle="modal" data-bs-target="#show-code" (click)="generateCode()"><i class="fas fa-code me-2"></i>Näytä upotuskoodi</button></td>
                                </tr>
                            </tbody>
    
                        </table>
                    </div>
                </div>
                
            </div>
        </div>
    </div>


               <!-- Modal Check before deleting offer -->
               <button data-bs-toggle="modal" id="checkDeleteBtn" data-bs-target="#delete-feedback" style="display:none"></button>
               <div class="modal fade" id="delete-feedback" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                 <div class="modal-dialog" role="document">
                   <div class="modal-content">
                     <div class="modal-header">
                       <h5 class="modal-title" id="exampleModalLabel">Haluatko poistaa tämän palautteen esittelystä?</h5>
                       <!-- <p>Kaikki tähdellä merkityt kentät ovat pakollisia</p> -->
                       <button type="button" class="close" id="closeDeleteModal" data-bs-dismiss="modal" aria-label="Close">
                         <span aria-hidden="true">&times;</span>
                       </button>
                     </div>
                     <div class="modal-body">
                       <h5 class="text-center">Poistetaanko palautekysely pysyvästi?</h5>
                       <div class="col-12 row m-0 pt-4 pb-4">
                        <div class="col-12 text-center">
                            <button class="btn btn-feedbacker-delete" (click)="removeFeedback()"><i class="far fa-trash-alt me-2"></i>Poista</button>
                          </div>
                         <div class="col-12 text-center">
                           <a class="pointer"  id="closeDeleteModal" data-bs-dismiss="modal">Peruuta</a>
                         </div>
                       </div>
                     </div>
                   </div>
                 </div>
               </div>

            <!-- Modal Check before deleting offer -->
            <button data-bs-toggle="modal" id="spinner-delete" data-bs-target="#delete-spinner" style="display:none"></button>
            <div class="modal fade" id="delete-spinner" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Haluatko poistaa tämän esittelyn?</h5>
                    <!-- <p>Kaikki tähdellä merkityt kentät ovat pakollisia</p> -->
                    <button type="button" class="close" id="closeDeleteModal" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <h5 class="text-center">Poistetaanko esittelyn pysyvästi? Tätä ei enää näytetä verkkosivuilla.</h5>
                    <div class="col-12 row m-0 pt-4 pb-4">
                    <div class="col-12 text-center">
                        <button class="btn btn-feedbacker-delete" (click)="removeSpinner()"><i class="far fa-trash-alt me-2"></i>Poista</button>
                        </div>
                    <div class="col-12 text-center">
                        <a class="pointer"  id="closeDeleteModalSpinner" data-bs-dismiss="modal">Peruuta</a>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>

                                          <!-- Modal Check before deleting offer -->
                                          <button data-bs-toggle="modal" id="show-btn" data-bs-target="#show-code" style="display:none"></button>
                                          <div class="modal fade" id="show-code" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div class="modal-dialog" role="document">
                                              <div class="modal-content">
                                                <div class="modal-header">
                                                  <h5 class="modal-title" id="exampleModalLabel">Upostuskoodi</h5>
                                                  <!-- <p>Kaikki tähdellä merkityt kentät ovat pakollisia</p> -->
                                                  <button type="button" class="close" id="closeDeleteModal" data-bs-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                  </button>
                                                </div>
                                                <div class="modal-body">
                                                  <h5 class="text-center">Kopioi upotuskoodisi</h5>
                                                  <div class="col-12 row pt-4 pb-4">
                                                    <textarea name="" disabled [value]="txtValue" style="height: 200px;" class="form-control" id="code-area"></textarea>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>