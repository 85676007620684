import { AfterContentInit, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';


@Component({
  selector: 'app-edit-company',
  templateUrl: './edit-company.component.html',
  styleUrls: ['./edit-company.component.css']
})
export class EditCompanyComponent implements OnInit {

  errMessage = "";
  successMessage = "";
  feedbackID:string = this.route.snapshot.params.id;
  businessID: string = this.route.snapshot.params.businessID;
  authToken = localStorage.getItem('auth');
  company = [];
  users = [];
  form: FormGroup;
  messageErr;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'auth': `${this.authToken}`  }),
    withCredentials: true,
  };

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private http: HttpClient,
    private route:ActivatedRoute,
    private authService: AuthService) {
      this.authService.validateBusiness(this.businessID)

     }

     ngOnInit(): void {
      this.getBusinessDetails();

      this.form = this.fb.group({
        businessName: ['', [
          Validators.required,
        ]],
        email: ['', [
          Validators.required,
        ]],
        phone: ['', [
          Validators.required,
        ]],
        businessEmail: ['', [
          Validators.required,
        ]],
        businessPhone: ['', [
          Validators.required,
        ]],
        backgroundColor: [''],
        leftText: [''],
        leftBackground: [''],
        rightText: [''],
        rightBackground: [''],
        buttonText: [''],
        buttonBackground: [''],
  
        ovt:'',
        operator: '',

      }); 
    }

    get email() { return this.form.get('email') };
    get businessName() { return this.form.get('businessName') };
    get phone() { return this.form.get('phone') };
    get ovt() { return this.form.get('ovt') };
    get operator() { return this.form.get('operator') };
    get businessEmail() { return this.form.get('businessEmail') };
    get businessPhone() { return this.form.get('businessPhone') };
    get leftBackground() { return this.form.get('leftBackground') };
    get leftText() { return this.form.get('leftText') };
    get rightBackground() { return this.form.get('rightBackground') };
    get rightText() { return this.form.get('rightText') };
    get buttonBackground() { return this.form.get('buttonBackground') };
    get buttonText() { return this.form.get('buttonText') };
    get secondaryColor() { return this.form.get('secondaryColor') };
    get backgroundColor() { return this.form.get('backgroundColor') };

    getBusinessDetails() {

      this.http.get(environment.USER_BUSINESS, this.httpOptions).subscribe(
        (res: any) => {
          if(res.status == 200 ) {
            this.company = res.message;
  
            console.log(this.company);
            var cm = this.company[0];

            this.form = this.fb.group({
               businessName: [cm.businessName, Validators.required],
              email: [cm.email, Validators.required],
              phone: [cm.phone, Validators.required],
              ovt: [cm.ovt, Validators.required],
              operator: [cm.operator, Validators.required],
              businessEmail: [cm.businessEmail, Validators.required],
              businessPhone: [cm.businessPhone, Validators.required],
              leftText: [cm.leftText],
              rightText: [cm.rightText],
              leftBackground: [cm.leftBackground],
              rightBackground: [cm.rightBackground],
              buttonText: [cm.buttonText],
              buttonBackground: [cm.buttonBackground],
              backgroundColor: [cm.backgroundColor],
            });
  
          }
          else {
            var anElm = document.getElementById('notadded');
            anElm.style.display="block";
            console.log('ei ole kyseistä käyttäjää')
          }
          
        }, (err: any) => {
    
          console.log("Error while finding status :: ", err);
        })
  
  
    }
    onSubmit(){

      
          console.log('Submitting');
          if (!this.form.valid) {
            return;
          }

          var leftText = this.form.get('leftText').value;
          var leftBackground = this.form.get('leftBackground').value;
          var rightText = this.form.get('rightText').value;
          var rightBackground = this.form.get('rightBackground').value;
          var buttonText = this.form.get('buttonText').value;
          var buttonBackground = this.form.get('buttonBackground').value;
          var backgroundColor= this.form.get('backgroundColor').value;
      
          if(!leftText){
            this.form.get('leftText').setValue('#000000');
          }
          if(!rightText){
            this.form.get('rightText').setValue('#ffffff');
          }
          if(!leftBackground){
            this.form.get('leftBackground').setValue('#EDEFF0');
          }
          if(!rightBackground){
            this.form.get('rightBackground').setValue('#020236');
          }
          if(!buttonText){
            this.form.get('buttonText').setValue('#ffffff');
          }
          if(!buttonBackground){
            this.form.get('buttonBackground').setValue('#020236');
          }
          if(!backgroundColor){
            this.form.get('backgroundColor').setValue('#ffffff');
          }
      
      
          var formAc = document.getElementById('form-container');
          formAc.classList.add("d-none");
          document.getElementById('loader').style.display="block";

      
          const request = {
            businessName: this.form.get('businessName').value,
            ovt: this.form.get('ovt').value,
            operator: this.form.get('operator').value,
            email: this.form.get('email').value,
            phone: this.form.get('phone').value,
            businessPhone: this.form.get('businessPhone').value,
            businessEmail: this.form.get('businessEmail').value,
            leftBackground: this.form.get('leftBackground').value,
            leftText: this.form.get('leftText').value,
            rightBackground: this.form.get('rightBackground').value,
            rightText: this.form.get('rightText').value,
            buttonText: this.form.get('buttonText').value,
            buttonBackground: this.form.get('buttonBackground').value,
            backgroundColor: this.form.get('backgroundColor').value,
          }
      
             this.http.put(environment.USER_BUSINESS, request, this.httpOptions).subscribe(
              (res: any) => {
                console.log(res);
                if (res.status == 200) {
                  alert('Tietojen muokkaaminen onnistui. Paina OK siirtyäksesi yrityksen tietoihin');
                  this.router.navigate(['/'+this.businessID+'/company-details/'])
                } 
                
                else {
                  this.messageErr = res.messgage;
                  document.getElementById('loader').style.display="none";
                  formAc.classList.remove("d-none");
      
                  //loginError = true;
                }
              }, (err: any) => {
      
                console.log("Error while login :: ", err);
              }
            )
      
          } 

}
