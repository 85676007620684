import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { base_host, environment, my_port, my_protocol } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

@Component({
  selector: 'app-compare-feedbacks',
  templateUrl: './compare-feedbacks.component.html',
  styleUrls: ['./compare-feedbacks.component.css']
})
export class CompareFeedbacksComponent implements OnInit {

  errMessage = "";
  successMessage = "";
  feedbacks:string = this.route.snapshot.params.feedbacks;
  businessID: string = this.route.snapshot.params.businessID;
  feedback;
  authToken = localStorage.getItem('auth');
  pathUrl;
  data;
  ready = false;
  feedbackValues;
  smileDiversion;
  chart;
  SMIchart;
  userDiversion;
  colorBank = [
    'blue',
    'orange',
    'pink',
    'red',
  ]

  criticsColorArr = [
    '#D2042D',
    '#811331',
    '#DE3163',
    '#DC143C',
  ]

  passiveColorArr = [
    '#FFBF00',
    '#E4D00A',
    '#FFD700',
    '#FCF55F',
  ]
  prefsColorArr = [
    '#228B22',
    '#7CFC00',
    '#008000',
    '#4CBB17',
  ]

  constructor(
    private router: Router,
    private http: HttpClient,
    private route:ActivatedRoute,
    private authService: AuthService) {
      this.authService.validateBusiness(this.businessID)

     }


  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'auth': `${this.authToken}`  }),
    withCredentials: true,
  };

  ngOnInit(): void {
    this.getFeedbackData();
  }

  getFeedbackData() {

    this.http.get(environment.FEEDBACK_COMPARE+'/'+this.feedbacks, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {
          this.data = res.message;

          console.log(this.feedback);

          this.generateData()
          

        }
        else {
          var anElm = document.getElementById('notadded');
          anElm.style.display="block";
          console.log('ei ole kyseistä käyttäjää')
        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })


  }

  generateData(){
    var dataset = [];
    var ds = [];

    console.log(this.data.feedbacks.length)
    for (let i = 0; i < this.data.feedbacks.length; i++) {
      var elm = this.data.feedbacks[i];


        var average = {
          label: elm.identifier + ' Keskiarvo',
          spanGaps:true,
          data: elm.average,
          backgroundColor:this.colorBank[i],
          pointBackgroundColor:'white',
          pointBorderColor :this.colorBank[i],
          borderColor: this.colorBank[i],
          tension: 0.5,
          borderWidth:2,
          hidden:true,
      
      }

      var nps = {
          label: elm.identifier+ ' NPS',
          spanGaps:true,
          data: elm.nps,
          backgroundColor:this.colorBank[i],
          pointBackgroundColor:'white',
          pointBorderColor :this.colorBank[i],
          borderColor: this.colorBank[i],
          tension: 0.5,
          borderWidth:2,
      }

      var csat = {
        label: elm.identifier+ ' CSAT',
        spanGaps:true,
        data: elm.csat,
        backgroundColor:this.colorBank[i],
        pointBackgroundColor:'white',
        pointBorderColor :this.colorBank[i],
        borderColor: this.colorBank[i],
        tension: 0.5,
        borderWidth:2,
    }

    var ces = {
      label: elm.identifier+ ' CES',
      spanGaps:true,
      data: elm.ces,
      backgroundColor:this.colorBank[i],
      pointBackgroundColor:'white',
      pointBorderColor :this.colorBank[i],
      borderColor: this.colorBank[i],
      tension: 0.5,
      borderWidth:2,
  }


      var visitors = {
        label: elm.identifier+ ' Kävijät',
        spanGaps:true,
        data: elm.visitors,
        backgroundColor:this.colorBank[i],
        pointBackgroundColor:'white',
        pointBorderColor :this.colorBank[i],
        borderColor: this.colorBank[i],
        tension: 0.5,
        borderWidth:2,
        hidden:true,
    }

    var critics = {
      label: elm.identifier+' Kriitikot',
      data: elm.critics,
      backgroundColor:this.criticsColorArr[i],
      stack: 'Stack '+i,
    }
    var passives = {
      label: elm.identifier +' Passiiviset',
      data: elm.passives,
      backgroundColor:this.passiveColorArr[i],
      stack: 'Stack '+i,
    }
    var prefs = {
      label: elm.identifier+' Suosittelijat',
      data: elm.preferrers,
      backgroundColor:this.prefsColorArr[i],
      stack: 'Stack '+i,
    }
    var s1 = {
      label: elm.identifier+' Innostuneet',
      data: elm.s1,
      backgroundColor:this.prefsColorArr[i],
      stack: 'Stack '+i,
    }
    var s2 = {
      label: elm.identifier+' Iloiset',
      data: elm.s2,
      backgroundColor:this.passiveColorArr[i],
      stack: 'Stack '+i,
    }
    var s3 = {
      label: elm.identifier+' Normaalit',
      data: elm.s3,
      backgroundColor:this.passiveColorArr[i],
      stack: 'Stack '+i,
    }
    var s4 = {
      label: elm.identifier+' Surulliset',
      data: elm.s4,
      backgroundColor:this.criticsColorArr[i],
      stack: 'Stack '+i,
    }
    var s5 = {
      label: elm.identifier+' Vihaiset',
      data: elm.s5,
      backgroundColor:this.criticsColorArr[i],
      stack: 'Stack '+i,
    }

    dataset.push(average);
    dataset.push(visitors);

    if(this.data.type =="nps"){
      dataset.push(nps);
      ds.push(critics);
      ds.push(passives);
      ds.push(prefs);
    }
    else if(this.data.type =="smile"){
      dataset.push(csat);
      dataset.push(ces);
      ds.push(s1);
      ds.push(s2);
      ds.push(s3);
      ds.push(s4);
      ds.push(s5);
    }
      




        
      
      
    }

    console.log(dataset);

    this.generateAverageChart(dataset)
    this.generateSMI(ds);
  }

  generateAverageChart(dataset){
    var g = <HTMLCanvasElement>document.getElementById('my-chart');
    var width = document.documentElement.clientWidth;
    if(width > 1024){
      g.height = 150;
    }
    else{
      g.height = 300;
    }
    
    Chart.defaults.font.family = "Montserrat";
    Chart.defaults.font.size = 16;
    Chart.defaults.color ="black";
    const nullDupes = data => data.map((x, i) => data[i - 1] === x ? null : x);

    
    var options = {
      scales: {
        x: {
          grid:{
            display:false
          }
        }
    },

    }

    this.chart = new Chart(g, {
      type: 'line',
      data: {
          labels:this.data.dates ,
          datasets: dataset,
          
          
      },
      options: options,
  });
  }

  generateSMI(dataset){
    var g = <HTMLCanvasElement>document.getElementById('my-smi-chart');
    var width = document.documentElement.clientWidth;
    if(width > 1024){
      g.height = 150;
    }
    else{
      g.height = 300;
    }
    
    Chart.defaults.font.family = "Montserrat";
    Chart.defaults.font.size = 16;
    Chart.defaults.color ="black";
    const nullDupes = data => data.map((x, i) => data[i - 1] === x ? null : x);

    
    var options = {
      responsive: true,
      interaction: {
        intersect: false,
      },
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true
        }
      }
  }
    

    this.SMIchart = new Chart(g, {
      type: 'bar',
      data: {
          labels:this.data.dates ,
          datasets:dataset,
          
          
      },
      options: options,
  });
  }

}
