import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { base_host, environment, my_port, my_protocol } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);
import * as XLSX from 'xlsx';
import 'xlsx';

@Component({
  selector: 'app-feedback-details',
  templateUrl: './feedback-details.component.html',
  styleUrls: ['./feedback-details.component.css']
})
export class FeedbackDetailsComponent implements OnInit {

  errMessage = "";
  successMessage = "";
  feedbackID:string = this.route.snapshot.params.id;
  businessID: string = this.route.snapshot.params.businessID;
  feedback;
  authToken = localStorage.getItem('auth');
  pathUrl;
  data;
  ready = false;
  feedbackValues;
  smileDiversion;
  chart;
  SMIchart;
  userDiversion;
  order;
  isNPSCert = false;
  txtValue;
  company;
  spinners = [];
  selected = [];
  npsGoal;
  csatGoal;
  cesGoal;
  npsSector;
  csatSector;
  cesSector;
  textChart;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'auth': `${this.authToken}`  }),
    withCredentials: true,
  };

  constructor(
    private router: Router,
    private http: HttpClient,
    private route:ActivatedRoute,
    private authService: AuthService) {
      this.authService.validateBusiness(this.businessID)

     }

  ngOnInit(): void {
  
    this.getFeedback();
    this.fetchOrderDetails();
    this.getBusinessDetails()
  }

  fetchOrderDetails() {

    this.http.get(environment.ORDER_DATA, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {
          this.order = [];
          this.isNPSCert = false;
          this.order = res.message;


          for (let i = 0; i < this.order.certificates.selectedCertificates.length; i++) {
            const cert = this.order.certificates.selectedCertificates[i];

            if(cert == this.feedbackID){
              this.isNPSCert = true;
            }
          }

        }
        else {
          var anElm = document.getElementById('notadded');
          anElm.style.display="block";
          console.log('ei ole kyseistä käyttäjää')
        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })


  }

  getBusinessDetails() {

    this.http.get(environment.USER_BUSINESS, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {
          this.company = res.message;
          this.npsGoal = res.message[0].goals.nps.company;
          this.npsSector = res.message[0].goals.nps.sector;
          this.csatGoal = res.message[0].goals.csat.company;
          this.csatSector = res.message[0].goals.csat.sector;
          this.cesGoal = res.message[0].goals.ces.company;
          this.cesSector = res.message[0].goals.ces.sector;

          console.log(this.company);

        }
        else {
          var anElm = document.getElementById('notadded');
          anElm.style.display="block";
          console.log('ei ole kyseistä käyttäjää')
        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })


  }

  getFeedback() {

    this.http.get(environment.FEEDBACK+'/'+this.feedbackID, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {
          this.feedback = res.message[0];
          this.pathUrl = my_protocol.protocol+'://' + base_host.base_host + my_port.port+ '/#/feedback/' + this.businessID + '/' + this.feedback.token+'/web';

          console.log(this.feedback);
          this.getFeedbackValues()
          this.getSpinners()

        }
        else {
          var anElm = document.getElementById('notadded');
          anElm.style.display="block";
          console.log('ei ole kyseistä käyttäjää')
        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })


  }

  getFeedbackValues() {

    this.http.get(environment.FEEDBACK_VALUES+'/'+this.feedbackID, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {
          this.feedbackValues = res.message;
          console.log(this.feedbackValues, '***')
          this.ready = true;
          

          this.waitStyle()



        }
        else {
          
          var anElm = document.getElementById('notadded');
          anElm.style.display="block";
          console.log('ei ole kyseistä käyttäjää')
        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })


  }

  getSpinners() {

    this.http.get(environment.SPINNERS, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {
          this.spinners = res.message;
          var spin = res.message

          //Loopthrough answers
          for (let i = 0; i < this.feedback.answers.length; i++) {
            const fans = this.feedback.answers[i];

            //Loop through spinners
            for (let j = 0; j < spin.length; j++) {
              const sans = spin[j];

              //Loop through spinner answers
              for (let k = 0; k < sans.answers.length; k++) {
                const ra = sans.answers[k];


                if(ra.feedbackID == this.feedbackID){
                  if(ra.answerID == fans.answerID){

                    var obj =  {spinnerID: sans._id, name: sans.name };

                    var arr = [];
                    arr.push(obj)


                    if(!fans.spinners){
                      Object.assign(fans, {spinners: arr});
                    }
                    else{
                      fans.spinners.push(obj);
                    }
                    
                  }
                }
                
              }
              
            }
            
          }



          console.log(this.feedback, 'EELIII',this.spinners);
          


        }
        else {
          
          var anElm = document.getElementById('notadded');
          anElm.style.display="block";
          console.log('ei ole kyseistä käyttäjää')
        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })


  }

  updateSpinner(){
    var elm = (<HTMLSelectElement>document.getElementById('selected-select')).value;
    console.log(elm);

    if( elm == null || elm == "" || elm == undefined || elm == "undefined" ){
      alert('Unohdit lisätä esittelyn kohteen.');
      return;
    }

    if(this.selected.length == 0){
      alert('Et valinnut palautteita');
    }


    var req = {
      selectedAnswers: this.selected,
      feedbackID: this.feedbackID,
      spinnerID: elm,
    }

    this.http.post(environment.UPDATE_SPINNER_ANSWER, req,this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {
          this.getFeedback();
          alert('Palautteiden lisääminen esittelyyn onnistui! Paina "OK" jatkaaksesi');

          document.getElementById('close-update-modal').click();
          this.selected = [];

          


        }
        else {
          
          var anElm = document.getElementById('notadded');
          anElm.style.display="block";
          console.log('ei ole kyseistä käyttäjää')
        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })

    console.log(this.selected);
  }

  createSelectedArr(answer){

    var elm = (<HTMLInputElement>document.getElementById('select-'+answer.answerID)).checked;

    if(elm){
      console.log('Lisäys')


        var obj = {
          answerID: answer.answerID,
          text: answer.text,
          feedbackID: this.feedbackID,
          createdDate:answer.createdDate,
        }

        this.selected.push(obj);

      

      console.log(this.selected)

    }
    else{
      for (let i = 0; i < this.selected.length; i++) {
        if(this.selected[i].answerID == answer.answerID) {
          this.selected.splice(i,1); 
          i-1;
        }
        
      }

      console.log(this.selected)
    }

    

  }

  generateCode(){
    var style = '<style>.nset-feedbacker{width: 400px!important;}@media(max-width:500px) {.nset-feedbacker{width: 100%!important;}}</style>'
    var str = ""
    if(this.feedback.type == 'smile'){
      var str = '<iframe height="300px" width="100%" src="'+this.pathUrl+'" > </iframe>';
    }
    else if(this.feedback.type == 'nps'){
      var str = '<iframe height="280px" width="100%" src="'+this.pathUrl+'" > </iframe>';
    }
    
    (<HTMLTextAreaElement>document.getElementById('code-area')).value = str;
    console.log('Upotuskoodisi: '+style+'\n'+str)
  }

  generateCertCode(){
    var style = '<style>.nset-feedbacker{width: 400px!important;}@media(max-width:500px) {.nset-feedbacker{width: 100%!important;}}</style>'
    var str = ""
    var g = "";
    var link = my_protocol.protocol+'://' + base_host.base_host + my_port.port+ '/#/certificates/'+this.company[0].businessName+'/' + this.businessID + '/' + this.feedback.token;

    if(this.feedback.type == "nps"){
      g = "nps"
    }
    else if(this.feedback.type == "smile"){
      g = "csat";
    }

    if(true){
      var str = '<iframe height="340"  src="'+link+'/'+g+'/12/question" > </iframe>';
    }
    
    (<HTMLTextAreaElement>document.getElementById('cert-code-area')).value = str;
    this.txtValue = str;
    console.log(this.txtValue)
    console.log('Upotuskoodisi: '+style+'\n'+str)
  }

  createCharts(){

    if(this.feedback.type =="smile"){
      this.generateAnsChart();

    }

    if(this.feedback.type == 'nps'){
      this.generateUserTypeChart()
    }
    
    this.fetchChartData(1);
    this.textAnalyis()

    
  }

  removeFeedback(){


    this.http.delete(environment.FEEDBACK+'/'+this.feedbackID, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {
          document.getElementById('closeDeleteModal').click();
          alert('Palautekysely on poistettu onnistuneesti. Paina OK siirtyäksesi kaikki kyselyt sivulle');
          this.router.navigate(['/'+this.businessID+'/feedbacks'])


        }
        else {
          var anElm = document.getElementById('notadded');
          anElm.style.display="block";
          console.log('ei ole kyseistä käyttäjää')
        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })
  }

  generateAnsChart(){

 

    var g = <HTMLCanvasElement>document.getElementById('smileDiversion');

    
    Chart.defaults.font.family = "Montserrat";
    Chart.defaults.font.size = 16;
    Chart.defaults.color ="black";


    

    this.smileDiversion = new Chart(g, {
      type: 'pie',
      data: {
          labels:['Innostunut', 'Iloinen', 'Normaali','Surullinen', 'Vihainen' ],
          datasets: [{
              label: 'Varausaste',
              data: [this.feedbackValues.loves, this.feedbackValues.smiles,this.feedbackValues.mehs,this.feedbackValues.sads,this.feedbackValues.angrys],
              backgroundColor: [
                "#5FBB48",
                "#4DBB90",
                "#7A479C",
                "#FBB729",
                "#C21D65",
              ],
              borderColor: 'gray',
              borderWidth:1,
              
              
          }
        ],
          
          
      },
      
  });


  }

  generateUserTypeChart(){


    var e = <HTMLCanvasElement>document.getElementById('userDiversion');

    
    Chart.defaults.font.family = "Montserrat";
    Chart.defaults.font.size = 16;
    Chart.defaults.color ="black";


    

    this.userDiversion = new Chart(e, {
      type: 'pie',
      data: {
          labels:['Suosittelija', 'Passiivinen', 'Kriitikko' ],
          datasets: [{
              label: 'Tyypit',
              data: [this.feedbackValues.prefs, this.feedbackValues.passives,this.feedbackValues.critics],
              backgroundColor: [
                "#0047AB",
                "#000080",
                "#CCCCFF",
                "#4169E1",
                "#0F52BA",
                "#9FE2BF",
                "#008080"
              ],
              borderColor: 'gray',
              borderWidth:1,
              
              
          }
        ],
          
          
      },
      
  });

  }

  waitStyle() {
    var that = this;

    setTimeout(() =>{
      that.createCharts();
      
    },100)

  }


  selectMonth(){

    this.chart.destroy();
    this.SMIchart.destroy();

    var monthsValue = (<HTMLSelectElement>document.getElementById('select-month')).value;
    var int_month = parseInt(monthsValue);

    this.fetchChartData(int_month);



  }

  fetchChartData(months){

    this.http.get(environment.DAILYS_DATA+'/'+months+'/'+this.feedbackID, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status = 200) {
          console.log(res)
          this.data = res.message;

          this.generateAverageChart();
          this.generateSMI()

        }
      }, (err: any) => {
        
        console.log("Error while login :: ", err);
      }
    )
  }

  generateAverageChart(){
    var g = <HTMLCanvasElement>document.getElementById('my-chart');
    var width = document.documentElement.clientWidth;
    if(width > 1024){
      g.height = 150;
    }
    else{
      g.height = 300;
    }
    
    Chart.defaults.font.family = "Montserrat";
    Chart.defaults.font.size = 16;
    Chart.defaults.color ="black";
    const nullDupes = data => data.map((x, i) => data[i - 1] === x ? null : x);

    //Nps not paired values == company
    var arr1 = [];
    var arr2 = [];
    //Smile 
    var arr3 = [];
    var arr4 = [];
    var arr5 = [];
    var arr6 = [];

    for (let i = 0; i < this.data.dates.length; i++) {
      const elm = this.data.dates[i];

      arr1.push(this.npsGoal *100);
      arr2.push(this.npsSector *100);
      arr3.push(this.csatGoal *100);
      arr4.push(this.csatSector *100);
      arr5.push(this.cesGoal) *100;
      arr6.push(this.cesSector *100);
      
    }

    
    var options = {
      scales: {
        x: {
          grid:{
            display:false
          }
        }
    },

    }

    if(this.feedback.type == 'nps'){
      var d = [{
        label: 'Keskiarvo',
        spanGaps:true,
        data: nullDupes(this.data.average),
        backgroundColor:'orange',
        pointBackgroundColor:'white',
        pointBorderColor :'orange',
        borderColor: 'orange',
        tension: 0.5,
        borderWidth:2,
        
        
    },
    {
      label: 'NPS',
      spanGaps:true,
      data: nullDupes(this.data.nps),
      backgroundColor:'#0047AB',
      borderColor: '#0047AB',
      pointBackgroundColor:'white',
      pointBorderColor :'#0047AB',
      tension: 0.5,
      borderWidth:2,
      
      
    },
    {
      label: 'Kävijät',
      spanGaps:true,
      data: nullDupes(this.data.visitors),
      backgroundColor:'#2B3E91',
      borderColor: '#2B3E91',
      pointBackgroundColor:'white',
      pointBorderColor :'#2B3E91',
      tension: 0.5,
      borderWidth:2,
      hidden:true,
      
  },
  {
    label: 'NPS viitearvo '+ this.company[0].businessName,
    spanGaps:true,
    data: arr1,
    backgroundColor:'#F54A49',
    borderColor: '#F54A49',
    pointBackgroundColor:'white',
    pointBorderColor :'#F54A49',
    tension: 0.5,
    borderWidth:2,
    
},
{
  label: 'NPS viitearvo toimiala',
  spanGaps:true,
  data: arr2,
  backgroundColor:'#2DCEA1',
  borderColor: '#2DCEA1',
  pointBackgroundColor:'white',
  pointBorderColor :'#2DCEA1',
  tension: 0.5,
  borderWidth:2,
  
},
]
    }
    else {

      var d = [{
        label: 'Keskiarvo',
        spanGaps:true,
        data: nullDupes(this.data.average),
        backgroundColor:'orange',
        pointBackgroundColor:'white',
        pointBorderColor :'orange',
        borderColor: 'orange',
        tension: 0.5,
        borderWidth:2,
        
        
    },
    {
      label: 'Kävijät',
      spanGaps:true,
      data: nullDupes(this.data.visitors),
      backgroundColor:'#2B3E91',
      borderColor: '#2B3E91',
      pointBackgroundColor:'white',
      pointBorderColor :'#2B3E91',
      tension: 0.5,
      borderWidth:2,
      hidden:true,
    },
    {
      label: 'CES',
      spanGaps:true,
      data: nullDupes(this.data.ces),
      backgroundColor:"#C21D65",
      borderColor: "#C21D65",
      pointBackgroundColor:'white',
      pointBorderColor :"#C21D65",
      tension: 0.5,
      borderWidth:2,
      
    },
    {
      label: 'CSAT',
      spanGaps:true,
      data: nullDupes(this.data.csat),
      backgroundColor:"#FBB729",
      borderColor: "#FBB729",
      pointBackgroundColor:'white',
      pointBorderColor :"#FBB729",
      tension: 0.5,
      borderWidth:2,
      
    },
    {
      label: 'CSAT viitearvo '+ this.company[0].businessName,
      spanGaps:true,
      data: arr3,
      backgroundColor:"#1188EF",
      borderColor: "#1188EF",
      pointBackgroundColor:'white',
      pointBorderColor :"#1188EF",
      tension: 0.5,
      borderWidth:2,
      
    },
    {
      label: 'CSAT toimiala',
      spanGaps:true,
      data: arr4,
      backgroundColor:"#11C5EF",
      borderColor: "#11C5EF",
      pointBackgroundColor:'white',
      pointBorderColor :"#11C5EF",
      tension: 0.5,
      borderWidth:2,
      
    },
    {
      label: 'CES viitearvo '+ this.company[0].businessName,
      spanGaps:true,
      data: arr5,
      backgroundColor:"#102549",
      borderColor: "#102549",
      pointBackgroundColor:'white',
      pointBorderColor :"#102549",
      tension: 0.5,
      borderWidth:2,
      
    },
    {
      label: 'CES toimiala',
      spanGaps:true,
      data: arr6,
      backgroundColor:"#F54152",
      borderColor: "#F54152",
      pointBackgroundColor:'white',
      pointBorderColor :"#F54152",
      tension: 0.5,
      borderWidth:2,
      
    }
]
    }

    this.chart = new Chart(g, {
      type: 'line',
      data: {
          labels:this.data.dates ,
          datasets: d,
          
          
      },
      options: options,
  });
  }

  generateSMI(){
    var g = <HTMLCanvasElement>document.getElementById('my-smi-chart');
    var width = document.documentElement.clientWidth;
    if(width > 1024){
      g.height = 150;
    }
    else{
      g.height = 300;
    }
    
    Chart.defaults.font.family = "Montserrat";
    Chart.defaults.font.size = 16;
    Chart.defaults.color ="black";
    const nullDupes = data => data.map((x, i) => data[i - 1] === x ? null : x);

    
    var options = {
      responsive: true,
      interaction: {
        intersect: false,
      },
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true
        }
      }
  }
    

  if(this.feedback.type == 'nps'){
      this.SMIchart = new Chart(g, {
        type: 'bar',
        data: {
            labels:this.data.dates ,
            datasets: [{
                label: 'Kriitikot',
                data: this.data.critics,
                backgroundColor:'red',
                
    
            },
            {
              label: 'Passiiviset',
              data: this.data.passives,
              backgroundColor:'orange',
              

          },
          {
            label: 'Suosittelijat',
            data: this.data.preferrers,
            backgroundColor:'green',
            
        },



          ],
            
            
        },
        options: options,
    });
    }
    else{

      this.SMIchart = new Chart(g, {
        type: 'bar',
        data: {
            labels:this.data.dates ,
            datasets: [{
                label: 'Innostuneet',
                data: this.data.s1,
                backgroundColor:"#5FBB48",
                
    
            },
            {
              label: 'Iloiset',
              data: this.data.s2,
              backgroundColor: "#4DBB90",
              

          },
          {
            label: 'Normaalit',
            data: this.data.s3,
            backgroundColor:"#7A479C",
            
          },
          {
            label: 'Surulliset',
            data: this.data.s4,
            backgroundColor:"#FBB729",
            
          },
          {
            label: 'Vihaiset',
            data: this.data.s5,
            backgroundColor:"#C21D65",
            
          }],
            
            
        },options: options
      })
    }
}

generateTextChart(labels,data,colors){
  var g = <HTMLCanvasElement>document.getElementById('my-text-chart');
  var width = document.documentElement.clientWidth;
  if(width > 1024){
    g.height = 150;
  }
  else{
    g.height = 300;
  }
  
  Chart.defaults.font.family = "Montserrat";
  Chart.defaults.font.size = 16;
  Chart.defaults.color ="black";

  
  var options = {
    responsive: true,
    interaction: {
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true
      }
    }
}
  

    this.textChart = new Chart(g, {
      type: 'bar',
      data: {
          labels:labels,
          datasets: [{
              label: "Tekstit",
              data: data,
              backgroundColor:colors,
          }],
          
          
      },
      options: options,
  });

}

  removeCert(type){

    var req = {
      certificate: type,
    }


    this.http.post(environment.REMOVE_CERT, req,this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {
          document.getElementById('close-delete-nps').click();
          alert('Sertifikaatti on poistettu onnistuneesti. Paina OK jataaksesi');

          this.fetchOrderDetails()



        }
        else {
          var anElm = document.getElementById('notadded');
          anElm.style.display="block";
          console.log('ei ole kyseistä käyttäjää')
        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })
  }

  addCert(type){

    var req = {
      certificate: type,
    }


    this.http.post(environment.ADD_CERT, req,this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {

          this.fetchOrderDetails();
          


          console.log(document.getElementById('show-btn'))
          document.getElementById('questionCertBtn').click();

          this.generateCertCode()



        }
        else {
          alert('Lisäys epäonnistui. Ehkä maksimimäärä on täynä')
          console.log('ei ole kyseistä käyttäjää')
        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })
  }

  removeAnswer(s,answer){

    this.http.delete(environment.REMOVE_SPINNER_ANSWER+'/'+s.spinnerID+'/'+answer.answerID, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {
          document.getElementById('closeDeleteModal').click();
          alert('Palaute on poistettu onnistuneesti. Paina OK jatkaaksesi');
          this.getFeedback();

        }
        else {
          var anElm = document.getElementById('notadded');
          anElm.style.display="block";
          console.log('ei ole kyseistä käyttäjää')
        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })
  }

  exportToExcel(){
    var arr = [];

    for (let i = 0; i < this.feedback.answers.length; i++) {
      const ans = this.feedback.answers[i];

      var obj = {
        value: ans.value,
        date: ans.createdDate,
        text: ans.text,
      }

      arr.push(obj);


    }

    console.log(arr);
    var js = JSON.stringify(arr);
    var final = JSON.parse(js)
      const ws = XLSX.utils.json_to_sheet(final)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Responses')
      XLSX.writeFile(wb, 'MoodysetFeedbacks.xlsx')
    
  }

  textAnalyis(){

    var arr = [];

    for (let i = 0; i < this.feedback.answers.length; i++) {
      const ans = this.feedback.answers[i];
      arr.push(ans.text);
    }

    let unique = arr.filter((item, i, ar) => ar.indexOf(item) === i);
    var comparsion = [];

    for (let i = 0; i < unique.length; i++) {
      const elm = unique[i];

      var obj = {
        label: elm,
        data: 0,
        value: 0,
        backgroundColor: "",
      }

      comparsion.push(obj);
    }

    for (let i = 0; i < this.feedback.answers.length; i++) {
      const ans = this.feedback.answers[i];

      for (let j = 0; j < comparsion.length; j++) {
        const c = comparsion[j];

        if(c.label == ans.text){
          c.data = c.data +1;
          c.value = ans.value;
          if(c.value >= 80){
            c.backgroundColor = "green";
          }
          else if(c.value < 50){
            c.backgroundColor = "red";
          }
          else{
            c.backgroundColor = "yellow";
          }
        }
        
      }
      
    }

    var finalize = [];
    var data = [];
    var colors = [];

    for (let i = 0; i < comparsion.length; i++) {
      const elm = comparsion[i];
      
      var e = [];
      data.push(elm.data);
      colors.push(elm.backgroundColor);
    }

    console.log(finalize);
    this.generateTextChart(unique,data, colors);
    


  }

}
