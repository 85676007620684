import { AfterContentInit, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';


@Component({
  selector: 'app-user-home',
  templateUrl: './user-home.component.html',
  styleUrls: ['./user-home.component.css']
})


export class UserHomeComponent implements OnInit {

  authToken = localStorage.getItem('auth');
  businessID: string = this.route.snapshot.params.businessID;
  userID = this.authService.getTokenData().id;
  firstName = this.authService.getTokenData().firstName;
  feedbackValues;
  types;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'auth': `${this.authToken}`  }),
    withCredentials: true,
  };

  constructor( 
    private fb: FormBuilder,
    private router: Router,
    private route:ActivatedRoute,
    private http: HttpClient,
    private authService: AuthService) { }

  ngOnInit(): void {
    console.log(this.authService.getTokenData())
    this.getFeedbackValues();
  }

  

  getFeedbackValues() {

    this.http.get(environment.TYPES, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {
          this.types = res.message;
          console.log(this.types)



        }
        else {
          this.types = []
        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })


  }




}
