


<div class="" *ngIf="!allowAnswer">
    <h1 class="text-center mt-4">Kiitos Palautteestasi</h1>
    <p class="text-center">Olit jo aikaisemmin antanut palautteen, kiitos.</p>
</div>
<div class="" *ngIf="feedbackGiven == true">
    <h1 class="text-center mt-4">Kiitos Palautteestasi</h1>
    <p class="text-center">Vastauksesi on nyt tallennettu</p>
</div>

