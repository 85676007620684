import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';


@Component({
  selector: 'app-spinners',
  templateUrl: './spinners.component.html',
  styleUrls: ['./spinners.component.css']
})
export class SpinnersComponent implements OnInit {

  errMessage = "";
  successMessage = "";
  businessID: string = this.route.snapshot.params.businessID;
  feedbacks = [];
  authToken = localStorage.getItem('auth');
  feedbackValues;
  order;
  spinners:any;


  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'auth': `${this.authToken}`  }),
    withCredentials: true,
  };

  constructor(
    private router: Router,
    private http: HttpClient,
    private route:ActivatedRoute,
    private authService: AuthService) {
      this.authService.validateBusiness(this.businessID)

     }

  ngOnInit(): void {
    this.getSpinners();
    this.fetchOrderDetails();

  }

  fetchOrderDetails() {

    this.http.get(environment.ORDER_DATA, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {
          this.order = [];
          this.order = res.message;
          console.log(this.order);


        }
        else {

        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })


  }


  getSpinners() {

    this.http.get(environment.SPINNERS, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {
          this.spinners = res.message;
          console.log(this.spinners);
        }
        else {
          this.spinners = [];
          console.log(this.spinners);
          
          
        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })


  }

}
