import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { base_host, environment, my_port, my_protocol } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';
import * as XLSX from 'xlsx';
import 'xlsx';

@Component({
  selector: 'app-create-feedbac-campaign',
  templateUrl: './create-feedbac-campaign.component.html',
  styleUrls: ['./create-feedbac-campaign.component.css']
})
export class CreateFeedbacCampaignComponent implements OnInit {

  errMessage = "";
  successMessage = "";
  feedbackID:string = this.route.snapshot.params.id;
  businessID: string = this.route.snapshot.params.businessID;
  feedback;
  authToken = localStorage.getItem('auth');
  pathUrl;
  ready = false;
  feedbackValues;
  smileDiversion;
  userDiversion;
  parsedData = [];
  selectedEmails = [];
  company;

  constructor(
    private router: Router,
    private http: HttpClient,
    private route:ActivatedRoute,
    private authService: AuthService) {
      this.authService.validateBusiness(this.businessID)

     }

     httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'auth': `${this.authToken}`  }),
      withCredentials: true,
    };

     ngOnInit(): void {
      this.getFeedback();
      this.getBusinessDetails();
    }

    getFeedback() {

      this.http.get(environment.FEEDBACK+'/'+this.feedbackID, this.httpOptions).subscribe(
        (res: any) => {
          if(res.status == 200 ) {
            this.feedback = res.message[0];
            this.pathUrl = my_protocol.protocol+'://' + base_host.base_host + my_port.port+ '/#/feedback/' + this.businessID + '/' + this.feedback.token;
  
            console.log(this.feedback);
  
          }
          else {
            var anElm = document.getElementById('notadded');
            anElm.style.display="block";
            console.log('ei ole kyseistä käyttäjää')
          }
          
        }, (err: any) => {
    
          console.log("Error while finding status :: ", err);
        })
  
  
    }

    onFileChange(event: any) {
      /* wire up file reader */
      const target: DataTransfer = <DataTransfer>(event.target);
      if (target.files.length !== 1) {
        throw new Error('Cannot use multiple files');
      }
      const reader: FileReader = new FileReader();
      reader.readAsBinaryString(target.files[0]);
      reader.onload = (e: any) => {
        /* create workbook */
        const binarystr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
  
        /* selected the first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
  
        /* save data */
        var data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
        console.log(data); // Data will be logged in array format containing objects

        this.convertToScreen(data);
        data = [];
      };
   }

   

   convertToScreen(data){
     for (let i = 0; i < data.length; i++) {
       const single = data[i];
       console.log(single.email);
       
        if(this.validateEmail(single.email) == false){
          Object.assign(single, {error: true})
        }
        else{
          Object.assign(single, {error: false})
        }
      }

      this.parsedData = data;
      this.waitStyle()
       
     }

    validateEmail(email) 
     {
      var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      if (filter.test(email)) {
        // Yay! valid
        return true;
      }
      else
        {return false;}
     }

     waitStyle() {
      var that = this;
  
      setTimeout(() =>{
        for (let i = 0; i < this.parsedData.length; i++) {
          const email = this.parsedData[i];

          var elm = document.getElementById('email-'+i);

          if(email.error){
            elm.classList.add('table-danger');
          }
          
        }
        
      },500)
  
    }

    getBusinessDetails() {

      this.http.get(environment.USER_BUSINESS, this.httpOptions).subscribe(
        (res: any) => {
          if(res.status == 200 ) {
            this.company = res.message;
  
            console.log(this.company);
  
          }
          else {
            var anElm = document.getElementById('notadded');
            anElm.style.display="block";
            console.log('ei ole kyseistä käyttäjää')
          }
          
        }, (err: any) => {
    
          console.log("Error while finding status :: ", err);
        })
  
  
    }

    sendCampaign() {

      var terms = (<HTMLInputElement>document.getElementById('privacy')).checked;
      if(!terms){
        alert('Et ole hyväksynyt käyttöehtoja!');
        return;
      }

      //Close form
      var form = document.getElementById('campaign-form');

      form.style.display = "none";

      //Show success message

      var success_msg = document.getElementById('success-msg');
      success_msg.style.display="block";

      for (let i = 0; i < this.parsedData.length; i++) {
        const email = this.parsedData[i];

        if(!email.error){
          this.selectedEmails.push(email.email);
        }
        
      }

      if(this.selectedEmails.length == 0){
        alert('Et ole lisännyt sähköposteja');
        return;
      }

      var req = {
        emails: this.selectedEmails,
        question: this.feedback.question,
        type: this.feedback.type,
        textColor: this.feedback.textColor,
        backgroundColor:this.feedback.backgroundColor,
        feedbackToken: this.feedback.token,
        businessID: this.businessID,
        businessName: this.company[0].businessName,
        feedbackID: this.feedbackID,
        msg: (<HTMLTextAreaElement>document.getElementById('email-msg')).value,  
      }


      this.http.post(environment.FEEDBACK_EMAIL_CAMPAIGN,req, this.httpOptions).subscribe(
        (res: any) => {
          if(res.status == 200 ) {

            alert('Kampanja lähetetty');
            this.router.navigate(['/'+this.businessID+'feedback-details/'+this.feedbackID])
  
          }
          else {
            var anElm = document.getElementById('notadded');
            anElm.style.display="block";
            console.log('ei ole kyseistä käyttäjää')
          }
          
        }, (err: any) => {
    
          console.log("Error while finding status :: ", err);
        })
  
  
    }
   

}

