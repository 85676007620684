<app-user-header></app-user-header>
<div class="" *ngIf="types && types.length > 0; else show_not">
<div class="bg-container header pnset-6 container-fluid pt-4 ps-4 pe-4">
  <div class="col-12 row pb-4">
      <div class="col-12 col-md-7 page-title">
          <p class="page-title h2 text-white d-inline-block mb-0">Flowt</p><br>
            <button class="btn btn-feedbacker" routerLink="/{{businessID}}/create-type">Lisää flow</button>
      </div>
      <div class="col">
          <nav class="d-none d-md-inline-block ml-md-4">
              <ol class="breadcrumb breadcrumb-links breadcrumb-dark">
                  <li class="breadcrumb-item">
                      <a routerLink="/{{businessID}}/users/home">Koti</a>
                  </li>
                  <li class="breadcrumb-item active">
                      <a href="#">Flowt</a>
                  </li>
              </ol>
          </nav>  
      </div>
  </div>
  <div class="col-12 row m-0">
      <div class="col-xl-3 col-md-6">
          <div class="card card-stats p-4">
              <div class="row">
                  <div class="col">
                      <h5 class="card-title text-uppercase text-muted mb-0">Lukumäärä</h5>
                      <span class="h2 font-weight-bold mb-0">{{types.length}}</span>
                  </div>
                  <div class="col-auto">
                      <div class="nset-icon icon-shape bg-gradient-red shadow">

                      </div>
                  </div>
<!--                   <p class="mt-3 mb-0 text-sm">
                      <span class="text-success me-2">10%</span>
                      <span class="text-nowrap">Yhteensä</span>
                  </p> -->
              </div>
          </div>
      </div>
<!--       <div class="col-xl-3 col-md-6">
          <div class="card card-stats p-4">
              <div class="row">
                  <div class="col">
                      <h5 class="card-title text-uppercase text-muted mb-0">Vastausaste</h5>
                      <span class="h2 font-weight-bold mb-0">{{feedbackValues.company.answer_rate.toFixed(2)*100}}</span>
                  </div>
                  <div class="col-auto">
                      <div class="nset-icon icon-shape bg-gradient-orange shadow">

                      </div>
                  </div>
                  <p class="mt-3 mb-0 text-sm">
                      <span class="text-success me-2">10%</span>
                      <span class="text-nowrap">Yhteensä</span>
                  </p>
              </div>
          </div>
      </div>
      <div class="col-xl-3 col-md-6">
        <div class="card card-stats p-4">
            <div class="row">
                <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">NPS</h5>
                    <span class="h2 font-weight-bold mb-0">{{feedbackValues.company.nps.toFixed(2) * 100}}</span>
                </div>
                <div class="col-auto">
                    <div class="nset-icon icon-shape bg-gradient-green shadow">

                    </div>
                </div>
                <p class="mt-3 mb-0 text-sm">
                    <span class="text-success me-2">10%</span>
                    <span class="text-nowrap">Yhteensä</span>
                </p>
            </div>
        </div>
    </div>
    <div class="col-xl-3 col-md-6">
        <div class="card card-stats p-4">
            <div class="row">
                <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">KESKIARVO</h5>
                    <span class="h2 font-weight-bold mb-0">{{feedbackValues.company.average.toFixed(2)}}</span>
                </div>
                <div class="col-auto">
                    <div class="nset-icon icon-shape bg-gradient-info shadow">

                    </div>
                </div>
                <p class="mt-3 mb-0 text-sm">
                    <span class="text-success me-2">10%</span>
                    <span class="text-nowrap">Yhteensä</span>
                </p>
            </div>
        </div>
    </div> -->
  </div>
</div>

<div class="col-12 p-0 p-md-4">
  <div class="card ">
      <div class="card-header">
          <div class="row-align-items-center">
              <div class="col">
                  <h6 class="text-dark text-uppercase ls-1 mb-1">Palautekyselyt</h6>
                  <h5 class="h3 text-black mb-0">Tiedot</h5>
              </div>
          </div>
          <div class="table-responsive" *ngIf="types.length > 0;else no_answers_content">
              <table class="table align-items-center table-flush">
                  <thead class="thead-light">
                      <tr>
                          <th>Nimi</th>
                          <th>Tiedot</th>
                      </tr>
                  </thead>
                  <tbody class="tbody-light">
                      <tr *ngFor="let data of types">
                          <th class="pointer" routerLink="/{{businessID}}/single-model/{{data._id}}">{{data.name}}</th>
                          <td><button routerLink="/{{businessID}}/single-model/{{data._id}}" class="btn-feedbacker">Tiedot</button></td>
                      </tr>
                  </tbody>

              </table>
          </div>
          <ng-template #no_answers_content>
              <div class="col-12 row m-0">
                  <div class="col-3"></div>
                  <div class="col-12 col-md-6">
                      <div class="alert alert-warning">
                          <p class="normal-text text-center">Et ole luonut kyselyitä.</p>
                      </div>
                  </div>
                  <div class="col-3"></div>
              </div>
          </ng-template>
      </div>
      
  </div>
</div>
</div>
<ng-template #show_not>
    <div class="col-12 row ms-0 me-0 mt-4">
        <div class="col-3"></div>
        <div class="col-6">
            <div class="alert alert-warning">
                <p class="text-center">Sinulla ei ole tyyppejä</p>
                <button class="btn btn-feedbacker" routerLink="/{{businessID}}/create-type">Lisää tyyppi</button>
            </div>
        </div>
        <div class="col-3"></div>
    </div>
</ng-template>







