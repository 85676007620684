 <app-user-header></app-user-header>


<div class="col-12" *ngIf="this.types">
    <div class="bg-container header pnset-6 container-fluid pt-4 ps-4 pe-4">
        <div class="col-12 row pb-4">
            <div class="col-12 col-md-7 page-title">
                <p class="page-title h2 text-white d-inline-block mb-0">Koti</p>
            </div>
            <div class="col">
                <nav class="d-none d-md-inline-block ml-md-4">
                    <ol class="breadcrumb breadcrumb-links breadcrumb-dark">
                        <li class="breadcrumb-item active">
                            <a href="#" rel="noopener noreferrer">Koti</a>
                        </li>
                    </ol>
                </nav>  
            </div>
        </div>
        <div class="col-12 row m-0">
            <div class="col-xl-3 col-md-6">
                <div class="card card-stats p-4">
                    <div class="row">
                        <div class="col">
                            <h5 class="card-title text-uppercase text-muted mb-0">Flowt</h5>
                            <span class="h2 font-weight-bold mb-0" *ngIf="types" >{{types.length}}</span>
                        </div>
                        <div class="col-auto">
                            <div class="nset-icon icon-shape bg-gradient-red shadow">

                            </div>
                        </div>
                        <p class="mt-3 mb-0 text-sm">
                            <!-- <span class="text-success me-2">10%</span> -->
                            <span class="text-nowrap">Yhteensä</span>
                        </p>
                    </div>
                </div>
            </div>



        </div>
    </div>
    <div class="col-12 ps-4 row nset-card">
        <div class="col-xl-12">
            <div class="card card-bg-question">
                <div class="card-header">
                    <div class="row align-items-center">
                        <div class="col">
                            <h6 class="text-light text-uppercase ls-1 mb-1">Flowt</h6>
                            <h5 class="h3 text-white mb-0">Yritykseni Flowt</h5>
                        </div>
                        <div class="col">
                            <ul class="nav nav-pills justify-content-end">
                                <li class="nav-item mr-2 mr-md-0">
                                    <button routerLink="/{{businessID}}/types" class="btn-feedbacker">Flowt</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table align-items-center table-flush">
                        <thead class="thead-dark">
                            <tr>
                                <th>Nimi</th>
                                <th>Tiedot</th>
                            </tr>
                        </thead>
                        <tbody class="tbody-dark">
                            <tr *ngFor="let data of types">
                                <td>{{data.name}}</td>
                                <td><button routerLink="/{{businessID}}/single-model/{{data._id}}" class="btn btn-feedbacker"><i class="fa fa-info-circle"></i>Tiedot</button></td>
                            </tr>
                        </tbody>

                    </table>
                </div>
                
            </div>

        </div>
<!--         <div class="col-xl-4">
            <div class="card ">
                <div class="card-header">
                    <div class="row align-items-center">
                        <div class="col">
                            <h6 class="text-dark text-uppercase ls-1 mb-1">Tehokkuus</h6>
                            <h5 class="h3 text-black mb-0"> NPS</h5>
                        </div>
                        <div class="col">
                            <ul class="nav nav-pills justify-content-end">
                                <li class="nav-item mr-2 mr-md-0">
                                    <button routerLink="/{{businessID}}/feedbacks" class="btn-feedbacker-dark">Kaikki</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table align-items-center table-flush">
                            <thead class="thead-light">
                                <tr>
                                    <th>Kysymys</th>
                                    <th>NPS</th>
                                    <th>Tutustu</th>
                                </tr>
                            </thead>
                            <tbody class="tbody-light">
                                    <tr *ngFor="let data of feedbackValues.feedbacks">
                                        <td>{{data.question}}</td>
                                        <td>{{(data.nps *100).toFixed(2)}}</td>
                                        <td><button routerLink="/{{businessID}}/feedback/{{data.feedbackID}}" class="btn btn-feedbacker"><i class="fa fa-info-circle"></i></button></td>
                                    </tr>
                            </tbody>
    
                        </table>
                    </div>
                </div>
                
            </div>
        </div> -->
    </div>
</div>

