import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment, my_port, my_protocol,base_host } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';

@Component({
  selector: 'app-single-model',
  templateUrl: './single-model.component.html',
  styleUrls: ['./single-model.component.css']
})
export class SingleModelComponent implements OnInit {

  errMessage = "";
  successMessage = "";
  businessID: string = this.route.snapshot.params.businessID;
  typeID: string = this.route.snapshot.params.id;
  types = [];
  authToken = localStorage.getItem('auth');
  form: FormGroup;
  e_form: FormGroup;
  selectedTree;
  editableID;
  company;
  link;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'auth': `${this.authToken}`  }),
    withCredentials: true,
  };

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private http: HttpClient,
    private route:ActivatedRoute,
    private authService: AuthService) {
      this.authService.validateBusiness(this.businessID)

     }

  ngOnInit(): void {
    this.getTypes();

    this.form = this.fb.group({
      title: ['', [
        Validators.required
      ]],
      description: [''],
      price: [''],
      url: [''],
      imageURL: [''],

    });

    this.e_form = this.fb.group({
      e_title: ['', [
        Validators.required
      ]],
      e_description: [''],
      e_price: [''],
      e_url: [''],
      e_imageURL: [''],

    });
  }
  get title() { return this.form.get('title') };
  get description() { return this.form.get('description') };
  get price() { return this.form.get('price') };
  get url() { return this.form.get('url') };
  get imageURL() { return this.form.get('imageURL') };

  get e_title() { return this.e_form.get('e_title') };
  get e_description() { return this.e_form.get('e_description') };
  get e_price() { return this.e_form.get('e_price') };
  get e_url() { return this.e_form.get('e_url') };
  get e_imageURL() { return this.e_form.get('e_imageURL') };
  getTypes() {

    this.http.get(environment.TYPE+'/'+this.typeID, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {
          this.types = res.message;
          console.log(this.types);
          this.getBusinessDetails()

        }
        else {
          this.types = [];
        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })


  }

  selectTree(tree){
    console.log(tree._id);
    this.form.get('title').setValue(tree.question);
    this.selectedTree = tree;
    console.log(this.selectedTree)
  }

  addType() {

    var t = false;

    console.log('Submitting');
    if (!this.form.valid) {
      console.log('Form not valid. Please check that fields are correctly filled in');
      return;
    }

    var value = (<HTMLInputElement>document.getElementById('w-contact')).checked;
    var contact = false;
    if(value == true){ 
      contact = true;
    }
    else{
      contact = false;
    }

/*     var formAc = document.getElementById('form-container');
    formAc.classList.add("d-none");
    document.getElementById('loader').style.display="block"; */


    var req = {
      title: this.form.get('title').value,
      description: this.form.get('description').value,
      price: this.form.get('price').value,
      url: this.form.get('url').value,
      imageURL: this.form.get('imageURL').value,
      treeID: this.selectedTree._id,
      contact: contact
    }

      this.http.post(environment.ADD_ENDCOME, req, this.httpOptions).subscribe(
        (res: any) => {
          console.log("Register success");
          if (res.status == 200) {
            alert('Lopputulema lisätty onnistuneesti');
            //this.router.navigate(['/'+this.businessID+'/types']);
            this.getTypes()
            this.form.reset();
          } else {
            alert('Virhe lisätessä tyyppiä')
/*             document.getElementById('loader').style.display="none";
            formAc.classList.remove("d-none"); */
          }
        }, (err: any) => {

          console.log("Error while login :: ", err);
        }
      )

    }

    readFile(e){
        const file = e.target.files[0];
        if (!file) {
            return;
        }
        const reader = new FileReader();
        var that = this
        reader.onload = (evt) => {
            that = this
            const xmlData: string = (evt as any).target.result;
            that.sendxml(xmlData);
        };
        var x = reader.readAsText(file);
        console.log(x);
    }

    sendxml(xml){
      var req = {
        xml: xml,
        typeID: this.typeID,
      }

      this.http.post(environment.ADD_FLOW, req, this.httpOptions).subscribe(
        (res: any) => {
          console.log("Register success");
          if (res.status == 200) {
            alert('Flow lisätty onnistuneesti');
            this.getTypes()
          } else {
            alert('Virhe lisätessä tyyppiä')

          }
        }, (err: any) => {

          console.log("Error while login :: ", err);
        })
    }

    deleteFlow(){
      var req = {
        typeID: this.typeID,
      }

      this.http.post(environment.DELETE_FLOW, req, this.httpOptions).subscribe(
        (res: any) => {
          if (res.status == 200) {
            alert('Flow poistettu onnistuneesti');
            this.router.navigate(['/'+this.businessID+'/types']);
          } else {
            alert('Virhe lisätessä tyyppiä')

          }
        }, (err: any) => {

          console.log("Error while login :: ", err);
        })
    }

    preVisibility(e){

      var id = e.target.id;

      var value = (<HTMLInputElement>document.getElementById(id)).checked;
      if(id == "w-contact" && value == true){
        document.getElementById('contact').style.display = "block";
        document.getElementById('pref').style.display = "none";
        (<HTMLInputElement>document.getElementById('w-pref')).checked = false;
      }

      if(id == "w-pref" && value == true){
        document.getElementById('contact').style.display = "none";
        document.getElementById('pref').style.display = "block";
        (<HTMLInputElement>document.getElementById('w-contact')).checked = false;

      }

    }
    preVisibilityEdit(e){

      var id = e.target.id;

      var value = (<HTMLInputElement>document.getElementById(id)).checked;
      if(id == "we-contact" && value == true){
        document.getElementById('econtact').style.display = "block";
        document.getElementById('epref').style.display = "none";
        (<HTMLInputElement>document.getElementById('we-pref')).checked = false;
      }

      if(id == "we-pref" && value == true){
        document.getElementById('econtact').style.display = "none";
        document.getElementById('epref').style.display = "block";
        (<HTMLInputElement>document.getElementById('we-contact')).checked = false;

      }

    }

    editEndcome(endcome){
      this.editableID = endcome.endcomeID;
      this.e_form.get('e_title').setValue(endcome.title);
      this.e_form.get('e_description').setValue(endcome.description);
      this.e_form.get('e_url').setValue(endcome.url);
      this.e_form.get('e_imageURL').setValue(endcome.imageURL);
      this.e_form.get('e_price').setValue(endcome.price);

      if(endcome.contact){
        document.getElementById('econtact').style.display = "block";
        document.getElementById('epref').style.display = "none";
        (<HTMLInputElement>document.getElementById('we-contact')).checked = true;
      }
      else{
        document.getElementById('econtact').style.display = "none";
        document.getElementById('epref').style.display = "block";
        (<HTMLInputElement>document.getElementById('we-pref')).checked = true;
      }

      document.getElementById('edit-btn').click()
    }

    deleteEndcome(endcome){
      this.editableID = endcome.endcomeID;

      for (let i = 0; i < this.types.length; i++) {
        const type = this.types[i];
        

        for (let j = 0; j < type.endcomes.length; j++) {
          var endcome = type.endcomes[j];
          console.log(endcome, this.editableID)
          if(endcome.endcomeID.toString() == this.editableID){


            

            type.endcomes.splice(j, 1);
            var endcomes = type.endcomes;
            break;
          }
        }
        
      }

      console.log(endcomes, this.types);

      var request = {
        treeID: this.selectedTree._id,
        endcomes: endcomes,
      }


      this.http.post(environment.EDIT_ENDCOME, request, this.httpOptions).subscribe(
        (res: any) => {
          if (res.status == 200) {
            alert('Lopputulema poistettu onnistuneesti');
            this.getTypes();
            //this.router.navigate(['/'+this.businessID+'/types']);
          } else {
            alert('Virhe lisätessä tyyppiä')

          }
        }, (err: any) => {

          console.log("Error while login :: ", err);
        })
    }

    sendEditEndcome(){

      console.log('tuli')


      var value = (<HTMLInputElement>document.getElementById('w-contact')).checked;
      var contact = false;
      if(value == true){ 
        contact = true;
      }
      else{
        contact = false;
      }
  
  /*     var formAc = document.getElementById('form-container');
      formAc.classList.add("d-none");
      document.getElementById('loader').style.display="block"; */
  
  
      var req = {
        title: this.e_form.get('e_title').value,
        description: this.e_form.get('e_description').value,
        price: this.e_form.get('e_price').value,
        url: this.e_form.get('e_url').value,
        imageURL: this.e_form.get('e_imageURL').value,
        treeID: this.selectedTree._id,
        contact: contact
      }
      var editaleType = "";

      for (let i = 0; i < this.types.length; i++) {
        const type = this.types[i];
        

        for (let j = 0; j < type.endcomes.length; j++) {
          var endcome = type.endcomes[j];
          console.log(endcome, this.editableID)
          if(endcome.endcomeID.toString() == this.editableID){
            console.log(endcome, 'vaalmis')
            editaleType = type._id;

            

            type.endcomes.splice(j, 1);

            Object.assign(req,{endcomeID: this.editableID})
            type.endcomes.push(req);
            endcome = req
            console.log(endcome);
            var endcomes = type.endcomes;
            break;
          }
        }
        
      }

      console.log(editaleType, endcomes, this.types);

      var request = {
        treeID: this.selectedTree._id,
        endcomes: endcomes,
      }


      this.http.post(environment.EDIT_ENDCOME, request, this.httpOptions).subscribe(
        (res: any) => {
          if (res.status == 200) {
            alert('Lopputulema muokattu onnistuneesti');
            this.getTypes();
            this.e_form.reset();
            //this.router.navigate(['/'+this.businessID+'/types']);
          } else {
            alert('Virhe lisätessä tyyppiä')

          }
        }, (err: any) => {

          console.log("Error while login :: ", err);
        })
    }

    getBusinessDetails() {

      this.http.get(environment.USER_BUSINESS, this.httpOptions).subscribe(
        (res: any) => {
          if(res.status == 200 ) {
            this.company = res.message;
  
            this.link = my_protocol.protocol+'://' + base_host.base_host + my_port.port+ '/#/home/'+this.company[0].token+'/' + this.typeID;
  
  
            console.log(this.company);
  
          }
          else {
            var anElm = document.getElementById('notadded');
            anElm.style.display="block";
            console.log('ei ole kyseistä käyttäjää')
          }
          
        }, (err: any) => {
    
          console.log("Error while finding status :: ", err);
        })
  
  
    }
  


}
