<app-user-header></app-user-header>
<div class="" *ngIf="feedback && feedback.identifier">

    <div class="bg-container header pnset-6 container-fluid pt-4 ps-4 pe-4">
        <div class="col-12 row pb-4">
            <div class="col-12 col-md-7 page-title">
                <p class="page-title h2 text-white d-inline-block mb-0">Luo kampanja</p> <br>
                <button class="btn btn-feedbacker mt-4"data-bs-toggle="modal" data-bs-target="#exampleModal" ><i class="fas fa-info-circle me-2"></i>Ohjeet</button>
            </div>
            <div class="col">
                <nav class="d-none d-md-inline-block ml-md-4">
                    <ol class="breadcrumb breadcrumb-links breadcrumb-dark">
                        <li class="breadcrumb-item">
                            <a routerLink="/{{businessID}}/users/home">Koti</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a routerLink="/{{businessID}}/feedbacks">Palautekyselyt</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a routerLink="/{{businessID}}/feedback/{{feedbackID}}">{{feedback.question}}</a>
                        </li>
                        <li class="breadcrumb-item active">
                            <a href="#">Luo kampanja</a>
                        </li>
                    </ol>
                </nav>  
            </div>
        </div>
        <div class="col-12 row">

        </div>
      </div>

      <div class="col-12 ps-4 row nset-card me-0 pb-4">
        <div class="col-xl-8">
            <div class="card card-bg-question">
                <div class="card-header">
                    <div class="row-align-items-center">
                        <div class="col">
                            <h6 class="text-light text-uppercase ls-1 mb-1">Lähetyslista</h6>
                            <h5 class="h3 text-white mb-0">Sähköpostiosoitteet</h5>
                        </div>
                    </div>
                </div>
                <div class="alert alert-success" id="success-msg" style="display: none;" >
                    <p class="text-center">Kampanjan luonti onnistui!</p>
                </div>
                <div id="campaign-form">
                    <div class="p-3" id="input">
                        <input type="file" style="color:white; opacity: 1;" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" (change)="onFileChange($event)" >
                    </div>
                    <ng-template #no_answers_content>
                        <div class="col-12 row me-0 mt-3">
                            <div class="col-3"></div>
                            <div class="col-12 col-md-6">
                                <div class="alert alert-warning"><p>Lisää excel.</p>
                                </div>
                            </div>
                            <div class="col-3"></div>
                        </div>
                    </ng-template>
                    <div class="table-responsive p-2 p-md-4" *ngIf="parsedData.length > 0;else no_answers_content">
                        <table class="table align-items-center table-flush">
                            <thead class="thead-dark">
                                <tr>
                                    <th>Sähköpostiosoite</th>
                                    <th>Virhe</th>
                                </tr>
                            </thead>
                            <tbody class="tbody-dark">
                                <tr *ngFor="let data of parsedData; let i = index" id="email-{{i}}">
                                    <td>{{data.email}}</td>
                                    <td>
                                        <span *ngIf="data.error">Ei lähetetä</span>
                                    </td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                    <div class="col-12 row m-0 pt-2 ps-0 pe-0 ps-md-4 pe-md-4">
                        <div class="form-floating mb-2  col-12 p-0">
                            <textarea style="min-height:125px"   placeholder="Turvallisuusviesti" id="email-msg" class="form-control" >Antaisitko meille palautetta? Palaute on vain yhden klikkauksen päässä.</textarea>
                            <label for="securityText">Muokkaa tekstiä sähköpostiin</label>
                        </div>
                      </div>
                        <div class="row col-12 ms-9 ps-0 ms-md-2 ps-md-4">
                            <div class="form-group privacy-text-wrap mb-2 col-10 col-md-6 p-0">
                            <label type="text" readonly class="body2b-b form-control-plaintext m-0 p-0 privacy-text">Olen lukenut palvelun <a routerLink="/termsofservice"> käyttöehdot </a>sekä hyväksyn <a routerLink="/privacypolicy">tietosuojaselosteen</a>*</label>
                            </div>
                            <div class="form-group mx-sm-3 mb-2  col-1 p-0">
                            <input style="width:20px; height:20px" type="checkbox" id="privacy"  required>
                            </div>
                        </div>
                    <button class="btn-feedbacker ms-0 ms-md-4" (click)="sendCampaign()"><i class="far fa-paper-plane me-2"></i>Lähetä kampanja</button>
                </div>
            </div>

        </div>
        <div class="col-xl-4">
            <div class="card ">
                <div class="card-header">
                    <div class="row-align-items-center">
                        <div class="col">
                            <h6 class="text-dark text-uppercase ls-1 mb-1">Palautekysely</h6>
                            <h5 class="h3 text-black mb-0">Tiedot</h5>
                        </div>
                    </div>
                    .
                    <div class="table-responsive">
                        <table class="table align-items-center table-flush">
                            <thead class="thead-light">
                                <tr>
                                    <th>Tieto</th>
                                    <th>Vastaus</th>
                                </tr>
                            </thead>
                            <tbody class="tbody-light">
                                <tr>
                                    <th width="25%">Tunniste</th>
                                    <td width="75%">{{feedback.identifier}}</td>
                                </tr>
                                <tr>
                                    <th width="25%">Palautetyyppi</th>
                                    <td width="75%">
                                        <span *ngIf="feedback.type == 'smile'">Hymynaama</span>
                                        <span *ngIf="feedback.type == 'nps'">NPS</span>
                                        <span *ngIf="feedback.type == 'thumb'">Peukalo</span>
                                        <span *ngIf="!feedback.type">Määrittämätön</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th width="25%">Alkupäivämäärä</th>
                                    <td width="75%">{{feedback.startDate | formateDate}}</td>
                                </tr>
                                <tr>
                                    <th width="25%">Loppupäivämäärä</th>
                                    <td width="75%">{{feedback.endDate | formateDate}}</td>
                                </tr>
                                <tr>
                                    <th width="25%">Kiitosviesti</th>
                                    <td width="75%">{{feedback.thankYouText}}</td>
                                </tr>
                                <tr>
                                    <th width="25%">Tekstiväri</th>
                                    <td width="75%">{{feedback.textColor}}</td>
                                </tr>
                                <tr>
                                    <th width="25%">Taustaväri</th>
                                    <td width="75%">{{feedback.backgroundColor}}</td>
                                </tr>
                            </tbody>
    
                        </table>
                    </div>

                </div>
                
            </div>
        </div>
    </div>


</div>

<!-- Button trigger modal -->


<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-body">
        <h3 class="text-center">Ohjeet</h3>

        <h5 class="mt-4">1) Excelin lataaminen</h5>
        <p>Lisää sarakkeeseen A ja riville yksi teksti "email". Tämän jälkeen voit lisätä sähköpostiosoitteita sarakekeseen A. Alla olevaasa kuvassa on esimerkki.</p>
        <img src="../../assets/images/excel_import.jpg" width="300px" alt="" srcset="">

        <h5 class="mt-4">2) Virheelliset sähköpostiosoitteet </h5>
        <p>Virheelliset sähköpostiosoitteet näkyvät punaisella taustalla. Näihin ei lähetetä palautekyselyä.</p>

        <h5 class="mt-4">3) Muut usein kysytyt huomiot </h5>
        <p>Vastaanottajat eivät näe toisiaan. MoodySet ei tallenna lähetettyjä sähköposteja eikä sähköpostiosoitteita. </p>
        <div class="text-center mt-4">
            <p ><a href="#" data-bs-dismiss="modal">Sulje</a></p>
 
        </div>
      </div>
            
    </div>
  </div>
</div>