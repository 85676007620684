<app-user-header></app-user-header>


<div class="wrapperi">

  <div class="bg-container header pnset-6 container-fluid pt-4 ps-4 pe-4">
    <div class="col-12 row pb-4">
        <div class="col-12 col-md-7 page-title">
            <p class="page-title h2 text-white d-inline-block mb-0">{{btnText}}</p> <br>
            <p class="desc-text text-white">{{btnText}} täyttämällä alla olevat tiedot.</p>
        </div>
        <div class="col">
            <nav class="d-none d-md-inline-block ml-md-4">
                <ol class="breadcrumb breadcrumb-links breadcrumb-dark">
                    <li class="breadcrumb-item">
                        <a routerLink="/{{businessID}}/users/home">Koti</a>
                    </li>
                    <li class="breadcrumb-item">
                      <a routerLink="/{{businessID}}/company-details">Yrityksen tiedot</a>
                    </li>
                    <li class="breadcrumb-item active">
                        <a href="#">{{btnText}}</a>
                    </li>
                </ol>
            </nav>  
        </div>
    </div>
    <div class="col-12 row">

    </div>
  </div>


  <div class="col-12 p-0 row m-0">
    <div class="col-3"></div>
    <div class="col-12 col-md-6 p-0 input-cover mt-4 p-4">
      <div class="col-12 p-0 mt-4">
        <div class="col-12 text-center p-0">
          <h1 class="mt-4">{{btnText}}</h1>
          <p class="desc-text">{{btnText}} täyttämällä alla olevat tiedot.</p>
        </div>
        <div class="alert alert-warning" *ngIf="messageErr">
          <p class="text-center">Samalla tunnisteella löyti jo palautekysely</p>
        </div>
      </div>

      <div id="loader" class="loader-container mt-4" style="display:none">
        <div class="loader mx-auto"></div>
        <p class="text-center pt-3">Käyttäjää lisätään...</p>
      </div>

      <div style="display: block;" id="form-container" class="">
        <form class="flex-row add-pet-form" [formGroup]="form"
        (ngSubmit)="onSubmit()">

        <!-- Etunimi ja sukunimi -->
        <div class="col-12 row m-0 pt-2">

            <div class="form-floating mb-2 col-12 col-md-6 p-0">
                <input type="text" placeholder="Etunimi" formControlName="firstName" id="firstName" class="form-control" [class.invalid-input]="firstName.errors && firstName.touched" required>
                <label for="firstName">Etunimi</label>
                <div class="my-alerts" *ngIf="firstName.invalid && firstName.touched">
                <p class="mt-2" *ngIf="firstName.errors.required">firstName on pakollinen</p>
                <p *ngIf="firstName.errors.firstName"> Vaaditaan kunnollinen firstName</p>
                </div>
            </div>

            <div class="form-floating mb-2 ps-md-2 col-12 col-md-6 p-0">
                <input type="text" placeholder="Sukunimi" formControlName="lastName" id="lastName" class="form-control" [class.invalid-input]="lastName.errors && lastName.touched" required>
                <label class="ps-md-4" for="lastName">Sukunimi</label>
                <div class="my-alerts" *ngIf="lastName.invalid && lastName.touched">
                <p class="mt-2" *ngIf="lastName.errors.required">Sukunimi on pakollinen</p>
                <p *ngIf="lastName.errors.lastName"> Vaaditaan määrätty Sukunimi</p>
                </div>
            </div>
        </div>
          <!-- Sähköposti -->
          <div class="col-12 row m-0 pt-2">

            <div class="form-floating mb-2  col-12 col-md-12 p-0">
                <input type="email" placeholder="Sähköposti" formControlName="email" id="email" class="form-control" [class.invalid-input]="email.errors && email.touched" required>
                <label for="email">Sähköposti</label>
                <div class="my-alerts" *ngIf="email.invalid && email.touched">
                <p class="mt-2" *ngIf="email.errors.required">email on pakollinen</p>
                <p *ngIf="email.errors.email"> Vaaditaan kunnollinen firstName</p>
                </div>
            </div>

        </div>

          
                
            <div class="form-inline mt-2">
              <div class="form-group mb-2 flex-row">
                <button [disabled]="form.invalid" class="btn btn-feedbacker w-100"><i class="fas fa-sign-in-alt me-2"></i>{{btnText}}</button>
              </div>
            </div>
          </form>

        </div>
      </div>
      <div class="col-3"></div>
</div>