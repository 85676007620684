<app-user-header></app-user-header>
<div class="" *ngIf="ready">

    <div class="bg-container header pnset-6 container-fluid pt-4 ps-4 pe-4">
        <div class="col-12 row pb-4">
            <div class="col-12 col-md-7 page-title">
                <p class="page-title h2 text-white d-inline-block mb-0">{{feedback.question}}</p> <br>
                <button class="btn btn-feedbacker me-4 mt-4" routerLink="/{{businessID}}/edit-feedback/{{feedbackID}}"><i class="fas fa-edit me-2"></i>Muokkaa</button>
                <button *ngIf="feedback.type != 'thumb'" class="btn btn-feedbacker mt-0 mt-md-4" routerLink="/{{businessID}}/create-campaign/{{feedbackID}}"><i class="fas fa-edit me-2"></i>Luo kampanja</button><br>
                <button class="btn btn-feedbacker-delete" data-bs-toggle="modal" data-bs-target="#delete-feedback" ><i class="far fa-trash-alt me-2"></i>Poista</button>
            </div>
            <div class="col">
                <nav class="d-none d-md-inline-block ml-md-4">
                    <ol class="breadcrumb breadcrumb-links breadcrumb-dark">
                        <li class="breadcrumb-item">
                            <a routerLink="/{{businessID}}/users/home">Koti</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a routerLink="/{{businessID}}/feedbacks">Palautekyselyt</a>
                        </li>
                        <li class="breadcrumb-item active">
                            <a href="#">{{feedback.question}}</a>
                        </li>
                    </ol>
                </nav>  
            </div>
        </div>
        <div class="col-12 row m-0" >
            <div class="col-xl-3 col-md-6">
                <div class="card card-stats p-4">
                    <div class="row">
                        <div class="col">
                            <h5 class="card-title text-uppercase text-muted mb-0">Katselut</h5>
                            <span class="h2 font-weight-bold mb-0">{{feedback.visitors}}</span>
                        </div>
                        <div class="col-auto">
                            <div class="nset-icon icon-shape bg-gradient-red shadow">
      
                            </div>
                        </div>
                        <p class="mt-3 mb-0 text-sm">
                            <!-- <span class="text-success me-2">10%</span> -->
                            <span class="text-nowrap">Yhteensä</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-md-6">
                <div class="card card-stats p-4">
                    <div class="row">
                        <div class="col">
                            <ng-container *ngIf="feedback && feedback.type == 'nps';else smile_top">
                                <h5 class="card-title text-uppercase text-muted mb-0">Vastausaste</h5>
                                <span *ngIf="feedbackValues.answer_rate" class="h2 font-weight-bold mb-0">{{(feedbackValues.answer_rate * 100).toFixed(2)}}% ({{feedbackValues.answer_number}} kpl)</span>    
                            </ng-container>
                            <ng-template #smile_top>
                                <h5 class="card-title text-uppercase text-muted mb-0">CSAT</h5>
                                <span *ngIf="feedbackValues.answer_rate" class="h2 font-weight-bold mb-0">{{(feedbackValues.csat * 100).toFixed(2)}}% </span>    
                            </ng-template>
                        </div>
                        <div class="col-auto">
                            <div class="nset-icon icon-shape bg-gradient-orange shadow">
      
                            </div>
                        </div>
                        <p class="mt-3 mb-0 text-sm">
                            <!-- <span class="text-success me-2">10%</span> -->
                            <span class="text-nowrap">Yhteensä</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-md-6">
                <div class="card card-stats p-4">
                    <div class="row">
                        <div class="col">
                            <ng-container *ngIf="feedback && feedback.type == 'nps';else smile_top_ces">
                                <h5 class="card-title text-uppercase text-muted mb-0">NPS</h5>
                                <span *ngIf="feedbackValues.nps_score" class="h2 font-weight-bold mb-0">{{(feedbackValues.nps_score*100).toFixed(2)}}</span>    
                            </ng-container>
                            <ng-template #smile_top_ces>
                                <h5 class="card-title text-uppercase text-muted mb-0">CES</h5>
                                <span *ngIf="feedbackValues.ces" class="h2 font-weight-bold mb-0">{{(feedbackValues.ces * 100).toFixed(2)}}% </span>    
                            </ng-template>
                        </div>
                        <div class="col-auto">
                            <div class="nset-icon icon-shape bg-gradient-green shadow">
      
                            </div>
                        </div>
                        <p class="mt-3 mb-0 text-sm">
                            <!-- <span class="text-success me-2">10%</span> -->
                            <span class="text-nowrap">Yhteensä</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-md-6">
                <div class="card card-stats p-4">
                    <div class="row">
                        <div class="col">
                            <h5 class="card-title text-uppercase text-muted mb-0">KESKIARVO</h5>
                            <span *ngIf="feedbackValues.average_value" class="h2 font-weight-bold mb-0">{{feedbackValues.average_value.toFixed(2)}}</span>
                        </div>
                        <div class="col-auto">
                            <div class="nset-icon icon-shape bg-gradient-info shadow">
      
                            </div>
                        </div>
                        <p class="mt-3 mb-0 text-sm">
                            <!-- <span class="text-success me-2">10%</span> -->
                            <span class="text-nowrap">Yhteensä</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
      </div>

      <div class="col-12 ps-4 row nset-card pb-4">
        <div class="col-xl-8">
            <div class="card card-bg-question p-0 p-md-4">
                <div class="card-header">
                    <div class="row-align-items-center">
                        <div class="col">
                            <h6 class="text-light text-uppercase ls-1 mb-1">Yleisnäkymä</h6>
                            <h5 class="h3 text-white mb-0">Jakautuminen</h5>
                        </div>
                    </div>
                </div>
                <div class="col-12 row">
                    <div class="col-12 col-md-6 mb-4 mb-md-0">
                        <div class="card container-box p-4">
                             <ng-container *ngIf="feedback.type == 'smile'; else not_smile">
                                <h3 class="text-center">Vastaukset</h3>
                                <div [hidden]="!smileDiversion">
                                    <canvas class=" w-100" id="smileDiversion" style="max-height:350px; min-height:250px" >{{smileDiversion}}</canvas>
                                </div>
                             </ng-container>
                             <ng-template #not_smile>
                                <h3 class="text-center">Käyttäjätyypit</h3>
                                <div [hidden]="!userDiversion">
                                    <canvas class=" w-100" id="userDiversion" style="max-height:350px; min-height:250px"  >{{userDiversion}}</canvas>
                                </div>
                             </ng-template>
                        </div>
                    </div>
<!--                     <div class="col-12 col-md-6 mb-4 mb-md-0">
                        <div class="card container-box p-4">

                        </div>
                    </div> -->
                </div>
                
            </div>

        </div>
        <div class="col-xl-4">
            <div class="card ">
                <div class="card-header">
                    <div class="row-align-items-center">
                        <div class="col">
                            <h6 class="text-dark text-uppercase ls-1 mb-1">Palautekysely</h6>
                            <h5 class="h3 text-black mb-0">Tiedot</h5>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table align-items-center table-flush">
                            <thead class="thead-light">
                                <tr>
                                    <th>Tieto</th>
                                    <th>Vastaus</th>
                                </tr>
                            </thead>
                            <tbody class="tbody-light">
                                <tr>
                                    <th width="25%">Tunniste</th>
                                    <td width="75%">{{feedback.identifier}}</td>
                                </tr>
                                <tr>
                                    <th width="25%">Palautetyyppi</th>
                                    <td width="75%">
                                        <span *ngIf="feedback.type == 'smile'">Hymynaama</span>
                                        <span *ngIf="feedback.type == 'nps'">NPS</span>
                                        <span *ngIf="feedback.type == 'thumb'">Peukalo</span>
                                        <span *ngIf="!feedback.type">Määrittämätön</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th width="25%">Kiitosviesti</th>
                                    <td width="75%">{{feedback.thankYouText}}</td>
                                </tr>
                                <tr>
                                    <th width="25%">Turvallisuusviesti</th>
                                    <td width="75%">{{feedback.securityText}}</td>
                                </tr>
                                <tr>
                                    <th width="25%">Tekstiväri</th>
                                    <td width="75%">{{feedback.textColor}}</td>
                                </tr>
                                <tr>
                                    <th width="25%">Taustaväri</th>
                                    <td width="75%">{{feedback.backgroundColor}}</td>
                                </tr>
                                <tr>
                                    <th width="25%">Linkki</th>
                                    <td width="75%"><a target="_blank" href="{{pathUrl}}">Näytä</a></td>
                                </tr>
                                <tr >
                                    <th width="25%">Upotuskoodi</th>
                                    <td width="75%"><button class="btn btn-feedbacker" data-bs-toggle="modal" data-bs-target="#show-code" (click)="generateCode()"><i class="fas fa-code me-2"></i>Näytä upotuskoodi</button></td>
                                </tr>
                                <tr *ngIf="order && order.certificates.webCertificate">
                                    <th width="25%">Sertifikaatti</th>
                                    <td *ngIf="!isNPSCert" width="75%"><button class="btn btn-feedbacker" (click)="addCert(this.feedbackID)" ><i class="fas fa-code me-2"></i>Näytä upotuskoodi</button></td>
                                    <td *ngIf="isNPSCert" width="75%"><button class="btn btn-feedbacker-delete" data-bs-toggle="modal" data-bs-target="#delete-cert-nps"><i class="far fa-trash-alt me-2"></i>Poista</button>

                                </tr>
                            </tbody>
    
                        </table>
                    </div>
                </div>
                
            </div>
        </div>
    </div>

    <div class="col-12 p-0 p-md-4" *ngIf="order && order.web.payingCustomer">
        <div class="card ">
            <div class="card-header">
                <div class="row-align-items-center">
                    <div class="col">
                        <h6 class="text-dark text-uppercase ls-1 mb-1">Palautekysely</h6>
                        <h5 class="h3 text-black mb-0">Kehitys</h5>
                    </div>
                </div>

                <h1 class="text-center"></h1>
                <div class="text-center">
                    <div class="col-12 col-md-3">
                        <select class="form-control" id="select-month" (change)="selectMonth()">
                            <option value="1">1 kk</option>
                            <option value="3">3 kk</option>
                            <option value="6">6 kk</option>
                            <option value="12">12 kk</option>
                            <option value="999999">Alku</option>
                        </select>
                    </div>
                </div>
                <div [hidden]="!chart">
                    <canvas class=" w-100 mt-4" id="my-chart" style="max-height:350px; min-height:250px"  >{{chart}}</canvas>
                </div>
            </div>
            
        </div>
    </div>

    <div class="col-12 p-0 p-md-4" *ngIf="order && order.web.payingCustomer">
        <div class="card ">
            <div class="card-header">
                <div class="row-align-items-center">
                    <div class="col">
                        <h6 class="text-dark text-uppercase ls-1 mb-1">Palautekysely</h6>
                        <h5 class="h3 text-black mb-0">Smile index (SMI)</h5>
                    </div>
                </div>
                <div [hidden]="!SMIchart">
                    <canvas class=" w-100 mt-4" id="my-smi-chart" style="max-height:350px; min-height:250px"  >{{SMIchart}}</canvas>
                </div>
            </div>
            
        </div>
    </div>

    <div class="col-12 p-0 p-md-4" *ngIf="order && order.web.payingCustomer">
        <div class="card ">
            <div class="card-header">
                <div class="row-align-items-center">
                    <div class="col">
                        <h6 class="text-dark text-uppercase ls-1 mb-1">Tekstianalyysi</h6>
                        <h5 class="h3 text-black mb-0">Tekstianalyysi </h5>
                    </div>
                </div>
                <div [hidden]="!textChart">
                    <canvas class=" w-100 mt-4" id="my-text-chart" style="max-height:350px; min-height:250px"  >{{textChart}}</canvas>
                </div>
            </div>
            
        </div>
    </div>

    <div class="col-12 p-0 p-md-4" >
        <div class="card ">
            <div class="card-header">
                <div class="row-align-items-center">
                    <div class="col">
                        <h6 class="text-dark text-uppercase ls-1 mb-1">Palautekysely</h6>
                        <h5 class="h3 text-black mb-0">Tiedot</h5>
                    </div>
                    <div class="col">
                        <ul class="nav nav-pills justify-content-end">
                            <li class="nav-item mr-2 mr-md-0">
                                <button class="btn-feedbacker-dark" data-bs-toggle="modal" data-bs-target="#show-selected">Esittely</button>
                            </li>
                        </ul>
                    </div>
                    <button class="btn-feedbacker" (click)="exportToExcel()"><i class="fas fa-file-excel me-2"></i>Vie exceliin</button>

                </div>
                <div class="table-responsive" *ngIf="feedback.answers.length > 0;else no_answers_content">
                    <table class="table align-items-center table-flush">
                        <thead class="thead-light">
                            <tr>
                                <th>Aika</th>
                                <th>Arvosana</th>
                                <th>Palaute</th>
                                <th>Esittely</th>
                                <th>Valinta</th>
                            </tr>
                        </thead>
                        <tbody class="tbody-light">
                            <tr *ngFor="let answer of feedback.answers">
                                <th>{{answer.createdDate | formateDate}}</th>
                                <td>{{answer.value}}</td>
                                <td>{{answer.text}}</td>
                                <td *ngIf="answer.spinners && answer.spinners.length > 0;else other_cont">
                                    <ng-container *ngFor="let s of answer.spinners">
                                        <div class="tag-cont">
                                            <span (click)="removeAnswer(s,answer)" class="tag">{{s.name}}<i class="fas fa-trash-alt ms-2"></i></span>
                                        </div>
                                    </ng-container>
                                    
                                </td>
                                <ng-template #other_cont>
                                    <td></td>
                                </ng-template>
                                <td *ngIf="answer.answerID">
                                    <input  (click)="createSelectedArr(answer)" type="checkbox" id="select-{{answer.answerID}}" >
                                </td>
                            </tr>
                        </tbody>

                    </table>
                </div>
                <ng-template #no_answers_content>
                    <div class="col-12 row m-0">
                        <div class="col-3"></div>
                        <div class="col-12 col-md-6">
                            <div class="alert alert-warning">
                                <p class="normal-text text-center">Kyselysi ei ole vielä saanut vastauksia.</p>
                            </div>
                        </div>
                        <div class="col-3"></div>
                    </div>
                </ng-template>
            </div>
            
        </div>
    </div>






<!-- <div class="col-12">
    <h1>Näkymä</h1>
    <style>
        .nset-feedbacker{
                width: 400px!important;
        }
        @media(max-width:500px) {
            .nset-feedbacker{
                width: 100%!important;
            }
        }
    </style>
    <iframe height="300px"  class="nset-feedbacker" width="500px" src="http://192.168.1.201:4200/feedback/123/ebef05beae5a50727b2d61cd860315be656fbd53" frameborder="0"></iframe>
</div> -->
</div>

               <!-- Modal Check before deleting offer -->
               <button data-bs-toggle="modal" id="checkDeleteBtn" data-bs-target="#delete-feedback" style="display:none"></button>
               <div class="modal fade" id="delete-feedback" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                 <div class="modal-dialog" role="document">
                   <div class="modal-content">
                     <div class="modal-header">
                       <h5 class="modal-title" id="exampleModalLabel">Palautekyselyn poistaminen</h5>
                       <!-- <p>Kaikki tähdellä merkityt kentät ovat pakollisia</p> -->
                       <button type="button" class="close" id="closeDeleteModal" data-bs-dismiss="modal" aria-label="Close">
                         <span aria-hidden="true">&times;</span>
                       </button>
                     </div>
                     <div class="modal-body">
                       <h5 class="text-center">Poistetaanko palautekysely pysyvästi?</h5>
                       <div class="col-12 row m-0 pt-4 pb-4">
                        <div class="col-12 text-center">
                            <button class="btn btn-feedbacker-delete" (click)="removeFeedback()"><i class="far fa-trash-alt me-2"></i>Poista</button>
                          </div>
                         <div class="col-12 text-center">
                           <a class="pointer"  id="close-delete-contractor" data-bs-dismiss="modal">Peruuta</a>
                         </div>
                       </div>
                     </div>
                   </div>
                 </div>
               </div>

                              <!-- Modal Check before deleting offer -->
                              <button data-bs-toggle="modal" id="show-btn" data-bs-target="#show-code" style="display:none"></button>
                              <div class="modal fade" id="show-code" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog" role="document">
                                  <div class="modal-content">
                                    <div class="modal-header">
                                      <h5 class="modal-title" id="exampleModalLabel">Upostuskoodi</h5>
                                      <!-- <p>Kaikki tähdellä merkityt kentät ovat pakollisia</p> -->
                                      <button type="button" class="close" id="closeDeleteModal" data-bs-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                    </div>
                                    <div class="modal-body">
                                      <h5 class="text-center">Kopioi upotuskoodisi</h5>
                                      <div class="col-12 row pt-4 pb-4">
                                        <textarea name=""  style="height: 200px;" class="form-control" id="code-area"></textarea>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

            <!-- Modal Check before deleting offer -->
            <button data-bs-toggle="modal" id="show-btn" data-bs-target="#show-selected" style="display:none"></button>
            <div class="modal fade" id="show-selected" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Lisää esittelyyn</h5>
                    <!-- <p>Kaikki tähdellä merkityt kentät ovat pakollisia</p> -->
                    <button type="button" class="close" id="close-update-modal" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                    <div class="modal-body">
                    <h5 class="text-center">Tarkista valinnat:</h5>
                    <div class="table-responsive" *ngIf="feedback && feedback.answers && feedback.answers.length > 0">
                        <table class="table align-items-center table-flush">
                            <thead class="thead-light">
                                <tr>
                                    <th>Aika</th>
                                    <th>Palaute</th>
                                </tr>
                            </thead>
                            <tbody class="tbody-light">
                                <tr *ngFor="let answer of selected">
                                    <th>{{answer.createdDate | formateDate}}</th>
                                    <td>{{answer.text}}</td>
                                </tr>
                            </tbody>
    
                        </table>

                        <p class="mt-3">Valitse esittely:</p>
                        <select name="" id="selected-select" class="form-control">
                            <option [value]="undefined" disabled>Valitse esittely</option>
                            <option *ngFor="let s of spinners" value="{{s._id}}">{{s.name}}</option>
                        </select>

                        <div class="col-12">
                            <button class="btn-feedbacker mt-4" (click)="updateSpinner()" >Tallenna</button>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>

            <!-- Modal Check before deleting offer -->
            <button data-bs-toggle="modal" id="questionCertBtn" data-bs-target="#show-certificate-code" style="display:none"></button>
            <div class="modal fade" id="show-certificate-code" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Settifikaatin upostuskoodi</h5>
                    <!-- <p>Kaikki tähdellä merkityt kentät ovat pakollisia</p> -->
                    <button type="button" class="close" id="closeDeleteModal" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                    <div class="modal-body">
                    <h5 class="text-center">Kopioi sertifikaatin upotuskoodisi</h5>
                    <div class="col-12 row pt-4 pb-4">
                        <textarea name=""  style="height: 200px;" class="form-control" [value]="txtValue" id="cert-code-area"></textarea>
                    </div>
                    </div>
                </div>
                </div>
            </div>

                            <!-- Modal Check before deleting offer -->
                            <div class="modal fade" id="delete-cert-nps" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                              <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                  <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Sertifikaatin poistaminen</h5>
                                    <!-- <p>Kaikki tähdellä merkityt kentät ovat pakollisia</p> -->
                                    <button type="button" class="close" id="closeDeleteModal" data-bs-dismiss="modal" aria-label="Close">
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                  <div class="modal-body">
                                    <h5 class="text-center">Poistetaanko sertifikaatti pysyvästi?</h5>
                                    <div class="col-12 row m-0 pt-4 pb-4">
                                     <div class="col-12 text-center">
                                         <button class="btn btn-feedbacker-delete" (click)="removeCert(this.feedbackID)"><i class="far fa-trash-alt me-2"></i>Poista</button>
                                       </div>
                                      <div class="col-12 text-center">
                                        <a class="pointer"  id="close-delete-nps" data-bs-dismiss="modal">Peruuta</a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>