import { AfterContentInit, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';


@Component({
  selector: 'app-create-feedback',
  templateUrl: './create-feedback.component.html',
  styleUrls: ['./create-feedback.component.css']
})
export class CreateFeedbackComponent implements OnInit,AfterContentInit {

  form: FormGroup;
  messageErr: string;
  messageSuccess: string;
  passwordErr:string="";
  shortPwd
  authToken = localStorage.getItem('auth');
  businessID: string = this.route.snapshot.params.businessID;
  feedbackID: string = this.route.snapshot.params.id;
  feedback;
  isEdit;
  btnText = ""

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'auth': `${this.authToken}`  }),
    withCredentials: true,
  };
  constructor( 
    private fb: FormBuilder,
    private router: Router,
    private route:ActivatedRoute,
    private http: HttpClient,
    private authService: AuthService) { }

  ngOnInit() {
    if(this.feedbackID){
      this.isEdit = true;
      this.isEditing();
      this.btnText = "Muokkaa palautekyselyä";
    }
    else{
      this.btnText = "Lisää palautekysely";
    }

    this.form = this.fb.group({
      identifier: [''],

      allowTextFeedback: ['',
      Validators.required],
      question: ['', 
      Validators.required],
      thankYouText: ['', [
        Validators.required,
      ]],
      privacy: ['', Validators.required],
      securityText: [''],
      questionlow: [''],
      questionmid: [''],
      questionhigh: [''],
      textColor: [''],
      backgroundColor: [''],
      type: [{value :'', disabled: this.isEdit}, Validators.required],
    }); 

  }
  ngAfterContentInit(){
    var st = "Vastaus tallennetaan täysin anonyymisti."
    this.form.get('securityText').setValue(st)
  }

  isEditing(){
    this.fetchFeedbackDetails();
  }

  get identifier() { return this.form.get('identifier') };
  get allowTextFeedback() { return this.form.get('allowTextFeedback') };
  get question() { return this.form.get('question') };
  get questionlow() { return this.form.get('questionlow') };
  get questionmid() { return this.form.get('questionmid') };
  get questionhigh() { return this.form.get('questionhigh') };
  get thankYouText() { return this.form.get('thankYouText') };
  get privacy() { return this.form.get('privacy') };
  get securityText() { return this.form.get('securityText') };
  get textColor() { return this.form.get('textColor') };
  get backgroundColor() { return this.form.get('backgroundColor') };
  get type() { return this.form.get('type') };


  fetchFeedbackDetails(){
    this.http.get(environment.FEEDBACK+'/'+this.feedbackID, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {
          this.feedback = res.message[0];

          if(this.feedback.pretemplates){
            (<HTMLInputElement>document.getElementById('allowPre')).checked = true;
            document.getElementById('pre-wrap').style.display = "block";

            for (let i = 1; i < 5; i++) {
              if(this.feedback.pretemplates.low.answers[i-1]){
                (<HTMLInputElement>document.getElementById('low'+i)).value = this.feedback.pretemplates.low.answers[i-1];
              }
              if(this.feedback.pretemplates.mid.answers[i-1]){
                (<HTMLInputElement>document.getElementById('mid'+i)).value = this.feedback.pretemplates.mid.answers[i-1];

              }
              if(this.feedback.pretemplates.high.answers[i-1]){
                (<HTMLInputElement>document.getElementById('high'+i)).value = this.feedback.pretemplates.high.answers[i-1];

              }
              
            }
          }


          this.form = this.fb.group({
            identifier: [this.feedback.identifier, Validators.required],
            question: [this.feedback.question, Validators.required],
            questionlow: [this.feedback.pretemplates.low.question],
            questionmid: [this.feedback.pretemplates.mid.question],
            questionhigh: [this.feedback.pretemplates.high.question],
            thankYouText: [this.feedback.thankYouText, Validators.required],
            privacy: [this.feedback.privacy, Validators.required],
            securityText: [this.feedback.securityText],
            allowTextFeedback: [this.feedback.allowTextFeedback, Validators.required],
            textColor: this.feedback.textColor,
            backgroundColor: this.feedback.backgroundColor,
            type: [this.feedback.type, Validators.required],
          });
          this.form.get('type').disable();

        }
        else {
          var anElm = document.getElementById('notadded');
          anElm.style.display="block";
          console.log('ei ole kyseistä käyttäjää')
        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })

  }

  onSubmit(){
    if(this.feedbackID){
      this.editFeedback();
    }
    else{
      this.newFeedback();
    }
  }
 
  newFeedback() {

    var pres = (<HTMLInputElement>document.getElementById('allowPre')).checked;

    if(this.form.get('allowTextFeedback').value == true && pres){
      alert('Samanaikaisesti ei voi olla tekstipalaute sallittu ja vakiovastaukset');
      return;
    }

    var textColor = this.form.get('textColor').value;
    var backgroundColor= this.form.get('backgroundColor').value;

    if(!textColor){
      this.form.get('textColor').setValue('#000000');
    }

    if(!backgroundColor){
      this.form.get('backgroundColor').setValue('#ffffff');
    }


    console.log('Submitting');
    if (!this.form.valid) {
      return;
    }


    var formAc = document.getElementById('form-container');
    formAc.classList.add("d-none");
    document.getElementById('loader').style.display="block";

    this.passwordErr="";
    this.messageErr="";

    var pretemplates;

    if(pres){
      //create pre
      var low = [];
      var mid = [];
      var high = [];

      for (let i = 1; i < 5; i++) {
        console.log('low'+i)
        var lowa = (<HTMLInputElement>document.getElementById('low'+i)).value;
        var mida = (<HTMLInputElement>document.getElementById('mid'+i)).value;
        var higha = (<HTMLInputElement>document.getElementById('high'+i)).value;

        (lowa != "") ? low.push(lowa) :  console.log('eoli');
        (mida != "") ? mid.push(mida) :  console.log('eoli');
        (higha != "") ? high.push(higha) :  console.log('eoli');
      }

      var lowo  = {
        question: this.form.get('questionlow').value,
        answers: low
      };
      var mido  = {
        question: this.form.get('questionmid').value,
        answers: mid
      };
      var higho  = {
        question: this.form.get('questionhigh').value,
        answers: high
      };

      pretemplates = {
        low: lowo,
        mid: mido,
        high: higho,
      }
    }
    else{
      pretemplates = false
    }


    const request = {
      identifier: this.form.get('identifier').value,
      allowTextFeedback: this.form.get('allowTextFeedback').value,
      question: this.form.get('question').value,
      thankYouText: this.form.get('thankYouText').value,
      securityText: this.form.get('securityText').value,
      textColor: this.form.get('textColor').value,
      backgroundColor: this.form.get('backgroundColor').value,
      type: this.form.get('type').value,
      pretemplates:pretemplates
    }


       this.http.post(environment.FEEDBACK, request, this.httpOptions).subscribe(
        (res: any) => {
          console.log(res);
          if (res.status == 200) {
            alert('Kysely lisätty onnisuneesti')
            this.messageSuccess = res.messgage;
            this.router.navigate(['/'+this.businessID+'/feedback/'+res.message.id])
          } 
          
          else {
            this.messageErr = res.messgage;
            document.getElementById('loader').style.display="none";
            formAc.classList.remove("d-none");

            //loginError = true;
          }
        }, (err: any) => {

          console.log("Error while login :: ", err);
        }
      )

    }
    
    editFeedback() {
          
      var pres = (<HTMLInputElement>document.getElementById('allowPre')).checked;

      if(this.form.get('allowTextFeedback').value == true && pres){
        alert('Samanaikaisesti ei voi olla tekstipalaute sallittu ja vakiovastaukset');
        return;
      }

      var pretemplates;

      if(pres){
        //create pre
        var low = [];
        var mid = [];
        var high = [];
  
        for (let i = 1; i < 5; i++) {
          console.log('low'+i)
          var lowa = (<HTMLInputElement>document.getElementById('low'+i)).value;
          var mida = (<HTMLInputElement>document.getElementById('mid'+i)).value;
          var higha = (<HTMLInputElement>document.getElementById('high'+i)).value;
  
          (lowa != "") ? low.push(lowa) :  console.log('eoli');
          (mida != "") ? mid.push(mida) :  console.log('eoli');
          (higha != "") ? high.push(higha) :  console.log('eoli');
        }
  
        var lowo  = {
          question: this.form.get('questionlow').value,
          answers: low
        };
        var mido  = {
          question: this.form.get('questionmid').value,
          answers: mid
        };
        var higho  = {
          question: this.form.get('questionhigh').value,
          answers: high
        };
  
        pretemplates = {
          low: lowo,
          mid: mido,
          high: higho,
        }
      }
      else{
        pretemplates = false
      }

      
          console.log('Submitting');
          if (!this.form.valid) {
            return;
          }
      
      
          var formAc = document.getElementById('form-container');
          formAc.classList.add("d-none");
          document.getElementById('loader').style.display="block";
      
          this.passwordErr="";
          this.messageErr="";
      
          const request = {
            identifier: this.form.get('identifier').value,
            allowTextFeedback: this.form.get('allowTextFeedback').value,
            question: this.form.get('question').value,
            thankYouText: this.form.get('thankYouText').value,
            securityText: this.form.get('securityText').value,
            textColor: this.form.get('textColor').value,
            backgroundColor: this.form.get('backgroundColor').value,
            feedbackID: this.feedbackID,            
          }
      
             this.http.put(environment.FEEDBACK, request, this.httpOptions).subscribe(
              (res: any) => {
                console.log(res);
                if (res.status == 200) {
                  this.messageSuccess = res.messgage;
                  this.router.navigate(['/'+this.businessID+'/feedback/'+this.feedbackID])
                } 
                
                else {
                  this.messageErr = res.messgage;
                  document.getElementById('loader').style.display="none";
                  formAc.classList.remove("d-none");
      
                  //loginError = true;
                }
              }, (err: any) => {
      
                console.log("Error while login :: ", err);
              }
            )
      
          } 

          preVisibility(e){

            var id = e.target.id;

            var value = (<HTMLInputElement>document.getElementById(id)).checked;
            if(value){
              if(this.form.get('allowTextFeedback').value == 'true'){
                value = false;
                alert('Samanaikaisesti ei voi olla tekstipalaute sallittu ja vakiovastaukset');
                return;
              }
              else{
                document.getElementById('pre-wrap').style.display = "block";
              }
            }
            else{
              document.getElementById('pre-wrap').style.display = "none";

            }
          }



}
