import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';


@Component({
  selector: 'app-register-user',
  templateUrl: './register-user.component.html',
  styleUrls: ['./register-user.component.css']
})
export class RegisterUserComponent implements OnInit {

  form: FormGroup;
  message: string;
  authToken = localStorage.getItem('auth');
  passwordErr = "";
  messageErr = "";
  businessID = this.authService.getTokenData().businessID;
  userID: string = this.route.snapshot.params.id;
  btnText;
  user = [];

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'auth': `${this.authToken}` }),
    withCredentials: true,
  };

  constructor( 
    private fb: FormBuilder,
    private router: Router,
    private http: HttpClient,
    private route:ActivatedRoute,
    private authService: AuthService) { }


  ngOnInit(): void {
    this.form = this.fb.group({
      email: ['', [
        Validators.email, 
        Validators.required
      ]],

      firstName: ['', Validators.required],
      lastName: ['', Validators.required],

    });

    if(this.userID){
      this.btnText = "Muokkaa käyttäjää"
      this.fetchUserData();
    }
    else{
      this.btnText = "Lisää käyttäjä"
    }
  }

  get email() { return this.form.get('email') };
  get firstName() { return this.form.get('firstName') };
  get lastName() { return this.form.get('lastName') };


  fetchUserData(){

    this.http.get(environment.USER, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {
          this.user = res.message;
          var m = res.message[0]

          this.form = this.fb.group({
            firstName: [m.firstName, Validators.required],
            lastName: [m.lastName, Validators.required],
            email: [m.email, Validators.required],
          });
        }
        else {
          console.log('No user')
        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })
  }

  onSubmit(){
    if(this.userID){
      this.editUser();
    }
    else{
      this.addNewUser()
    }
  }

  editUser(){
    var t = false;

    console.log('Submitting');
    if (!this.form.valid) {
      console.log('Form not valid. Please check that fields are correctly filled in');
      return;
    }

    var formAc = document.getElementById('form-container');
    formAc.classList.add("d-none");
    document.getElementById('loader').style.display="block";


    var req = {
      email: this.form.get('email').value,
      userName: this.form.get('email').value,
      firstName: this.form.get('firstName').value,
      lastName: this.form.get('lastName').value,
    }

      this.http.put(environment.USER, req, this.httpOptions).subscribe(
        (res: any) => {
          if (res.status == 200) {
            alert('Muokkaus onnistui. Paina OK siirtyäksesi katsomaan tietoja');
            this.router.navigate(['/'+this.businessID+'/company-details']);
          } else {
            alert('Wopsaa virhe tapahtui muokastessa käyttäjää')
            document.getElementById('loader').style.display="none";
            formAc.classList.remove("d-none");
          }
        }, (err: any) => {

          console.log("Error while login :: ", err);
        }
      )

  }

  addNewUser() {

    var t = false;

    console.log('Submitting');
    if (!this.form.valid) {
      console.log('Form not valid. Please check that fields are correctly filled in');
      return;
    }

    var formAc = document.getElementById('form-container');
    formAc.classList.add("d-none");
    document.getElementById('loader').style.display="block";


    var req = {
      email: this.form.get('email').value,
      userName: this.form.get('email').value,
      firstName: this.form.get('firstName').value,
      lastName: this.form.get('lastName').value,
    }

      this.http.post(environment.USER_REGISTER_END_POINT, req, this.httpOptions).subscribe(
        (res: any) => {
          console.log("Register success");
          if (res.status == 200) {
            alert('Uusi käyttäjä on rekisteröity onnistuneesti! Uuden käyttäjän sähköpostiin on lähetetty aktivointilinkki.');
            this.router.navigate(['/'+this.businessID+'/company-details']);
          } else {
            alert('Virhe lisätessä käyttäjää')
            document.getElementById('loader').style.display="none";
            formAc.classList.remove("d-none");
          }
        }, (err: any) => {

          console.log("Error while login :: ", err);
        }
      )

    }

}
