import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';

@Component({
  selector: 'app-feedback-list',
  templateUrl: './feedback-list.component.html',
  styleUrls: ['./feedback-list.component.css']
})
export class FeedbackListComponent implements OnInit {

  errMessage = "";
  successMessage = "";
  businessID: string = this.route.snapshot.params.businessID;
  feedbacks = [];
  authToken = localStorage.getItem('auth');
  feedbackValues;
  order;
  type = 'none';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'auth': `${this.authToken}`  }),
    withCredentials: true,
  };

  constructor(
    private router: Router,
    private http: HttpClient,
    private route:ActivatedRoute,
    private authService: AuthService) {
      this.authService.validateBusiness(this.businessID)

     }

  ngOnInit(): void {
    this.getFeedbacks();
    this.getFeedbackValues()
    this.fetchOrderDetails();
  }

  fetchOrderDetails() {

    this.http.get(environment.ORDER_DATA, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {
          this.order = res.message;
          console.log(this.order);

        }
        else {

        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })


  }



  getFeedbacks() {

    this.http.get(environment.FEEDBACKS, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {
          this.feedbacks = res.message;
          console.log(this.feedbacks);

        }
        else {
          this.feedbacks = [];
          console.log(this.feedbacks.length)
          

        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })


  }

  getFeedbackValues() {

    this.http.get(environment.FEEDBACK_DATA, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {
          this.feedbackValues = res.message;



        }
        else {
          this.feedbackValues = res.message;
        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })


  }

  addType(data,e){

    console.log(e)
    if(e.target.checked){
      this.type = data.type;
    }
    else{

    var elms = document.getElementsByClassName('nset-input');
    var is = false;
    for (let i = 0; i < elms.length; i++) {
      const elm = elms[i];


      var checked = (<HTMLInputElement>document.getElementById(elm.id)).checked;

      if(checked){
        is = true
      }

      if(i+1 == elms.length){
        if(is == true){

        }
        else{
          this.type = 'none'
        }
      }
      
    }
   }

    
  }

  compare(){
    var elms = document.getElementsByClassName('nset-input');
    var arr = [];
    var type = false;

    for (let i = 0; i < elms.length; i++) {
      const elm = elms[i];


      var checked = (<HTMLInputElement>document.getElementById(elm.id)).checked;

      if(checked){
        arr.push(elm.id);
      }

      if(i+1 == elms.length){
        if(arr.length > 1 || arr.length > 4){
          JSON.stringify(arr);
          this.router.navigate(['/'+this.businessID+'/compare-feedbacks/'+ arr])
        }
        else{
          alert('Vertailussa täytyy olla vähintään 1 ja enintään 4');
          return;
        }
      }
      
    }
  }

}
