
<div class="full-wrap w-100">
    <div class="col-12 ">
        <div class="d-flex justify-content-center">
            <h1 class="d-flex mb-0 pt-4">{{typeText}}</h1>
        </div>
        <div class="d-flex justify-content-center">
          <p class=" d-flex text-center mb-1">{{company}}</p>
        </div>
       <div class="d-flex justify-content-center">
        <div class="d-flex circle-wrap mt-4" *ngIf="this.feedback">
        
            <div class="circle">
              
              <div class="mask full" id="mask-full-fill">
                <div class="fill" id="mask-full"></div>
              </div>
             
              <div class="mask half">
                <div class="fill" id="mask-half"></div>
              </div>
              
              <div class="inside-circle">
                {{feedback.value}}
              </div>
              
            </div>
    </div>
       </div>
       <div class="d-flex justify-content-center pt-4">
           <img  src="../../assets/images/moodysetlogo_b.png" width="40%">
        </div>



</div>
