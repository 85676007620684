import { AfterContentInit, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';

@Component({
  selector: 'app-create-calendar',
  templateUrl: './create-calendar.component.html',
  styleUrls: ['./create-calendar.component.css']
})
export class CreateCalendarComponent implements OnInit {

  form: FormGroup;
  messageErr: string;
  messageSuccess: string;
  passwordErr:string="";
  shortPwd
  authToken = localStorage.getItem('auth');
  businessID: string = this.route.snapshot.params.businessID;
  calendarID: string = this.route.snapshot.params.id;
  calendar;
  isEdit;
  btnText = ""


  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'auth': `${this.authToken}`  }),
    withCredentials: true,
  };
  constructor( 
    private fb: FormBuilder,
    private router: Router,
    private route:ActivatedRoute,
    private http: HttpClient,
    private authService: AuthService) { }


  ngOnInit(): void {

    if(this.calendarID){
      this.isEdit = true;
      this.isEditing();
      this.btnText = "Muokkaa kalenteria";
    }
    else{
      this.btnText = "Lisää kalenteri";
    }

    this.form = this.fb.group({
      identifier: [''],

      allowToSeeReserved: ['',
      Validators.required],
      allowMultipleReservations: ['',
      Validators.required],
      thankYouText: ['', [
        Validators.required,
      ]],
      privacy: ['', Validators.required],
      reminder: ['', Validators.required],
      forcePayment: ['', Validators.required],
    }); 
  }

  get identifier() { return this.form.get('identifier') };
  get allowMultipleReservations() { return this.form.get('allowMultipleReservations') };
  get allowToSeeReserved() { return this.form.get('allowToSeeReserved') };
  get thankYouText() { return this.form.get('thankYouText') };
  get privacy() { return this.form.get('privacy') };
  get reminder() { return this.form.get('reminder') };
  get forcePayment() { return this.form.get('forcePayment') };

  isEditing(){
    this.fetchCalendarDetails();
  }

  fetchCalendarDetails(){
    this.http.get(environment.CALENDAR+'/'+this.calendarID, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {
          this.calendar = res.message[0];


          this.form = this.fb.group({
            identifier: [this.calendar.identifier, Validators.required],
            thankYouText: [this.calendar.thankYouText, Validators.required],
            privacy: [this.calendar.privacy, Validators.required],
            forcePayment: [this.calendar.forcePayment, Validators.required],
            reminder: [this.calendar.reminder, Validators.required],
            allowToSeeReserved: [this.calendar.allowToSeeReserved, Validators.required],
            allowMultipleReservations: [this.calendar.allowMultipleReservations, Validators.required],
          });

        }
        else {
          var anElm = document.getElementById('notadded');
          anElm.style.display="block";
          console.log('ei ole kyseistä käyttäjää')
        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })

  }
  onSubmit(){
    if(this.calendarID){
      this.editCalendar();
    }
    else{
      this.newCalendar();
    }
  }

  newCalendar() {



    var formAc = document.getElementById('form-container');
    formAc.classList.add("d-none");
    document.getElementById('loader').style.display="block";

    var fp = false;
    var ar = false;
    var amr = false

    if(this.form.get('forcePayment').value == 'true'){
      fp = true;
    }
    if(this.form.get('allowToSeeReserved').value == 'true'){
      ar = true;
    }
    if(this.form.get('allowMultipleReservations').value == 'true'){
      amr = true;
    }
    const request = {
      identifier: this.form.get('identifier').value,
      allowToSeeReserved: ar,
      thankYouText: this.form.get('thankYouText').value,
      reminder: this.form.get('reminder').value,
      forcePayment: fp,
      allowMultipleReservations: amr,
    }


       this.http.post(environment.CALENDAR, request, this.httpOptions).subscribe(
        (res: any) => {
          console.log(res);
          if (res.status == 200) {
            alert('Kalenteri lisätty onnistuneesti lisätty onnisuneesti')
            this.messageSuccess = res.messgage;
            this.router.navigate(['/'+this.businessID+'/calendar/'+res.message.id])
          } 
          
          else {
            this.messageErr = res.messgage;
            document.getElementById('loader').style.display="none";
            formAc.classList.remove("d-none");

            //loginError = true;
          }
        }, (err: any) => {

          console.log("Error while login :: ", err);
        }
      )

    }
    
    editCalendar() {
          
      
          var formAc = document.getElementById('form-container');
          formAc.classList.add("d-none");
          document.getElementById('loader').style.display="block";
      
      
          var fp = false;
          var ar = false;
          var amr = false
      
          if(this.form.get('forcePayment').value == 'true'){
            fp = true;
          }
          if(this.form.get('allowToSeeReserved').value == 'true'){
            ar = true;
          }
          if(this.form.get('allowMultipleReservations').value == 'true'){
            amr = true;
          }
          const request = {
            identifier: this.form.get('identifier').value,
            allowToSeeReserved: ar,
            thankYouText: this.form.get('thankYouText').value,
            reminder: this.form.get('reminder').value,
            forcePayment: fp,
            allowMultipleReservations: amr,
          }
      
             this.http.put(environment.CALENDAR, request, this.httpOptions).subscribe(
              (res: any) => {
                console.log(res);
                if (res.status == 200) {
                  this.messageSuccess = res.messgage;
                  this.router.navigate(['/'+this.businessID+'/calendar/'+this.calendarID])
                } 
                
                else {
                  this.messageErr = res.messgage;
                  document.getElementById('loader').style.display="none";
                  formAc.classList.remove("d-none");
      
                  //loginError = true;
                }
              }, (err: any) => {
      
                console.log("Error while login :: ", err);
              }
            )
      
          } 



}
