import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';
import { JsonPipe } from '@angular/common';

@Component({
  selector: 'app-feedback-email-answer',
  templateUrl: './feedback-email-answer.component.html',
  styleUrls: ['./feedback-email-answer.component.css']
})
export class FeedbackEmailAnswerComponent implements OnInit {

  errMessage = "";
  successMessage = "";
  token:string = this.route.snapshot.params.token;
  businessID: string = this.route.snapshot.params.businessID;
  value: string = this.route.snapshot.params.value;
  myUUID;
  feedback;
  cookie;
  allowAnswer = false;
  feedbackGiven = false;
  currentQustion;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    withCredentials: false,
  };

  constructor(
    private router: Router,
    private http: HttpClient,
    private route:ActivatedRoute,
    private authService: AuthService) {
      this.authService.validateBusiness(this.businessID)

     }


  ngOnInit(): void {
    this.checkCookie();
  }

  createCookie(token){
    var arr = [];
    arr.push(token);
    var str = JSON.stringify(arr)
    
    const d = new Date();
    d.setTime(d.getTime() + (7*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = 'answered' + "=" + str + ";" + expires + ";path=/";
  }
  
  checkCookie(){
    let name = 'answered' + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        this.cookie = c.substring(name.length, c.length);

        var parsed = JSON.parse(this.cookie);

        var is_answerd = false;

        for (let i = 0; i < parsed.length; i++) {
          const token = parsed[i];

          if(token == this.token){
            is_answerd = true;
          }

          if(i+1 == parsed.length && is_answerd == false){
            this.allowAnswer = true;
            this.createAnswer();
          }
          else{
            this.allowAnswer = false;
          }
          
        }
        console.log(this.cookie);
        if(!this.cookie){
          this.createAnswer();
        }
      }
    }
    if(!document.cookie){
      var cookie = this.getCookies('answered');
      if(!cookie){
        this.allowAnswer = true;
        this.createAnswer();
      }
    }
    
  }

  getFeedback() {
    var req = {
      businessID: this.businessID,
      token: this.token,
    }

    this.http.post(environment.GET_FEEDBACK_FOR_NO_AUTH,req, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {

          this.feedback = res.message[0];

          
          /* this.waitStyle(); */

        }
        else {
          var anElm = document.getElementById('notadded');
          anElm.style.display="block";
          console.log('ei ole kyseistä käyttäjää')
        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })

  }

  createAnswer(){
    console.log(this.cookie)

      var req = {
        businessID: this.businessID,
        token: this.token,
        value: parseInt(this.value)
      }

      console.log(req);

      this.http.post(environment.SUBMIT_FEEDBACK_NO_AUTH,req, this.httpOptions).subscribe(
        (res: any) => {
          if(res.status == 200 ) {


            var cookie = this.getCookies('answered');
            if(cookie == false){
              this.createCookie(this.token);
            }
            else{
              var arr = JSON.parse(this.cookie);
              arr.push(this.token);
              var str = JSON.stringify(arr);
              document.cookie = "answered ="+str;
            }
            console.log(cookie);
            this.feedbackGiven = true;



          }
          else {
            console.log('Could not update', res)
          }
          
        }, (err: any) => {
    
          console.log("Error while finding status :: ", err);
        })
    
  }

  getCookies(name) {
    var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) {
      console.log(match[2]);
      return true;
    }
    else{
         console.log('--something went wrong---');
         return false;
    }
  }

/*   waitStyle() {
    var that = this;

    setTimeout(() =>{
      that.styleWanted();
      
    },400)

  } */

/*   styleWanted() {
    //change question color
    document.getElementById('main-text').style.color = this.feedback.textColor;
    //change secure text color
    document.getElementById('secure-text').style.color = this.feedback.textColor;
    
    if(document.getElementById('pre-text-label-pos')){
      document.getElementById('pre-text-label-pos').style.color = this.feedback.textColor;
    }
    
    //chanhe thaks text color
    //document.getElementById('thankyou-text').style.color = this.feedback.textColor;
    //change backgroundcolor
    if(this.spot != 'pos'){
      console.log(this.feedback)
      document.getElementById('full-area').style.backgroundColor = this.feedback.backgroundColor;

    }
    else{
      document.getElementById('full-area-pos').style.backgroundColor = this.feedback.backgroundColor;

    }
    var arr = document.getElementsByClassName('text-feedbacker');

    for (let i = 0; i < arr.length; i++) {
      const elm = arr[i];
      
      var text = (<HTMLParagraphElement>elm)
      text.style.color = this.feedback.textColor;
      
    }
  } */

}
