import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CustomHttpInterceptor } from './interceptors/custom-http.interceptor';
import { LocationStrategy,HashLocationStrategy } from '@angular/common';
import { FlatpickrModule } from 'angularx-flatpickr';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import '../../node_modules/flatpickr/dist/flatpickr.css';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormateDatePipe } from './pipes/formate-date.pipe';
import { LoginComponent } from './login/login.component';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { NotAuthHeaderComponent } from './not-auth-header/not-auth-header.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { UserHomeComponent } from './user-home/user-home.component';
import { ActivateUserComponent } from './activate-user/activate-user.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { UserHeaderComponent } from './user-header/user-header.component';
import { CreateFeedbackComponent } from './create-feedback/create-feedback.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { FeedbackListComponent } from './feedback-list/feedback-list.component';
import { FeedbackDetailsComponent } from './feedback-details/feedback-details.component';
import { CompanyDetailsComponent } from './company-details/company-details.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { RegisterUserComponent } from './register-user/register-user.component';
import { EditCompanyComponent } from './edit-company/edit-company.component';
import { OrderComponent } from './order/order.component';
import { EditBusinessDetailsComponent } from './edit-business-details/edit-business-details.component';
import { SelectBusinessComponent } from './select-business/select-business.component';
import { CreateFeedbacCampaignComponent } from './create-feedbac-campaign/create-feedbac-campaign.component';
import { FeedbackEmailAnswerComponent } from './feedback-email-answer/feedback-email-answer.component';
import { DevicesComponent } from './devices/devices.component';
import { EditDeviceComponent } from './edit-device/edit-device.component';
import { DeviceHubComponent } from './device-hub/device-hub.component';
import { DeviceComponent } from './device/device.component';
import { BusinessCertificatesComponent } from './business-certificates/business-certificates.component';
import { CompareFeedbacksComponent } from './compare-feedbacks/compare-feedbacks.component';
import { SpinnersComponent } from './spinners/spinners.component';
import { BusinessSpinnersComponent } from './business-spinners/business-spinners.component';
import { CreateSpinnerComponent } from './create-spinner/create-spinner.component';
import { SpinnerDetailsComponent } from './spinner-details/spinner-details.component';
import { CalendarComponent } from './calendar/calendar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BookingComponent } from './booking/booking.component';
import { CustomerCalendarComponent } from './customer-calendar/customer-calendar.component';
import { PaymentRegisterComponent } from './payment-register/payment-register.component';
import { ReservationsComponent } from './reservations/reservations.component';
import { CreateCalendarComponent } from './create-calendar/create-calendar.component';
import { PaymentComponent } from './payment/payment.component';
import { CalendarsComponent } from './calendars/calendars.component';
import { HomeComponent } from './home/home.component';
import { ModelsComponent } from './models/models.component';
import { SingleModelComponent } from './single-model/single-model.component';
import { TypesComponent } from './types/types.component';
import { CreateTypeComponent } from './create-type/create-type.component';

@NgModule({
  declarations: [
    AppComponent,
    FormateDatePipe,
    LoginComponent,
    AdminLoginComponent,
    NotAuthHeaderComponent,
    AdminDashboardComponent,
    UserHomeComponent,
    ActivateUserComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    UserHeaderComponent,
    CreateFeedbackComponent,
    FeedbackComponent,
    FeedbackListComponent,
    FeedbackDetailsComponent,
    CompanyDetailsComponent,
    UserDetailsComponent,
    RegisterUserComponent,
    EditCompanyComponent,
    OrderComponent,
    EditBusinessDetailsComponent,
    SelectBusinessComponent,
    CreateFeedbacCampaignComponent,
    FeedbackEmailAnswerComponent,
    DevicesComponent,
    EditDeviceComponent,
    DeviceHubComponent,
    DeviceComponent,
    BusinessCertificatesComponent,
    CompareFeedbacksComponent,
    SpinnersComponent,
    BusinessSpinnersComponent,
    CreateSpinnerComponent,
    SpinnerDetailsComponent,
    CalendarComponent,
    BookingComponent,
    CustomerCalendarComponent,
    PaymentRegisterComponent,
    ReservationsComponent,
    CreateCalendarComponent,
    PaymentComponent,
    CalendarsComponent,
    HomeComponent,
    ModelsComponent,
    SingleModelComponent,
    TypesComponent,
    CreateTypeComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgbModalModule,
    CommonModule,
    BrowserAnimationsModule,
    FlatpickrModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    NgbModule,
  ],
  providers: [
    CustomHttpInterceptor,
    {provide: LocationStrategy, useClass: HashLocationStrategy},

    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
