<app-user-header></app-user-header>


<div class="wrapperi">

  <div class="bg-container header pnset-6 container-fluid pt-4 ps-4 pe-4">
    <div class="col-12 row pb-4">
        <div class="col-12 col-md-7 page-title">
            <p class="page-title h2 text-white d-inline-block mb-0">{{btnText}}</p> <br>
            <p class="desc-text text-white">{{btnText}} täyttämällä alla olevat tiedot.</p>
        </div>
        <div class="col">
            <nav class="d-none d-md-inline-block ml-md-4">
                <ol class="breadcrumb breadcrumb-links breadcrumb-dark">
                    <li class="breadcrumb-item">
                        <a routerLink="/{{businessID}}/users/home">Koti</a>
                    </li>
                    <li class="breadcrumb-item">
                      <a routerLink="/{{businessID}}/calendars">Kalenterit</a>
                    </li>
                    <li class="breadcrumb-item active">
                        <a href="#">{{btnText}}</a>
                    </li>
                </ol>
            </nav>  
        </div>
    </div>
    <div class="col-12 row">

    </div>
  </div>


  <div class="col-12 p-0 row m-0">
    <div class="col-3"></div>
    <div class="col-12 col-md-6 p-0 input-cover mt-4 p-4">
      <div class="col-12 p-0 mt-4">
        <div class="alert alert-warning" *ngIf="messageErr">
          <p class="text-center">Samalla tunnisteella löyti jo kalenteri</p>
        </div>
      </div>

      <div id="loader" class="loader-container mt-4" style="display:none">
        <div class="loader mx-auto"></div>
      </div>

      <div style="display: block;" id="form-container" class="d-flex flex-row justify-content-center ml-4">
        <form class="flex-row add-pet-form" [formGroup]="form"
        (ngSubmit)="onSubmit()">
          <!-- Sähköpostiosoite ja puhelinnumero -->
          <div class="col-12 row m-0 pt-2">

            <div class="form-floating mb-2 col-12  p-0">
              <input type="text" placeholder="Palautteen nimi" formControlName="identifier"  class="form-control">
              <label for="identifier">Kalenterin nimi (pakollinen)</label>
            </div>
          </div>
        <!-- Sähköpostiosoite ja puhelinnumero -->

          <!-- Tekstipalaute -->
          <div class="col-12 row m-0 pt-2">
            <div class="form-floating mb-2  col-12 col-md-6 p-0">
              <select type="text" placeholder="Varatut saa nähdä" formControlName="allowToSeeReserved" id="allowToSeeReserved" class="form-control" [class.invalid-input]="allowToSeeReserved.errors && allowToSeeReserved.touched" required>
                  <option></option>
                  <option value="true">Kyllä</option>
                  <option value="false">Ei</option>
              </select>
              <label for="allowToSeeReserved">Varatut saa nähdä (pakollinen)</label>
              <div class="my-alerts" *ngIf="allowToSeeReserved.invalid && allowToSeeReserved.touched">
                <p class="mt-2" *ngIf="allowToSeeReserved.errors.required">Varatut saa nähdä on pakollinen</p>
                <p *ngIf="allowToSeeReserved.errors.allowToSeeReserved"> Vaaditaan määrätty Varatut saa nähdä</p>
              </div>
            </div>

            <div class="form-floating mb-2 ps-md-2 col-12 col-md-6 p-0">
              <select type="text" placeholder="Salli monta samanaikaista varausta" formControlName="allowMultipleReservations" id="allowMultipleReservations" class="form-control" [class.invalid-input]="allowMultipleReservations.errors && allowMultipleReservations.touched" required>
                  <option></option>
                  <option value="false">Ei</option>
                  <option value="true">Kyllä</option>
                  <!-- <option value="thumb">Peukalo</option> -->
              </select>
              <label class="ps-md-4" for="allowMultipleReservations">Salli monta samanaikaista varausta (pakollinen)</label>
              <div class="my-alerts" *ngIf="allowMultipleReservations.invalid && allowMultipleReservations.touched">
                <p class="mt-2" *ngIf="allowMultipleReservations.errors.required">Salli monta samanaikaista varausta on pakollinen</p>
                <p *ngIf="allowMultipleReservations.errors.allowMultipleReservations"> Vaaditaan määrätty Palautteen tyyppi</p>
              </div>
            </div>

          </div>

        <!-- Kiitosviesti -->
        <div class="col-12 row m-0 pt-2">
            <div class="form-floating mb-2  col-12 p-0">
                <select type="text" placeholder="Maksu pakollinen" formControlName="forcePayment" id="forcePayment" class="form-control" [class.invalid-input]="forcePayment.errors && forcePayment.touched" required>
                    <option></option>
                    <option value="false">Ei</option>
                    <option value="true">Kyllä</option>
                    <!-- <option value="thumb">Peukalo</option> -->
                </select>
                <label class="ps-md-4" for="forcePayment">Maksu pakollinen (pakollinen)</label>
                <div class="my-alerts" *ngIf="forcePayment.invalid && forcePayment.touched">
                  <p class="mt-2" *ngIf="forcePayment.errors.required">Maksu pakollinen on pakollinen</p>
                  <p *ngIf="forcePayment.errors.forcePayment"> Vaaditaan määrätty Palautteen tyyppi</p>
                </div>
            </div>
          </div>

        <!-- Kiitosviesti -->
        <div class="col-12 row m-0 pt-2">
            <div class="form-floating mb-2  col-12 p-0">
                <select type="text" placeholder="Asiakasta muistutetaan" formControlName="reminder" id="reminder" class="form-control" [class.invalid-input]="reminder.errors && reminder.touched" required>
                    <option></option>
                    <option value="email">Sähköpostilla</option>
                    <option value="sms">Tekstiviestillä</option>
                    <option value="smsEmail">Sähköpostilla ja tekstiviestillä</option>
                </select>
                <label class="ps-md-4" for="reminder">Asiakasta muistutetaan (pakollinen)</label>
                <div class="my-alerts" *ngIf="reminder.invalid && reminder.touched">
                  <p class="mt-2" *ngIf="reminder.errors.required">Asiakasta muistutetaan on pakollinen</p>
                  <p *ngIf="reminder.errors.reminder"> Vaaditaan määrätty Palautteen tyyppi</p>
                </div>
            </div>
          </div>


        <!-- Kiitosviesti -->
        <div class="col-12 row m-0 pt-2">
            <div class="form-floating mb-2  col-12 p-0">
                <textarea style="min-height:125px"  placeholder="Kiitosviesti" formControlName="thankYouText" id="thankYouText" class="form-control" [class.invalid-input]="thankYouText.errors && thankYouText.touched" required></textarea>
                <label for="thankYouText">Kiitosviesti (pakollinen)</label>
                <div class="my-alerts" *ngIf="thankYouText.invalid && thankYouText.touched">
                <p class="mt-2" *ngIf="thankYouText.errors.required">Kiitosviesti on pakollinen</p>
                <p *ngIf="thankYouText.errors.thankYouText"> Vaaditaan määrätty Kiitosviesti</p>
                </div>
            </div>
          </div>



            <!-- Ehtojen hyväksyminen -->
            <div class="form-group">
                <div class="row col-12 d-inline-flex">
                    <div class="form-group privacy-text-wrap mb-2 col-10 col-md-6 p-0">
                    <label type="text" readonly class="body2b-b form-control-plaintext m-0 p-0 privacy-text">Olen lukenut palvelun <a routerLink="/termsofservice"> käyttöehdot </a>sekä hyväksyn <a routerLink="/privacypolicy">tietosuojaselosteen</a>*</label>
                    </div>
                    <div class="form-group mx-sm-3 mb-2  col-1 p-0">
                    <input style="width:20px; height:20px" type="checkbox" formControlName="privacy" id="privacy"  [class.invalid-input]="privacy.errors && privacy.touched" required>
                    <div class="my-alerts w-50" *ngIf="privacy.invalid && privacy.touched">
                        <p class="mt-2" *ngIf="privacy.errors.required">Käyttöehdot on hyväksyttävä</p>
                        <p *ngIf="privacy.errors.privacy"> Vaaditaan määrätty käyttöehdot</p>
                    </div>
                    </div>
                </div>
            </div>
          
                
            <div class="form-inline mt-2">
              <div class="form-group mb-2 flex-row">
                <button [disabled]="form.invalid" class="btn btn-feedbacker w-100"><i class="fas fa-sign-in-alt me-2"></i>{{btnText}}</button>
              </div>
            </div>
          </form>

        </div>
      </div>
      <div class="col-3"></div>
</div>