<app-user-header></app-user-header>
<div class="" *ngIf="types && types.length > 0; else show_not">
<div class="bg-container header pnset-6 container-fluid pt-4 ps-4 pe-4">
  <div class="col-12 row pb-4">
      <div class="col-12 col-md-7 page-title">
          <p class="page-title h2 text-white d-inline-block mb-0">Tyypit</p><br>
            <button class="btn btn-feedbacker-delete" (click)="deleteFlow()">Poista</button>
      </div>
      <div class="col">
          <nav class="d-none d-md-inline-block ml-md-4">
              <ol class="breadcrumb breadcrumb-links breadcrumb-dark">
                  <li class="breadcrumb-item">
                      <a routerLink="/{{businessID}}/users/home">Koti</a>
                  </li>
                  <li class="breadcrumb-item active">
                      <a href="#">Tyypit</a>
                  </li>
              </ol>
          </nav>  
      </div>
  </div>
  <div class="col-12 row m-0">
      <div class="col-xl-3 col-md-6">
          <div class="card card-stats p-4">
              <div class="row">
                  <div class="col">
                      <h5 class="card-title text-uppercase text-muted mb-0">Lukumäärä</h5>
                      <span class="h2 font-weight-bold mb-0">{{types.length}}</span>
                  </div>
                  <div class="col-auto">
                      <div class="nset-icon icon-shape bg-gradient-red shadow">

                      </div>
                  </div>
                  <p class="mt-3 mb-0 text-sm">
                      <span class="text-success me-2">10%</span>
                      <span class="text-nowrap">Yhteensä</span>
                  </p>
              </div>
          </div>
      </div>
<!--       <div class="col-xl-3 col-md-6">
          <div class="card card-stats p-4">
              <div class="row">
                  <div class="col">
                      <h5 class="card-title text-uppercase text-muted mb-0">Vastausaste</h5>
                      <span class="h2 font-weight-bold mb-0">{{feedbackValues.company.answer_rate.toFixed(2)*100}}</span>
                  </div>
                  <div class="col-auto">
                      <div class="nset-icon icon-shape bg-gradient-orange shadow">

                      </div>
                  </div>
                  <p class="mt-3 mb-0 text-sm">
                      <span class="text-success me-2">10%</span>
                      <span class="text-nowrap">Yhteensä</span>
                  </p>
              </div>
          </div>
      </div>
      <div class="col-xl-3 col-md-6">
        <div class="card card-stats p-4">
            <div class="row">
                <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">NPS</h5>
                    <span class="h2 font-weight-bold mb-0">{{feedbackValues.company.nps.toFixed(2) * 100}}</span>
                </div>
                <div class="col-auto">
                    <div class="nset-icon icon-shape bg-gradient-green shadow">

                    </div>
                </div>
                <p class="mt-3 mb-0 text-sm">
                    <span class="text-success me-2">10%</span>
                    <span class="text-nowrap">Yhteensä</span>
                </p>
            </div>
        </div>
    </div>
    <div class="col-xl-3 col-md-6">
        <div class="card card-stats p-4">
            <div class="row">
                <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">KESKIARVO</h5>
                    <span class="h2 font-weight-bold mb-0">{{feedbackValues.company.average.toFixed(2)}}</span>
                </div>
                <div class="col-auto">
                    <div class="nset-icon icon-shape bg-gradient-info shadow">

                    </div>
                </div>
                <p class="mt-3 mb-0 text-sm">
                    <span class="text-success me-2">10%</span>
                    <span class="text-nowrap">Yhteensä</span>
                </p>
            </div>
        </div>
    </div> -->
  </div>
</div>

<div class="col-12 ps-4 mt-4">
    <p class="mb-0">Linkki:</p>
    <a target="_blank" href="{{link}}">{{link}}</a>
</div>

<div class="col-12 p-0 p-md-4">
  <div class="card ">
      <div class="card-header">
          <div class="row-align-items-center">
              <div class="col">
                  <h6 class="text-dark text-uppercase ls-1 mb-1">Flowt</h6>
                  <h5 class="h3 text-black mb-0">Tiedot</h5>
              </div>
          </div>
          <div class="table-responsive" *ngIf="types.length > 0;else no_answers_content">
              <table class="table align-items-center table-flush">
                  <thead class="thead-light">
                      <tr>
                          <th>Nimi</th>
                          <th>Lopputulemat</th>
                          <th>Lisää</th>
                          <th>Tiedot</th>
                      </tr>
                  </thead>
                  <tbody class="tbody-light">
                      <tr *ngFor="let data of types">
                          <th class="pointer" >{{data.question}}</th>
                          <th class="pointer" >{{data.endcomes.length}}</th>
                          <td><button class="btn-feedbacker" (click)="selectTree(data)" data-bs-toggle="modal" data-bs-target="#add-endcome" >Lisää</button></td>
                          <td><button class="btn-feedbacker"(click)="selectTree(data)" data-bs-toggle="modal" data-bs-target="#show-endcome">Tiedot</button></td>
                      </tr>
                  </tbody>

              </table>
          </div>
          <ng-template #no_answers_content>
              <div class="col-12 row m-0">
                  <div class="col-3"></div>
                  <div class="col-12 col-md-6">
                      <div class="alert alert-warning">
                          <p class="normal-text text-center">Et ole luonut flowita.</p>
                      </div>
                  </div>
                  <div class="col-3"></div>
              </div>
          </ng-template>
      </div>
      
  </div>
</div>
</div>
<ng-template #show_not>
    <div class="col-12 row ms-0 me-0 mt-4">
        <div class="col-3"></div>
        <div class="col-6">
            <div class="alert alert-warning">
                <p class="text-center">Sinulla ei ole flowta lisättynä lisää se tästä:</p>
                <input type="file" accept="xml" id="file-input" (change)="readFile($event)">
            </div>
        </div>
        <div class="col-3"></div>
    </div>
</ng-template>


    <!-- Modal Check before deleting offer -->
    <div class="modal fade" id="add-endcome" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Lisää lopputulema</h5>
            <!-- <p>Kaikki tähdellä merkityt kentät ovat pakollisia</p> -->
            <button type="button" class="close" id="closeDeleteModal" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <h5 class="text-center">Täytä lomake</h5>
            <div style="display: block;" id="form-container" class="">

                <form class="flex-row add-pet-form" [formGroup]="form"
                (ngSubmit)="addType()">

                <!-- Kiitosviesti -->
                <div class="row">
                    <div class="col-sm-12 mt-4">
                    <div class="input-group input-group-sm">
                        <input (click)="preVisibility($event)" type="checkbox" id="w-contact" />
                        <label class="checkbox-inline ms-4" id="lbProcessingConsultant" for="w-contact">Haluan kontaktoinnin</label>
                    </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-12 mt-4">
                    <div class="input-group input-group-sm">
                        <input (click)="preVisibility($event)" type="checkbox" id="w-pref" />
                        <label class="checkbox-inline ms-4" id="lbProcessingConsultant" for="w-pref">Haluan antaa suosituksia</label>
                    </div>
                    </div>
                </div>

                <div class="" id="pref" style="display: none;">
    
                  <!-- Sähköposti -->
                  <div class="col-12 row m-0 pt-2">
        
                    <div class="form-floating mb-2  col-12 col-md-12 p-0">
                        <input type="text" placeholder="Nimi" formControlName="title" id="title" class="form-control" [class.invalid-input]="title.errors && title.touched" required>
                        <label for="title">*Nimi</label>
                        <div class="my-alerts" *ngIf="title.invalid && title.touched">
                        <p class="mt-2" *ngIf="title.errors.required">title on pakollinen</p>
                        <p *ngIf="title.errors.title"> Vaaditaan kunnollinen firsttitle</p>
                        </div>
                    </div>

                    <div class="form-floating mb-2  col-12 col-md-12 p-0">
                        <textarea type="text" placeholder="Kuvaus" formControlName="description" id="description" class="form-control"></textarea>
                        <label for="description">Kuvaus</label>
                    </div>


                    <div class="form-floating mb-2  col-12 col-md-12 p-0">
                        <input type="text" placeholder="Hinta" formControlName="price" id="price" class="form-control">
                        <label for="price">Hinta</label>
                    </div>

                    <div class="form-floating mb-2  col-12 col-md-12 p-0">
                        <textarea type="text" placeholder="Linkki" formControlName="url" id="url" class="form-control" ></textarea>
                        <label for="url">Linkki</label>
                    </div>


                    <div class="form-floating mb-2  col-12 col-md-12 p-0">
                        <input type="text" placeholder="Kuva" formControlName="imageURL" id="imageURL" class="form-control">
                        <label for="imageURL">Kuva</label>
                    </div>




        
                </div>
        
            </div>
            <div class="" id="contact" style="display: none;">
                <p> <i>Kontaktointi lähetetään "Yritys" -sivulla merkittyyn yrityssähköpostiosoitteeseen</i></p>
                <p>Lisää nimeksi tieto, jonka haluat antaa asiakkaalle kontaktoinnin yhteydessä</p>
                <div class="form-floating mb-2  col-12 col-md-12 p-0">
                    <input type="text" placeholder="Nimi" formControlName="title" id="title" class="form-control" [class.invalid-input]="title.errors && title.touched" required>
                    <label for="title">*Nimi</label>
                    <div class="my-alerts" *ngIf="title.invalid && title.touched">
                    <p class="mt-2" *ngIf="title.errors.required">title on pakollinen</p>
                    <p *ngIf="title.errors.title"> Vaaditaan kunnollinen firsttitle</p>
                    </div>
                </div>

            </div>
                        
                    <div class="form-inline mt-2">
                      <div class="form-group mb-2 flex-row">
                        <button [disabled]="form.invalid" class="btn btn-feedbacker w-100"><i class="fas fa-sign-in-alt me-2"></i>Lisää</button>
                      </div>
                    </div>
                    
                  </form>
        
                </div>
        </div>
        </div>
    </div>
    </div>


        <!-- Modal Check before deleting offer -->
        <div  class="modal fade" id="show-endcome" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header" *ngIf="selectedTree">
                    <h5 class="modal-title" id="exampleModalLabel">Lopputulema {{selectedTree['question']}}</h5>
                    <button type="button" class="close" id="closeDeleteModal" data-bs-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" *ngIf="selectedTree">
                    <h5 class="text-center">Lopputulemat</h5>

                    <div class="container-endcome" *ngFor="let data of selectedTree.endcomes">

                    <div class="row m-0" *ngIf="!data.imageURL; else no_img_content">
      
                        <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                          <div class="text-message text-message-left break-word w-100">
                                <h6 >{{data.title}}</h6>
                                <p class="text-message-left-text" *ngIf="data.price">{{data.price}}€</p>
                                <p class="text-message-left-text">{{data.description}}</p>
                                <button *ngIf="data.url" class="btn btn-feedbacker"><a href="{{data.url}}" target="_blank" rel="noopener noreferrer">Katso</a></button>
                            </div>
                          </div>
                      </div>
                      <ng-template #no_img_content>

                        <div class="row m-0">
            
                            <div class="col-12 col-md-12 p-0 m-1">
                              <div class="d-flex justify-content-start p-0">
                                <div class="text-message text-message-left whitespace-preline">
                                  <div class="image-container">
                                    <h6 >{{data.title}}</h6>
                                    <div class="image-container">
                                      <img class="kuvaviesti" src="{{data.imageURL}}" alt="">
                                    </div>
                                    <p class="text-message-left-text" *ngIf="data.price">{{data.price}}€</p>
                                    <p class="text-message-left-text">{{data.description}}</p>
                                    <button *ngIf="data.url" class="btn btn-feedbacker"><a href="{{data.url}}" target="_blank" rel="noopener noreferrer">Katso</a></button>
                                  </div>
                                </div>
                              </div>
                            </div>
            
                          </div>
                      </ng-template>
                      <button class="btn-feedbacker" (click)="editEndcome(data)">Muokkaa</button>
                      <button class="btn-feedbacker-delete" (click)="deleteEndcome(data)">Poista</button>
                    </div>

                </div>
                </div>
            </div>
            </div>


                <!-- Modal Check before deleting offer -->
                <button id="edit-btn" class="btn-feedbacker"data-bs-toggle="modal" style="display: none;" data-bs-target="#edit-endcome">Tiedot</button>

    <div class="modal fade" id="edit-endcome" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Muokkaa lopputulema</h5>
                <!-- <p>Kaikki tähdellä merkityt kentät ovat pakollisia</p> -->
                <button type="button" class="close" id="closeDeleteModal" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <h5 class="text-center">Täytä lomake</h5>
                <div style="display: block;" id="form-container" class="">
    
                    <form class="flex-row add-pet-form" [formGroup]="e_form"
                    (ngSubmit)="sendEditEndcome()">
    
                    <!-- Kiitosviesti -->
                    <div class="row">
                        <div class="col-sm-12 mt-4">
                        <div class="input-group input-group-sm">
                            <input (click)="preVisibilityEdit($event)" type="checkbox" id="we-contact" />
                            <label class="checkbox-inline ms-4" id="lbProcessingConsultant" for="we-contact">Haluan kontaktoinnin</label>
                        </div>
                        </div>
                    </div>
    
                    <div class="row">
                        <div class="col-sm-12 mt-4">
                        <div class="input-group input-group-sm">
                            <input (click)="preVisibility($event)" type="checkbox" id="we-pref" />
                            <label class="checkbox-inline ms-4" id="lbProcessingConsultant" for="w-pref">Haluan antaa suosituksia</label>
                        </div>
                        </div>
                    </div>
    
                    <div class="" id="epref" style="display: none;">
        
                      <!-- Sähköposti -->
                      <div class="col-12 row m-0 pt-2">
            
                        <div class="form-floating mb-2  col-12 col-md-12 p-0">
                            <input type="text" placeholder="Nimi" formControlName="e_title" id="e_title" class="form-control" [class.invalid-input]="e_title.errors && e_title.touched" required>
                            <label for="e_title">*Nimi</label>
                            <div class="my-alerts" *ngIf="e_title.invalid && e_title.touched">
                            <p class="mt-2" *ngIf="e_title.errors.required">e_title on pakollinen</p>
                            <p *ngIf="e_title.errors.e_title"> Vaaditaan kunnollinen firste_title</p>
                            </div>
                        </div>
    
                        <div class="form-floating mb-2  col-12 col-md-12 p-0">
                            <textarea type="text" placeholder="Kuvaus" formControlName="e_description" id="e_description" class="form-control"></textarea>
                            <label for="e_description">Kuvaus</label>
                        </div>
    
    
                        <div class="form-floating mb-2  col-12 col-md-12 p-0">
                            <input type="text" placeholder="Hinta" formControlName="e_price" id="e_price" class="form-control">
                            <label for="e_price">Hinta</label>
                        </div>
    
                        <div class="form-floating mb-2  col-12 col-md-12 p-0">
                            <textarea type="text" placeholder="Linkki" formControlName="e_url" id="e_url" class="form-control" ></textarea>
                            <label for="e_url">Linkki</label>
                        </div>
    
    
                        <div class="form-floating mb-2  col-12 col-md-12 p-0">
                            <input type="text" placeholder="Kuva" formControlName="e_imageURL" id="e_imageURL" class="form-control">
                            <label for="e_imageURL">Kuva</label>
                        </div>
    
    
    
    
            
                    </div>
            
                </div>
                <div class="" id="econtact" style="display: none;">
                    <p> <i>Kontaktointi lähetetään "Yritys" -sivulla merkittyyn yrityssähköpostiosoitteeseen</i></p>
                    <p>Lisää nimeksi tieto, jonka haluat antaa asiakkaalle kontaktoinnin yhteydessä</p>
                    <div class="form-floating mb-2  col-12 col-md-12 p-0">
                        <input type="text" placeholder="Nimi" formControlName="e_title" id="e_title" class="form-control" [class.invalid-input]="e_title.errors && e_title.touched" required>
                        <label for="e_title">*Nimi</label>
                        <div class="my-alerts" *ngIf="e_title.invalid && e_title.touched">
                        <p class="mt-2" *ngIf="e_title.errors.required">Nimi on pakollinen</p>
                        <p *ngIf="e_title.errors.e_title"> Vaaditaan kunnollinen Nimi</p>
                        </div>
                    </div>
    
                </div>
                            
                        <div class="form-inline mt-2">
                          <div class="form-group mb-2 flex-row">
                            <button [disabled]="e_form.invalid" class="btn btn-feedbacker w-100"><i class="fas fa-sign-in-alt me-2"></i>Lisää</button>
                          </div>
                        </div>
                        
                      </form>
            
                    </div>
            </div>
            </div>
        </div>
        </div>
    






