<button class="btn btn-feedbacker" id="open-modal" style="display: none;" data-bs-toggle="modal" data-bs-target="#delete-cert-nps">Tee slotteja</button>

<div class="row text-center">
    <div class="col-md-4">
      <div class="btn-group">
        <div
          class="btn btn-primary"
          mwlCalendarPreviousView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="closeOpenMonthViewDay()"
        >
          Edellinen
        </div>
        <div
          class="btn btn-outline-secondary"
          mwlCalendarToday
          [(viewDate)]="viewDate"
        >
          Nykyinen
        </div>
        <div
          class="btn btn-primary"
          mwlCalendarNextView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="closeOpenMonthViewDay()"
        >
          Seuraava
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'fi' }}</h3>
    </div>
    <div class="col-md-4">
      <div class="btn-group">
        <div
          class="btn btn-primary"
          (click)="setView(CalendarView.Month)"
          [class.active]="view === CalendarView.Month"
        >
          Kuukausi
        </div>
        <div
          class="btn btn-primary"
          (click)="setView(CalendarView.Week)"
          [class.active]="view === CalendarView.Week"
        >
          Viikko
        </div>
        <div
          class="btn btn-primary"
          (click)="setView(CalendarView.Day)"
          [class.active]="view === CalendarView.Day"
        >
          Päivä
        </div>
      </div>
    </div>
  </div>
  <br />
  <div [ngSwitch]="view">
    <mwl-calendar-month-view
      *ngSwitchCase="CalendarView.Month"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      [locale]="locale"
      [activeDayIsOpen]="activeDayIsOpen"
      (dayClicked)="dayClicked($event.day)"
      (eventClicked)="handleEvent('Klikattu', $event.event)"
      (eventTimesChanged)="eventTimesChanged($event)"
    >
    </mwl-calendar-month-view>
    <mwl-calendar-week-view
      *ngSwitchCase="CalendarView.Week"
      [viewDate]="viewDate"
      [events]="events"
      [locale]="locale"
      [refresh]="refresh"
      [dayStartHour]="10"
      [dayEndHour]="20"
      (eventClicked)="handleEvent('Clicked', $event.event)"
    >
    </mwl-calendar-week-view>
    <mwl-calendar-day-view
      *ngSwitchCase="CalendarView.Day"
      [viewDate]="viewDate"
      [events]="events"
      [locale]="locale"
      [refresh]="refresh"
      [dayStartHour]="6"
      [dayEndHour]="20"
      (eventClicked)="handleEvent('Clicked', $event.event)"
    >
    </mwl-calendar-day-view>
  </div>
  
  <!-- Everything you see below is just for the demo, you don't need to include it in your app -->
    

  
  <ng-template #modalContent let-close="close">
    <div class="modal-header">
      <h5 class="modal-title">Varaa tapahtuma</h5>
      <button type="button" class="close" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div>
        Action:
        <pre>{{ modalData?.action }}</pre>
      </div>
      <div>
        Event:
        <pre>{{ modalData?.event | json }}</pre>
      </div>
      <button class="btn btn-feedbacker" (click)="timeSelected(modalData?.event)">Valitse aika</button>
    </div>
    <div class="modal-footer">
      <button type="button" id="close-modal" class="btn btn-outline-secondary" (click)="close()">
        OK
      </button>
    </div>
  </ng-template>
  

  <button class="btn btn-feedbacker" data-bs-toggle="modal" data-bs-target="#delete-cert-nps">Osta tapahtuma</button>

  <div class="modal fade" id="delete-cert-nps" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Varaa aika</h5>
          <!-- <p>Kaikki tähdellä merkityt kentät ovat pakollisia</p> -->
          <button type="button" class="close" id="closeDeleteModal" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <h5 class="text-center">Varaa aika</h5>
          <div class="col-12 row m-0 pt-4 pb-4">

            <div class="" *ngFor="let time of selectedTimes">
                <p>{{time.title}}</p>
                <p>{{time.notes}}</p>
            </div>

            <p>Summa yhteensä: {{totalPrice}} ({{(totalPrice / 1.24).toFixed(2)}})</p>
  
            <form class="flex-row add-pet-form" [formGroup]="form"
            (ngSubmit)="onSubmit()">
              <!-- ETUNIMI -->
              <div class="form-group">
                <div class="row col-12 m-0 p-0">
                  <div class="form-group mb-2  p-0">
                    <label type="text" readonly class="label-text form-control-plaintext m-0 p-0" value="Nimi">Etunimi*</label>
                  </div>
                  <div class="form-group mb-2 flex-column col-12  p-0">
                    <input class="" type="text" placeholder="Etunimi" formControlName="firstName" class="form-control" [class.invalid-input]="firstName.errors && firstName.touched" required>
                    <div class="my-alerts" *ngIf="firstName.invalid && firstName.touched">
                      <p class="body2b-w mt-2" *ngIf="firstName.errors.required">Etunimi on pakollinen</p>
                      <p *ngIf="firstName.errors.firstName"> Vaaditaan kunnollinen Etunimi</p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Sukunimi -->
              <div class="form-group">
                  <div class="row col-12 m-0 p-0">
                      <div class="form-group mb-2  p-0">
                      <label type="text" readonly class="label-text form-control-plaintext m-0 p-0" value="Nimi">Sukunimi*</label>
                      </div>
                      <div class="form-group mb-2 flex-column col-12  p-0">
                      <input class="" type="text" placeholder="Sukunimi" formControlName="lastName" class="form-control" [class.invalid-input]="lastName.errors && lastName.touched" required>
                      <div class="my-alerts" *ngIf="lastName.invalid && lastName.touched">
                          <p class="body2b-w mt-2" *ngIf="lastName.errors.required">Sukunimi on pakollinen</p>
                          <p *ngIf="lastName.errors.lastName"> Vaaditaan kunnollinen sukunimi</p>
                      </div>
                      </div>
                  </div>
              </div>
              <!-- Postiosoite -->
              <div class="form-group">
                  <div class="row col-12 m-0 p-0">
                      <div class="form-group mb-2  p-0">
                      <label type="text" readonly class="label-text form-control-plaintext m-0 p-0" value="Nimi">Postiosoite*</label>
                      </div>
                      <div class="form-group mb-2 flex-column col-12  p-0">
                      <input class="form-control" type="text" placeholder="Postiosoite" formControlName="address" class="form-control" [class.invalid-input]="address.errors && address.touched" required>
                      <div class="my-alerts" *ngIf="address.invalid && address.touched">
                          <p class="body2b-w mt-2" *ngIf="address.errors.required">Osoite on pakollinen</p>
                          <p *ngIf="address.errors.address"> Vaaditaan kunnollinen osoite</p>
                      </div>
                      </div>
                  </div>
              </div>
              <!-- Postinumero -->
              <div class="form-group">
                  <div class="row col-12 m-0 p-0">
                      <div class="form-group mb-2  p-0">
                      <label type="text" readonly class="label-text form-control-plaintext m-0 p-0" value="Nimi">Postinumero*</label>
                      </div>
                      <div class="form-group mb-2 flex-column col-12  p-0">
                      <input type="number" placeholder="Postinumero" formControlName="postNumber" class="form-control" [class.invalid-input]="postNumber.errors && postNumber.touched" required>
                      <div class="my-alerts" *ngIf="postNumber.invalid && postNumber.touched">
                          <p class="body2b-w mt-2" *ngIf="postNumber.errors.required">Postinumero on pakollinen</p>
                          <p *ngIf="postNumber.errors.postNumber"> Vaaditaan kunnollinen postinumero</p>
                      </div>
                      </div>
                  </div>
              </div>
              <!-- Postitoimipaikka -->
              <div class="form-group">
                  <div class="row col-12 m-0 p-0">
                      <div class="form-group mb-2  p-0">
                      <label type="text" readonly class="label-text form-control-plaintext m-0 p-0" value="Nimi">Postitoimipaikka*</label>
                      </div>
                      <div class="form-group mb-2 flex-column col-12  p-0">
                      <input class="form-control" type="text" placeholder="Postitoimipaikka" formControlName="postOffice" class="form-control" [class.invalid-input]="postOffice.errors && postOffice.touched" required>
                      <div class="my-alerts" *ngIf="postOffice.invalid && postOffice.touched">
                          <p class="body2b-w mt-2" *ngIf="postOffice.errors.required">Postitoimipaikka on pakollinen</p>
                          <p *ngIf="postOffice.errors.postOffice"> Vaaditaan kunnollinen postitoimipaikka</p>
                      </div>
                      </div>
                  </div>
              </div>
              <!-- Sähköpostiosoite -->
              <div class="form-group">
                  <div class="row col-12 m-0 p-0">
                      <div class="form-group mb-2  p-0">
                      <label type="text" readonly class="label-text form-control-plaintext m-0 p-0" value="Nimi">Sähköpostiosoite*</label>
                      </div>
                      <div class="form-group mb-2 flex-column col-12  p-0">
                      <input class="form-control" type="email" placeholder="Sähköpostiosoite" formControlName="email" class="form-control" [class.invalid-input]="email.errors && email.touched" required>
                      <div class="my-alerts" *ngIf="email.invalid && email.touched">
                          <p class="body2b-w mt-2" *ngIf="email.errors.required">Sähköpostiosoite on pakollinen</p>
                          <p *ngIf="email.errors.email"> Vaaditaan kunnollinen sähköpostiosoite</p>
                      </div>
                      </div>
                  </div>
              </div>
              <!-- Puhelinnumero -->
              <div class="form-group">
                  <div class="row col-12 m-0 p-0">
                      <div class="form-group mb-2  p-0">
                      <label type="text" readonly class="label-text form-control-plaintext m-0 p-0" value="Nimi">Puhelinnumero*</label>
                      </div>
                      <div class="form-group mb-2 flex-column col-12  p-0">
                      <input class="form-control" type="tel" placeholder="Puhelinnumero" formControlName="phone" class="form-control" [class.invalid-input]="phone.errors && phone.touched" required>
                      <div class="my-alerts" *ngIf="phone.invalid && phone.touched">
                          <p class="body2b-w mt-2" *ngIf="phone.errors.required">Puhelinnumero on pakollinen</p>
                          <p *ngIf="phone.errors.phone"> Vaaditaan kunnollinen Puhelinnumero</p>
                      </div>
                      </div>
                  </div>
              </div>
              
              <!-- Lisätietoja -->
             <div class="form-group">
                <div class="row col-12 m-0 p-0">
                  <div class="form-group mb-2 col-6 p-0">
                    <label type="text" readonly class="label-text form-control-plaintext m-0 p-0" value="lisätietoja">Lisätietoja</label>
                  </div>
                  <div class="form-group mb-2 flex-column col-12  p-0">
                    <textarea style="min-height: 70px;" type="text" placeholder="Kirjoita tähän lisätietoja" formControlName="addInfo"  class="form-control"></textarea>
                  </div>
                </div>
              </div> 

              <div class="col-12"></div>

              <p>Valitse maksutapa</p>
              <div [disabled]="form.invalid" *ngFor="let method of paymentMethods" class="full-wrap mb-3 w-100">
                <div (click)="selectMethod(method.selected_value)" class="w-100 list-group-item list-group-item-link pt-3 ps-1 pe-1">
                  <div class="list-wrap-closed d-inline-flex col-12 p-0">
                    <img src="{{method.img}}" class="icon-image"> 
                    <p class="body2 mt-md-2 body2">{{method.name}}</p>
                  </div>
                
                </div>
            </div>

              </form>
            <div class="col-12 text-center">
              <a class="pointer"  id="close-delete-nps" data-bs-dismiss="modal">Peruuta</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>