<app-user-header></app-user-header>
<div class="full" *ngIf="this.company && this.company.length > 0">

    <div class="bg-container header pnset-6 container-fluid pt-4 ps-4 pe-4">
        <div class="col-12 row pb-4">
            <div class="col-12 col-md-7 page-title">
                <p class="page-title h2 text-white d-inline-block mb-0">{{company[0].businessName}}</p> <br>
            </div>
            <div class="col">
                <nav class="d-none d-md-inline-block ml-md-4">
                    <ol class="breadcrumb breadcrumb-links breadcrumb-dark">
                        <li class="breadcrumb-item">
                            <a routerLink="/{{businessID}}/users/home">Koti</a>
                        </li>
                        <li class="breadcrumb-item active">
                            <a href="#">Yrityksen tiedot</a>
                        </li>
                    </ol>
                </nav>  
            </div>
        </div>
        <div class="col-12 row">

        </div>
      </div>
      <div class="col-12 ps-4 row nset-card">
        <div class="col-xl-8">
            <div class="card card-bg-question">
                <div class="card-header">
                    <div class="row align-items-center">
                        <div class="col">
                            <h6 class="text-light text-uppercase ls-1 mb-1">Tiedot</h6>
                            <h5 class="h3 text-white mb-0">Käyttäjät</h5>
                        </div>
                        <div class="col">
                            <ul class="nav nav-pills justify-content-end">
                                <li class="nav-item mr-2 mr-md-0">
                                    <button class="btn btn-feedbacker" routerLink="/{{businessID}}/register-user"><i class="fas fa-plus-circle me-2"></i>Lisää</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table align-items-center table-flush">
                        <thead class="thead-dark">
                            <tr>
                                <th>Etunimi</th>
                                <th>Sukunimi</th>
                                <th>Sähköpostiosoite</th>
                            </tr>
                        </thead>
                        <tbody class="tbody-dark">
                            <tr *ngFor="let user of users">
                                <td>{{user.firstName}}</td>
                                <td>{{user.lastName}}</td>
                                <td>{{user.email}}</td>
                            </tr>
                        </tbody>

                    </table>
                </div>
                
            </div>

        </div>
        <div class="col-xl-4">
            <div class="card ">
                <div class="card-header">
                    <div class="row align-items-center">
                        <div class="col">
                            <h6 class="text-dark text-uppercase ls-1 mb-1">Tiedot</h6>
                            <h5 class="h3 text-black mb-0"> Yrityksen tiedot</h5>
                        </div>
                        <div class="col">
                            <ul class="nav nav-pills justify-content-end">
                                <li class="nav-item mr-2 mr-md-0">
                                    <button class="btn btn-feedbacker-dark" routerLink="/{{businessID}}/edit-business"><i class="fas fa-edit me-2"></i>Muokkaa</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table align-items-center table-flush">
                            <thead class="thead-light">
                                <tr>
                                    <th>Tieto</th>
                                    <th>Vastaus</th>

                                </tr>
                            </thead>
                            <tbody class="tbody-light">
                                <tr>
                                    <tr>
                                        <th width="25%">Nimi</th>
                                        <td width="75%">{{company[0].businessName}}</td>
                                    </tr>
                                    <tr>
                                        <th width="25%">Email</th>
                                        <td width="75%">{{company[0].email}}</td>
                                    </tr>
                                    <tr>
                                        <th width="25%">Phone</th>
                                        <td width="75%">{{company[0].phone}}</td>
                                    </tr>
                                    <tr>
                                        <th width="25%">OVT</th>
                                        <td width="75%">{{company[0].ovt}}</td>
                                    </tr>
                                    <tr>
                                        <th width="25%">Opertaattori</th>
                                        <td width="75%">{{company[0].operator}}</td>
                                    </tr>
                                    <tr *ngIf="company[0].orders.certificates.payingCustomer">
                                        <th width="25%">Sertifikaatti NPS</th>
                                        <td *ngIf="!isNPSCert" width="75%"><a routerLink="/{{businessID}}/company-details"  (click)="addCert('businessNPS')">Luo linkki</a></td>
                                        <td *ngIf="isNPSCert" width="75%"><a routerLink="/{{businessID}}/company-details" data-bs-toggle="modal" data-bs-target="#delete-cert-nps" >Poista sertifikaatti</a></td>
                                    </tr>
                                    <tr *ngIf="company[0].orders.certificates.payingCustomer">
                                        <th  width="25%">Sertifikaatti Keskiarvo</th>
                                        <td *ngIf="!isAverageCert" width="75%"><a routerLink="/{{businessID}}/company-details"  (click)="addCert('businessAverage')">Luo linkki</a></td>
                                        <td *ngIf="isAverageCert" width="75%"><a routerLink="/{{businessID}}/company-details" data-bs-toggle="modal" data-bs-target="#delete-cert-average">Poista sertifikaatti</a></td>

                                    </tr>
                            </tbody>
    
                        </table>
                    </div>
                </div>
                
            </div>
        </div>
    </div>

    <div class="col-12 p-0 p-md-4">
        <div class="card ">
            <div class="card-header">
                <div class="row-align-items-center">
                    <div class="col">
                        <h6 class="text-dark text-uppercase ls-1 mb-1">Integraatiot</h6>
                        <h5 class="h3 text-black mb-0">Tiedot</h5>
                    </div>
                </div>
                <div class="table-responsive" *ngIf="company[0].integrations.length > 0;else no_answers_content">
                    <table class="table align-items-center table-flush">
                        <thead class="thead-light">
                            <tr>
                                <th>Nimi</th>
                                <th>Token</th>
                            </tr>
                        </thead>
                        <tbody class="tbody-light">
                            <tr *ngFor="let data of company[0].integrations">
                                <td>{{data.name}}</td>
                                <td>{{data.integrationToken}}</td>
                            </tr>
                        </tbody>
      
                    </table>
                </div>
                <ng-template #no_answers_content>
                    <div class="col-12 row m-0">
                        <div class="col-3"></div>
                        <div class="col-12 col-md-6">
                            <div class="alert alert-warning">
                                <p class="normal-text text-center">Järjestelmää ei ole intrgroitu muihin järjestelmii.</p>
                            </div>
                        </div>
                        <div class="col-3"></div>
                    </div>
                </ng-template>
            </div>
            
        </div>
      </div>

      <div class="col-12 p-0 p-md-4">
        <div class="card ">
            <div class="card-header">
                <div class="row-align-items-center">
                    <div class="col">
                        <h6 class="text-dark text-uppercase ls-1 mb-1">Viitearvot</h6>
                        <h5 class="h3 text-black mb-0">Tiedot</h5>
                    </div>
                </div>
                <div class="table-responsive" *ngIf="company[0].integrations.length > 0;else no_answers_content">
                    <table class="table align-items-center table-flush">
                        <thead class="thead-light">
                            <tr>
                                <th>Tyyppi</th>
                                <th>{{company[0].businessName}}:n Viitearvo</th>
                                <th>Toimialan viitearvo</th>
                            </tr>
                        </thead>
                        <tbody class="tbody-light">

                            <tr>
                                <td>NPS</td>
                                <th><input class="form-control" min="0" max="100" id="npsc" [value]="company[0].goals.nps.company *100"></th>
                                <th><input class="form-control" min="0" max="100" id="npss" [value]="company[0].goals.nps.sector *100"></th>
                            </tr>
                            <tr>
                                <td>CSAT</td>
                                <th><input class="form-control" min="0" max="100" id="csatc" [value]="company[0].goals.csat.company *100"></th>
                                <th><input class="form-control" min="0" max="100" id="csats" [value]="company[0].goals.csat.sector *100"></th>
                            </tr>
                            <tr>
                                <td>CES</td>
                                <th><input class="form-control" min="0" max="100" id="cesc" [value]="company[0].goals.ces.company *100"></th>
                                <th><input class="form-control" min="0" max="100" id="cess" [value]="company[0].goals.ces.sector *100"></th>
                            </tr>
                            <tr>
                                <button type="submit" class="btn btn-feedbacker mt-4" (click)="saveReferences()">Tallenna</button>
                            </tr>

                        </tbody>
      
                    </table>
                </div>
                <ng-template #no_answers_content>
                    <div class="col-12 row m-0">
                        <div class="col-3"></div>
                        <div class="col-12 col-md-6">
                            <div class="alert alert-warning">
                                <p class="normal-text text-center">Järjestelmää ei ole intrgroitu muihin järjestelmii.</p>
                            </div>
                        </div>
                        <div class="col-3"></div>
                    </div>
                </ng-template>
            </div>
            
        </div>
      </div>


    




</div>

    <!-- Modal Check before deleting offer -->
    <button data-bs-toggle="modal" style="display: none;" id="show-btn" data-bs-target="#show-code"></button>
    <div class="modal fade" id="show-code" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Upostuskoodi</h5>
            <!-- <p>Kaikki tähdellä merkityt kentät ovat pakollisia</p> -->
            <button type="button" class="close" id="closeDeleteModal" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <h5 class="text-center">Kopioi upotuskoodisi</h5>
            <div class="col-12 row pt-4 pb-4">
            <textarea name=""  style="height: 200px;" class="form-control" id="code-area"></textarea>
            </div>
        </div>
        </div>
    </div>
    </div>

                <!-- Modal Check before deleting offer -->
               <button data-bs-toggle="modal" id="checkDeleteBtn" data-bs-target="#delete-feedback" style="display:none"></button>
               <div class="modal fade" id="delete-cert-nps" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                 <div class="modal-dialog" role="document">
                   <div class="modal-content">
                     <div class="modal-header">
                       <h5 class="modal-title" id="exampleModalLabel">Sertifikaatin poistaminen</h5>
                       <!-- <p>Kaikki tähdellä merkityt kentät ovat pakollisia</p> -->
                       <button type="button" class="close" id="closeDeleteModal" data-bs-dismiss="modal" aria-label="Close">
                         <span aria-hidden="true">&times;</span>
                       </button>
                     </div>
                     <div class="modal-body">
                       <h5 class="text-center">Poistetaanko sertifikaatti pysyvästi?</h5>
                       <div class="col-12 row m-0 pt-4 pb-4">
                        <div class="col-12 text-center">
                            <button class="btn btn-feedbacker-delete" (click)="removeCert('businessNPS')"><i class="far fa-trash-alt me-2"></i>Poista</button>
                          </div>
                         <div class="col-12 text-center">
                           <a class="pointer"  id="close-delete-nps" data-bs-dismiss="modal">Peruuta</a>
                         </div>
                       </div>
                     </div>
                   </div>
                 </div>
               </div>


            <!-- Modal Check before deleting offer -->
            <button data-bs-toggle="modal" id="cert-delete" data-bs-target="#delete-feedback" style="display:none"></button>
            <div class="modal fade" id="delete-cert-average" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Sertifikaatin poistaminen</h5>
                    <!-- <p>Kaikki tähdellä merkityt kentät ovat pakollisia</p> -->
                    <button type="button" class="close" id="closeDeleteModal" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                    <div class="modal-body">
                    <h5 class="text-center">Poistetaanko sertifikaatti pysyvästi?</h5>
                    <div class="col-12 row m-0 pt-4 pb-4">
                    <div class="col-12 text-center">
                        <button class="btn btn-feedbacker-delete" (click)="removeCert('businessAverage')"><i class="far fa-trash-alt me-2"></i>Poista</button>
                        </div>
                        <div class="col-12 text-center">
                        <a class="pointer"  id="close-delete-average" data-bs-dismiss="modal">Peruuta</a>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>