import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { base_host, environment, my_port, my_protocol } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';

@Component({
  selector: 'app-create-spinner',
  templateUrl: './create-spinner.component.html',
  styleUrls: ['./create-spinner.component.css']
})
export class CreateSpinnerComponent implements OnInit {

  errMessage = "";
  successMessage = "";
  spinnerID:string = this.route.snapshot.params.id;
  businessID: string = this.route.snapshot.params.businessID;
  authToken = localStorage.getItem('auth');
  feedbacks = [];
  spinner;
  form: FormGroup;
  isEdit;
  btnText = ""

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'auth': `${this.authToken}`  }),
    withCredentials: true,
  };

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private http: HttpClient,
    private route:ActivatedRoute,
    private authService: AuthService) {
      this.authService.validateBusiness(this.businessID)

     }

  ngOnInit(): void {
    if(this.spinnerID){
      this.isEdit = true;
      this.isEditing();
      this.btnText = "Muokkaa esittelyä";
    }
    else{
      this.btnText = "Lisää esittely";
    }

    this.form = this.fb.group({
      name: ['', Validators.required],
      textColor: [''],
      backgroundColor: [''],
    }); 
  }

  get name() { return this.form.get('name') };
  get textColor() { return this.form.get('textColor') };
  get backgroundColor() { return this.form.get('backgroundColor') };
  nweSpinner(){

    var textColor = this.form.get('textColor').value;
    var backgroundColor= this.form.get('backgroundColor').value;

    if(!textColor){
      this.form.get('textColor').setValue('#000000');
    }

    if(!backgroundColor){
      this.form.get('backgroundColor').setValue('#ffffff');
    }


    const request = {
      name: this.form.get('name').value,
      textColor: this.form.get('textColor').value,
      backgroundColor: this.form.get('backgroundColor').value,         
    }

    this.http.post(environment.SPINNER, request, this.httpOptions).subscribe(
      (res: any) => {
        console.log(res);
        if (res.status == 200) {
          alert('Esittelyn luominen onnistui! Paina "OK" siirtyäksesi esittelyn sivulle');
          this.router.navigate(['/'+this.businessID+'/spinner-details/'+res.message.id])
        } 
        
        else {

          alert('Päivitys epäonnistui')
          //loginError = true;
        }
      }, (err: any) => {

        console.log("Error while login :: ", err);
      }
    )

  }

  isEditing(){
    this.fetchSpinnerDetails();
  }

  fetchSpinnerDetails(){
    this.http.get(environment.SPINNER+'/'+this.spinnerID, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {
          this.spinner = res.message[0];


          this.form = this.fb.group({
            name: [this.spinner.name, Validators.required],
            textColor: this.spinner.textColor,
            backgroundColor: this.spinner.backgroundColor,

          });


        }
        else {
          var anElm = document.getElementById('notadded');
          anElm.style.display="block";
          console.log('ei ole kyseistä käyttäjää')
        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })

  }

  onSubmit(){
    if(this.spinnerID){
      this.editSpinner();
    }
    else{
      this.nweSpinner();
    }
  }

  editSpinner(){


    const request = {
      name: this.form.get('name').value,
      textColor: this.form.get('textColor').value,
      backgroundColor: this.form.get('backgroundColor').value,
      spinnerID: this.spinnerID,            
    }

       this.http.put(environment.SPINNER, request, this.httpOptions).subscribe(
        (res: any) => {
          console.log(res);
          if (res.status == 200) {
           alert('Muokkaaminen onnistui! Paina "OK" siirtyäksesi esittelyn sivulle')
            this.router.navigate(['/'+this.businessID+'/spinner-details/'+this.spinnerID])
          } 
          
          else {


            //loginError = true;
          }
        }, (err: any) => {

          console.log("Error while login :: ", err);
        }
      )
  }

}
