<app-user-header></app-user-header>


<div class="wrapperi">

  <div class="bg-container header pnset-6 container-fluid pt-4 ps-4 pe-4">
    <div class="col-12 row pb-4">
        <div class="col-12 col-md-7 page-title">
            <p class="page-title h2 text-white d-inline-block mb-0">{{btnText}}</p> <br>
            <p class="desc-text text-white">{{btnText}} täyttämällä alla olevat tiedot.</p>
        </div>
        <div class="col">
            <nav class="d-none d-md-inline-block ml-md-4">
                <ol class="breadcrumb breadcrumb-links breadcrumb-dark">
                    <li class="breadcrumb-item">
                        <a routerLink="/{{businessID}}/users/home">Koti</a>
                    </li>
                    <li class="breadcrumb-item">
                      <a routerLink="/{{businessID}}/feedbacks">Palautekyselyt</a>
                    </li>
                    <li class="breadcrumb-item active">
                        <a href="#">{{btnText}}</a>
                    </li>
                </ol>
            </nav>  
        </div>
    </div>
    <div class="col-12 row">

    </div>
  </div>


  <div class="col-12 p-0 row m-0">
    <div class="col-3"></div>
    <div class="col-12 col-md-6 p-0 input-cover mt-4 p-4">
      <div class="col-12 p-0 mt-4">
        <div class="alert alert-warning" *ngIf="messageErr">
          <p class="text-center">Samalla tunnisteella löyti jo palautekysely</p>
        </div>
      </div>

      <div id="loader" class="loader-container mt-4" style="display:none">
        <div class="loader mx-auto"></div>
      </div>

      <div style="display: block;" id="form-container" class="d-flex flex-row justify-content-center ml-4">
        <form class="flex-row add-pet-form" [formGroup]="form"
        (ngSubmit)="onSubmit()">
          <!-- Sähköpostiosoite ja puhelinnumero -->
          <div class="col-12 row m-0 pt-2">

            <div class="form-floating mb-2 col-12  p-0">
              <input type="text" placeholder="Palautteen nimi" formControlName="identifier"  class="form-control">
              <label for="identifier">Palautekyselyn nimi (pakollinen)</label>
            </div>
          </div>
        <!-- Sähköpostiosoite ja puhelinnumero -->

          <!-- Tekstipalaute -->
          <div class="col-12 row m-0 pt-2">
            <div class="form-floating mb-2  col-12 col-md-6 p-0">
              <select type="text" placeholder="Tekstipalaute sallittu" formControlName="allowTextFeedback" id="allowTextFeedback" class="form-control" [class.invalid-input]="allowTextFeedback.errors && allowTextFeedback.touched" required>
                  <option></option>
                  <option value="true">Kyllä</option>
                  <option value="false">Ei</option>
              </select>
              <label for="allowTextFeedback">Tekstipalaute sallittu (pakollinen)</label>
              <div class="my-alerts" *ngIf="allowTextFeedback.invalid && allowTextFeedback.touched">
                <p class="mt-2" *ngIf="allowTextFeedback.errors.required">Tekstipalaute sallittu on pakollinen</p>
                <p *ngIf="allowTextFeedback.errors.allowTextFeedback"> Vaaditaan määrätty Tekstipalaute sallittu</p>
              </div>
            </div>

            <div class="form-floating mb-2 ps-md-2 col-12 col-md-6 p-0">
              <select type="text" placeholder="Palautteen tyyppi" formControlName="type" id="type" class="form-control" [class.invalid-input]="type.errors && type.touched" required>
                  <option></option>
                  <option value="smile">Hymynaama</option>
                  <option value="nps">NPS</option>
                  <!-- <option value="thumb">Peukalo</option> -->
              </select>
              <label class="ps-md-4" for="type">Palautteen tyyppi (pakollinen)</label>
              <div class="my-alerts" *ngIf="type.invalid && type.touched">
                <p class="mt-2" *ngIf="type.errors.required">Palautteen tyyppi on pakollinen</p>
                <p *ngIf="type.errors.type"> Vaaditaan määrätty Palautteen tyyppi</p>
              </div>
            </div>

          </div>

          <!-- Tekstipalaute -->

          

          <div class="col-12 row m-0 pt-2">

            <div class="form-floating mb-2 col-12 col-md-6 p-0">
                <input type="color"  placeholder="Tekstinväri" formControlName="textColor" id="textColor" class="form-control" >
                <label for="textColor">Tekstinväri (pakollinen)</label>
            </div>

            <div class="form-floating ps-md-2  mb-2 col-12 col-md-6 p-0">
                <input type="color" placeholder="Taustaväri" formControlName="backgroundColor" id="backgroundColor" class="form-control">
                <label class="ps-md-4" for="backgroundColor">Taustaväri (pakollinen)</label>
            </div>
            </div>

          <!-- Kysymys -->
          <div class="col-12 row m-0 pt-2">
            <div class="form-floating mb-2 pe-2 col-12 p-0">
              <input type="text" placeholder="Kysymys" autocomplete="off" formControlName="question" id="question" class="form-control" [class.invalid-input]="question.errors && question.touched" required>
              <label for="question">Kysymys (pakollinen)</label>
              <div class="my-alerts" *ngIf="question.invalid && question.touched">
                <p class="mt-2" *ngIf="question.errors.required">Kysymys on pakollinen</p>
                <p *ngIf="question.errors.question"> Vaaditaan määrätty Kysymys</p>
              </div>
            </div>
          </div>

        <!-- Kiitosviesti -->
        <div class="row">
          <div class="col-sm-6 mt-4">
            <div class="input-group input-group-sm">
              <input (click)="preVisibility($event)" type="checkbox" id="allowPre" />
              <label class="checkbox-inline ms-4" id="lbProcessingConsultant" for="allowPre">Haluan vakiovastauksia</label>
            </div>
          </div>
        </div>

        <div id="pre-wrap" style="display: none;">
          <div class="col-12 low-container">
            <p class="mb-0">Alle 4 tulokset</p>
          <!-- Kysymys -->
          <div class="col-12 row m-0 pt-2">
            <div class="form-floating mb-2 pe-2 col-12 p-0">
              <input type="text" placeholder="Kysymys" autocomplete="off" formControlName="questionlow" id="questionlow" class="form-control" >
              <label for="questionlow">Kysymys (pakollinen)</label>

            </div>

            <div class="col-12 p-0 p-md-4">
              <div class="card ">
                  <div class="card-header">
                      <div class="table-responsive" >
                          <table class="table align-items-center table-flush">
                              <thead class="thead-light">
                                  <tr>
                                      <th>Vastaus</th>
                                  </tr>
                              </thead>
                              <tbody class="tbody-light">
                                <tr>
                                  <input value="" class="form-control mt-2" id="low1" >
                                </tr>
                                <tr>
                                  <input value="" class="form-control mt-2" id="low2" >
                                </tr>
                                <tr>
                                  <input value="" class="form-control mt-2" id="low3" >
                                </tr>
                                <tr>
                                  <input value="" class="form-control mt-2" id="low4" >
                                </tr>
                              </tbody>
            
                          </table>
                      </div>
                  </div>
                  
              </div>
            </div>
      

          </div>

          </div>
          <div class="col-12 mid-container">
            <p>Yli 4-8 tulokset</p>

            <div class="col-12 row m-0 pt-2">
              <div class="form-floating mb-2 pe-2 col-12 p-0">
                <input type="text" placeholder="Kysymys" autocomplete="off" formControlName="questionmid" id="questionmid" class="form-control">
                <label for="questionmid">Kysymys (pakollinen)</label>

              </div>
  
              <div class="col-12 p-0 p-md-4">
                <div class="card ">
                    <div class="card-header">
                        <div class="table-responsive" >
                            <table class="table align-items-center table-flush">
                                <thead class="thead-light">
                                    <tr>
                                        <th>Vastaus</th>
                                    </tr>
                                </thead>
                                <tbody class="tbody-light">
                                  <tr>
                                    <input value="" class="form-control mt-2" id="mid1" >
                                  </tr>
                                  <tr>
                                    <input value="" class="form-control mt-2" id="mid2" >
                                  </tr>
                                  <tr>
                                    <input value="" class="form-control mt-2" id="mid3" >
                                  </tr>
                                  <tr>
                                    <input value="" class="form-control mt-2" id="mid4" >
                                  </tr>
                                </tbody>
              
                            </table>
                        </div>
                    </div>
                    
                </div>
              </div>
        
  
            </div>

          </div>
          <div class="col-12 high-container">
            <p>Yli 8 tulokset</p>

            <div class="col-12 row m-0 pt-2">
              <div class="form-floating mb-2 pe-2 col-12 p-0">
                <input type="text" placeholder="Kysymys" autocomplete="off" formControlName="questionhigh" id="questionhigh" class="form-control" >
                <label for="questionhigh">Kysymys (pakollinen)</label>
              </div>
  
              <div class="col-12 p-0 p-md-4">
                <div class="card ">
                    <div class="card-header">
                        <div class="table-responsive" >
                            <table class="table align-items-center table-flush">
                                <thead class="thead-light">
                                    <tr>
                                        <th>Vastaus</th>
                                    </tr>
                                </thead>
                                <tbody class="tbody-light">
                                  <tr>
                                    <input value="" class="form-control mt-2" id="high1" >
                                  </tr>
                                  <tr>
                                    <input value="" class="form-control mt-2" id="high2" >
                                  </tr>
                                  <tr>
                                    <input value="" class="form-control mt-2" id="high3" >
                                  </tr>
                                  <tr>
                                    <input value="" class="form-control mt-2" id="high4" >
                                  </tr>
                                </tbody>
              
                            </table>
                        </div>
                    </div>
                    
                </div>
              </div>
        
  
            </div>

          </div>
          
          
        </div>

        <!-- Kiitosviesti -->
        <div class="col-12 row m-0 pt-2">
            <div class="form-floating mb-2  col-12 p-0">
                <textarea style="min-height:125px"  placeholder="Kiitosviesti" formControlName="thankYouText" id="thankYouText" class="form-control" [class.invalid-input]="thankYouText.errors && thankYouText.touched" required></textarea>
                <label for="thankYouText">Kiitosviesti (pakollinen)</label>
                <div class="my-alerts" *ngIf="thankYouText.invalid && thankYouText.touched">
                <p class="mt-2" *ngIf="thankYouText.errors.required">Kiitosviesti on pakollinen</p>
                <p *ngIf="thankYouText.errors.thankYouText"> Vaaditaan määrätty Kiitosviesti</p>
                </div>
            </div>
          </div>

          <!-- Secure text -->
          <div class="col-12 row m-0 pt-2">
            <div class="form-floating mb-2  col-12 p-0">
                <textarea style="min-height:125px"  placeholder="Turvallisuusviesti" formControlName="securityText" id="securityText" class="form-control" ></textarea>
                <label for="securityText">Turvallisuusviesti</label>
            </div>
          </div>

            <!-- Ehtojen hyväksyminen -->
            <div class="form-group">
                <div class="row col-12 d-inline-flex">
                    <div class="form-group privacy-text-wrap mb-2 col-10 col-md-6 p-0">
                    <label type="text" readonly class="body2b-b form-control-plaintext m-0 p-0 privacy-text">Olen lukenut palvelun <a routerLink="/termsofservice"> käyttöehdot </a>sekä hyväksyn <a routerLink="/privacypolicy">tietosuojaselosteen</a>*</label>
                    </div>
                    <div class="form-group mx-sm-3 mb-2  col-1 p-0">
                    <input style="width:20px; height:20px" type="checkbox" formControlName="privacy" id="privacy"  [class.invalid-input]="privacy.errors && privacy.touched" required>
                    <div class="my-alerts w-50" *ngIf="privacy.invalid && privacy.touched">
                        <p class="mt-2" *ngIf="privacy.errors.required">Käyttöehdot on hyväksyttävä</p>
                        <p *ngIf="privacy.errors.privacy"> Vaaditaan määrätty käyttöehdot</p>
                    </div>
                    </div>
                </div>
            </div>
          
                
            <div class="form-inline mt-2">
              <div class="form-group mb-2 flex-row">
                <button [disabled]="form.invalid" class="btn btn-feedbacker w-100"><i class="fas fa-sign-in-alt me-2"></i>{{btnText}}</button>
              </div>
            </div>
          </form>

        </div>
      </div>
      <div class="col-3"></div>
</div>