import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { base_host, environment, my_port, my_protocol } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';

@Component({
  selector: 'app-spinner-details',
  templateUrl: './spinner-details.component.html',
  styleUrls: ['./spinner-details.component.css']
})
export class SpinnerDetailsComponent implements OnInit {
  errMessage = "";
  successMessage = "";
  businessID: string = this.route.snapshot.params.businessID;
  spinnerID: string = this.route.snapshot.params.id;
  feedbacks = [];
  authToken = localStorage.getItem('auth');
  feedbackValues;
  order;
  spinners: [];
  spinner;
  deletable;
  pathUrl;
  txtValue;


  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'auth': `${this.authToken}`  }),
    withCredentials: true,
  };

  constructor(
    private router: Router,
    private http: HttpClient,
    private route:ActivatedRoute,
    private authService: AuthService) {
      this.authService.validateBusiness(this.businessID)

     }

  ngOnInit(): void {
    this.getSpinner();
  }

  getSpinner() {

    this.http.get(environment.SPINNER+'/'+this.spinnerID, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {
          this.spinner = res.message[0];
          console.log(this.spinner['answers']);
          this.pathUrl = my_protocol.protocol+'://' + base_host.base_host + my_port.port+ '/#/spinners/' + this.businessID + '/' + this.spinner.token;



        }
        else {
          var anElm = document.getElementById('notadded');
          anElm.style.display="block";
          console.log('ei ole kyseistä käyttäjää')
        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })


  }

  generateCode(){
    var style = '<style>.nset-feedbacker{width: 400px!important;}@media(max-width:500px) {.nset-feedbacker{width: 100%!important;}}</style>'



      var str = '<iframe height="400px" width="100%" src="'+this.pathUrl+'" > </iframe>';
    
    
    (<HTMLTextAreaElement>document.getElementById('code-area')).value = str;
    this.txtValue = str;
    console.log('Upotuskoodisi: '+style+'\n'+str)
  }

  selectDeletable(d){
    this.deletable = d;
  }

  removeFeedback(){

    this.http.delete(environment.REMOVE_SPINNER_ANSWER+'/'+this.spinnerID+'/'+this.deletable.answerID, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {
          document.getElementById('closeDeleteModal').click();
          alert('Palaute on poistettu onnistuneesti. Paina OK jatkaaksesi');
          this.getSpinner();

        }
        else {
          var anElm = document.getElementById('notadded');
          anElm.style.display="block";
          console.log('ei ole kyseistä käyttäjää')
        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })

  }

  removeSpinner(){

    this.http.delete(environment.SPINNER+'/'+this.spinnerID, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {
          document.getElementById('closeDeleteModalSpinner').click();
          alert('Esittely on poistettu onnistuneesti. Paina OK jatkaaksesi');
          this.router.navigate(['/'+this.businessID+'/spinners'])


        }
        else {
          var anElm = document.getElementById('notadded');
          anElm.style.display="block";
          console.log('ei ole kyseistä käyttäjää')
        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })

  }

  

}
