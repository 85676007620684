import { Component, OnInit, ElementRef, ViewChild, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment,env_data } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.css']
})
export class AdminLoginComponent implements OnInit {

  @ViewChild('recaptcha', { static: true }) recaptchaElement: ElementRef;

  user = {
    userName: '',
    password: '',
    loginError: false
  };
  errMessage = false;
  lockErr = false;
  loginForm: FormGroup;

  botErr = false;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    withCredentials: true,
  };

  constructor(
    private router: Router,
    private http: HttpClient,
    private fb: FormBuilder,
    private zone: NgZone,
    private authService: AuthService) { }


  ngOnInit(): void {
    this.addRecaptchaScript();

    this.loginForm = this.fb.group({
      userName: ['', [
 
        Validators.required,
        Validators.email, 

      ]],
      password:  ['',[
        Validators.required,
        Validators.minLength(5),

      ]]
    })
  }

  renderReCaptch() {
    window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
      'sitekey' : env_data.google_site_key, 
      'callback': (response:any) => {
          this.sendGoogleVerification(response);
      }
    });
  }

  addRecaptchaScript() {
    window['grecaptchaCallback'] = () => {
      this.renderReCaptch();
    }
 
    (function(d, s, id, obj){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { obj.renderReCaptch(); return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'recaptcha-jssdk', this));
 
  }

get userName() { return this.loginForm.get('userName') };
get password() { return this.loginForm.get('password') };
sendGoogleVerification(response) {
  var password = this.loginForm.get('password').value;
  var userName = this.loginForm.get('userName').value;

  if(password == "" || userName == "") {
    return;
  }
  if(password.length < 4) {
    return;
  }
  if(userName.lenght < 4 ) {
    return;
  }
  if(!userName.includes('@')) {
    return;
  }

  var req = {
    response:response,
    userName: userName,
    password: password,
  }

  var elm = document.getElementById('login-form');
  elm.classList.add("reg-form-success");
  console.log(elm);
  document.getElementById('loader').style.display="block";
  this.errMessage=false;
  this.lockErr = false;

  this.http.post(environment.GOOGLE_ADMIN_LOGIN_VERIFICATION, req, this.httpOptions).subscribe(
    (res: any) => { 
      console.log(res);

        if (res.status == "success") {
          
        } else if(res.status == "failed"){
          

          if(res.message == "Käyttäjätili lukittu") {
            this.lockErr = true;
            elm.classList.remove("reg-form-success");
            document.getElementById('loader').style.display="none";

            this.loginForm.get('password').setValue("");
          }
          else {
          this.errMessage = true;
          elm.classList.remove("reg-form-success");
          document.getElementById('loader').style.display="none";

          this.loginForm.get('password').setValue("");
          }
        }

        else if(res.status == 200) {
          this.authService.adminAuth(res.token);

          console.log(res.message);
          this.zone.run(() => {
            this.router.navigate(['/admin/dashboard']);
           });

        }
  
      if(res.status == 400){
        this.botErr == true;
      }
      if(res.status == "failed"){

          
      
          if(res.message == "Käyttäjätili lukittu") {
            this.lockErr = true;
            elm.classList.remove("reg-form-success");
            document.getElementById('loader').style.display="none";

            this.loginForm.get('password').setValue("");
          }
          else {
          this.errMessage = true;
          elm!.classList.remove("reg-form-success");
          document.getElementById('loader').style.display="none";

          this.loginForm.get('password').setValue("");
          }
        
      }
    
    }, (err: any) => {
        
      console.log("Error while login :: ", err);
    })

}

}
