
import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
  OnInit,
} from '@angular/core';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
  addMinutes,
  isThursday,
} from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import 'flatpickr/dist/flatpickr.css';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fi';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../service/auth.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment-timezone';
import 'moment-timezone';
registerLocaleData(localeFr);
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
  green: {
    primary: '#08e33f',
    secondary: '#08e33f',
  },
};


@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      h3 {
        margin: 0 0 10px;
      }

      pre {
        background-color: #f5f5f5;
        padding: 15px;
      }
    `,
  ],
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {

  locale: string = 'fi';

  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  view: CalendarView = CalendarView.Week;

  CalendarView = CalendarView;

  viewDate: Date = new Date();
  form: FormGroup;

  modalData: {
    action: string;
    event: CalendarEvent;
  };
  authToken = localStorage.getItem('auth');


  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'auth': `${this.authToken}`  }),
    withCredentials: true,
  };

  businessID: string = this.route.snapshot.params.businessID;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private modal: NgbModal,
    private http: HttpClient,
    private route:ActivatedRoute,
    private authService: AuthService) {
      this.authService.validateBusiness(this.businessID)

     }

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Muokkaa',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Poista',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        this.handleEvent('Deleted', event);
      },
    },
  ];

  refresh = new Subject<void>();
  events: CalendarEvent[] = [];

/*   events: CalendarEvent[] = [
    {
      start: subDays(startOfDay(new Date()), 1),
      end: addDays(new Date(), 1),
      title: 'A 3 day event',
      color: colors.red,
      actions: this.actions,
      allDay: true,
      resizable: {
        beforeStart: true,
        afterEnd: true,
      },
      draggable: true,
    },
    {
      start: startOfDay(new Date()),
      title: 'An event with no end date',
      color: colors.yellow,
      actions: this.actions,
    },
    {
      start: subDays(endOfMonth(new Date()), 3),
      end: addDays(endOfMonth(new Date()), 3),
      title: 'A long event that spans 2 months',
      color: colors.blue,
      allDay: true,
    },
    {
      start: addHours(startOfDay(new Date()), 3),
      end: addHours(startOfDay(new Date()), 0.5),
      title: 'A draggable and resizable event',
      color: colors.yellow,
      actions: this.actions,
      resizable: {
        beforeStart: true,
        afterEnd: true,
      },
      draggable: true,
    },
  ]; */

  activeDayIsOpen: boolean = true;

  get slotInterval() { return this.form.get('slotInterval') };
  get openTime() { return this.form.get('openTime') };
  get closeTime() { return this.form.get('closeTime') };
  get slotPause() { return this.form.get('slotPause') };
  get startDay() { return this.form.get('startDay') };
  get endDay() { return this.form.get('endDay') };
  get slotPrice() { return this.form.get('slotPrice') };
  ngOnInit(): void {

    this.fetchBookings();

    this.form = this.fb.group({
      slotInterval: "",
      openTime: [''],
      closeTime: [''],
      slotPause: [''],
      startDay: [''],
      endDay: [''],
      slotPrice: [''],
    }); 
  }


  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        console.log(newStart);
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    this.modal.open(this.modalContent, { size: 'lg' });
  }

  addEvent(): void {
    var obj = {
        title: 'New event',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        color: colors.red,
        actions: this.actions,
        bookingStatus: 'free',
        
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        }
    }

    this.events.push(obj);
    console.log(this.events);
/*     this.events = [
      {
        title: 'New event',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        color: colors.red,
        actions: this.actions,
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
      },
    ]; */
  }


  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }



  onSubmit(){

    var finalArr = [];

    const today = new Date()
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(6,0,0,0)
    

    
    const x = {
      endDay: new Date(this.form.get('endDay').value),
      slotInterval: parseInt(this.form.get('slotInterval').value),
      slotPause: parseInt(this.form.get('slotPause').value),
      openTime: this.form.get('openTime').value,
      closeTime: this.form.get('closeTime').value,
      startDay: new Date(this.form.get('startDay').value),
      slotPrice: parseFloat(this.form.get('slotPrice').value),
    };


    //Create startDate property
    var temp_s = x.openTime.split(':');
    var final_start_hours = temp_s[0];
    var final_start_mins = temp_s[1];
    var temp_e = x.closeTime.split(':');
    var final_end_hours = temp_e[0];
    var final_end_mins = temp_e[1];


    const finalEnd = x.endDay.setHours(final_end_hours,final_end_mins,0);
    const finalStart = x.startDay.setHours(final_start_hours,final_start_mins,0);

    //Create end date property
    let cust_edate = x.endDay.setHours(final_end_hours, final_end_mins,0);
    console.log(cust_edate, 'päivä');

    var end_w_opentime = x.endDay.setHours(final_start_hours, final_start_mins,0);

    //Count days between start and end
    var date1_unixtime = finalStart / 1000;
    var date2_unixtime = end_w_opentime / 1000;

    var timeDifference = date2_unixtime - date1_unixtime;

    var timeDifferenceInDays = Math.round(timeDifference  / 24 / 60 / 60);
    

    
    //Format the time
    let startTime = moment(x.openTime, "HH:mm");
    
    //Format the end time and the next day to it 
    let endTime = moment(x.closeTime, "HH:mm").add(0, 'days');
    
    //Times
    let allTimes = [];
    
    //Loop over the times - only pushes time with 30 minutes interval
    while (startTime < endTime) {
      //Push times
      allTimes.push(startTime.format("HH:mm")); 
      //Add slot pause
      startTime.add(x.slotPause, 'minutes');
      //Add interval of 30 minutes
      startTime.add(x.slotInterval, 'minutes');
    }
    if(timeDifferenceInDays == 0){
      var repeatNumber = 1;
    }
    else{
    var repeatNumber = timeDifferenceInDays +1;
    }

    for (let j = 1; j < repeatNumber+1; j++) {
      const num = j;

      if(j == 1){
        var editedDate = new Date(finalStart);
      }
      if(num > 1){
        editedDate = addDays(finalStart, num-1);
      }
      
      for (let i = 0; i < allTimes.length; i++) {
        const time = allTimes[i];


        var time_arr = time.split(':');
        var time_hours = parseInt(time_arr[0]);
        var time_mins = parseInt(time_arr[1]);

        if(i == 0){
          var e = editedDate.setHours(time_hours, time_mins);
          editedDate = new Date(e);

        }

        var obj = {
          title: 'Vapaa aika',
          start: new Date(editedDate),
          end: new Date(addMinutes(editedDate, x.slotInterval)),
          color: colors.green,
          actions: this.actions,
          bookingStatus: 'free',
          slotPrice: x.slotPrice,
          time_mins: x.slotInterval,
          
          draggable: true,
          resizable: {
            beforeStart: true,
            afterEnd: true,
          }
      }

      finalArr.push(obj);
      editedDate = addMinutes(editedDate, x.slotInterval)
      console.log(obj.start, " - ", obj.end);
      
        
      }
    }

    this.sendBookingDataToServer(finalArr);
  }

  sendBookingDataToServer(events){
    var req = {
      events: events,
    }

    this.http.post(environment.SLOT_BOOKINGS,req, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {
          
          this.events.push(events);
          this.refresh.next();
        }
        else {
          var anElm = document.getElementById('notadded');
          anElm.style.display="block";
          console.log('ei ole kyseistä käyttäjää')
        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })
  }

  fetchBookings(){


    this.http.get(environment.ALL_BOOKINGS, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {

          for (let i = 0; i < res.message.length; i++) {
            const event = res.message[i];

            console.log(event);
            var d = new Date(event.start);
            var e = new Date(event.end);
            event.start = d;
            event.end = e;
            
          }
          this.events = res.message;
          this.refresh.next();
        }
        else {

        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })
  }





}
