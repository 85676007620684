<div class=" chat-window-container h-100" id="full-chat-wrap">
      <div class="row col-12 m-0 three-sec-wrap row-container" id="chat-wrap">
    
        <!--Section one :: started-->
<!--         <div class="col-md-8 col-lg-4 link-container section-one color-white korkeus p-0 section sidebar-sec-one " id="mySidenav">
          <div class="bg-white col-12 d-inline-flex">
            <div class="bg-white ms-2">
              <i class="fa fa-times fa-icon close-fa burger-left closeNav" (click)="closeNav()" aria-hidden="true"></i>
  
            </div>
    

        </div>
  

        <div class="text-center">
  
        </div>
        </div> -->
        <!--Section one :: END-->
    
        <!--Section two :: started-->
        <div class="col-md-12 col-lg-12 section-two color-white korkeus p-0 section main-container" id="section-two-container">
    
          <!-- header container : end-->
    
    
          <div id="chat-window" class=" chat-window-main-container w-100 col-md-12 ps-1 pe-1" [ngClass]="{'chat-window-main-container-no-replybox': !showReplyBox}">
    
    
  
            <div class="chat-messages-container pb-4 m-0" id="main-chat-container" #chatMessageContainer (scroll)="onScroll()">
              
<!--                   <div id="start-create-pet" class="close-bubbles col-md-12 no-padding start-row">
                      <div class="row m-0">
                        <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                        <div class="text-message text-message-left helper-message whitespace-preline">
                          <p class="body1">Aloita tästä</p>
      
                          <div class="">
                              <button class="btn btn-secondary" (click)="startQuestionnaire()">Aloita</button>
                            </div>
                        </div>
                          <br />
                        </div>
                      </div>
                    </div> -->
  
              <div class="row m-0 message-container" *ngFor="let message of currentActiveMessageList">
    
                <!-- ALOITUS LÄÄKÄRIT -->
    
    

  
                  <!-- TEMPLATE 202 CRONICAL ILLS -->
                  <div class="col-md-12 no-padding" *ngIf=" message.template == 'TEMPLATE_202' " [ngClass]="{'disabled-input': message.oldMessage}">
                  <div class="row m-0">
    
                    <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                      <div class="text-message text-message-left whitespace-preline">
                        
                        
                        <p class="text-message-left-text"><span *ngIf="!message.question;else other_text">{{message.actMessage}}</span>
                            
                            <ng-template #other_text>{{message.question}}</ng-template></p>
                        
                        <div class="col-12 mt-2" *ngFor="let ans of message.answers">
                        <button class="btn btn-secondary" [disabled]="message.oldMessage" (click)="!message.oldMessage && setAnswer(message,ans.name) && OnAnswerSelected(message) ; message.oldMessage = true"
                        [ngClass]="{selectedButton: message.answer == ans.name }">{{ans.name}}</button>
                        </div>
                      </div>
                      </div>
                  </div>
                </div>
  
                <!-- TEMPLATE 202 ANSWER CHRONICAL ILLS  -->
                <div class="col-md-12 p-0" *ngIf=" message.template == 'TEMPLATE_202' && message.answer != null && message.oldMessage == true  " [ngClass]="{'disabled-input': message.oldMessage}">
                  <div class="row m-0">
                    <div class="col-md-7 col-3">
                      <p class="d-none">To take some space</p>
                    </div>
                    <div class="col-md-5 col-9">
                      <div class="d-flex justify-content-end">
                        <div class="text-message text-message-right me-2">
                          <p class="body2-w">{{message.answer}}</p>
                        </div>
                      </div>
                    </div>
  
                  </div>
                </div>
  
                <!-- TEMPLATE 9 TELL MORE -->
                <div class="col-md-12 no-padding" *ngIf=" message.template == 'TEMPLATE_203' " [ngClass]="{'disabled-input': message.oldMessage}">
                  <div class="row m-0">
    
                    <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                      <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                      <div class="text-message text-message-left whitespace-preline">
                        <div class="text-message-left-text" [innerHtml]="message.actMessage"></div>
                        
                          <textarea type="text" class="form-control more-symptom-txt whitespace-preline" placeholder="Kirjoita tähän..." [(ngModel)]="message.answer" [readonly]="message.oldMessage"></textarea><br>
                          <button class="btn btn-secondary" [disabled]="message.oldMessage" (click)="!message.oldMessage && OnAnswerSelected(message) ; message.oldMessage = true">Valmis</button>
      
                      </div>
                      </div>
                  </div>
                </div>
    
                <!-- TEMPLATE ANSWER 9 TELL MORE -->
                <div class="col-md-12 p-0" *ngIf=" message.template == 'TEMPLATE_9' && message.answer != null && message.oldMessage == true  " [ngClass]="{'disabled-input': message.oldMessage}">
                  <div class="row m-0">
                    <div class="col-md-7 col-3">
                      <p class="d-none">To take some space</p>
                    </div>
                    <div class="col-md-5 col-9 p-0">
                      <div class="d-flex justify-content-end p-0">
                      <div class="text-message text-message-right me-2">
                          <p class="body2-w break-word">{{message.answer}}</p>
    
                        </div>
                      </div>
                    </div>
  
                  </div>
                </div>
                  
    
                <!-- TEMPLATE 10 ANSWER CHRONICAL ILLS  -->
                <div class="col-md-12 p-0" *ngIf=" message.template == 'TEMPLATE_10' && message.answer != null && message.oldMessage == true  " [ngClass]="{'disabled-input': message.oldMessage}">
                  <div class="row m-0">
                    <div class="col-md-7 col-3">
                      <p class="d-none">To take some space</p>
                    </div>
                    <div class="col-md-5 col-9">
                      <div class="d-flex justify-content-end">
                        <div class="text-message text-message-right me-2">
                          <p class="body2-w">{{message.answer}}</p>
                        </div>
                      </div>
                    </div>
  
                  </div>
                </div>
    
                <!-- TEMPLATE 11 Lääkitys -->
                <div class="col-md-12 pb-2 no-padding"  *ngIf=" message.template == 'TEMPLATE_6' " [ngClass]="{'disabled-input': message.oldMessage}">
                  <div class="row m-0">
       
                    <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                      <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                      <div class="text-message text-message-left whitespace-preline">
      
                        <p class="text-message-left-text">Onko lemmikilläsi jokin pysyvä lääkitys? Onko lemmikki saanut kuukauden sisällä lääkekuureja? Jos on, niin mitä?</p>                   
                        <textarea type="text" class="form-control more-symptom-txt" placeholder="Kirjoita tähän..." [(ngModel)]="message.answer" [readonly]="message.oldMessage"></textarea><br>
                        <button class="btn btn-secondary" [disabled]="message.oldMessage" (click)="!message.oldMessage && setAnswer(message,'Ei ole lääkitystä') && OnAnswerSelected(message) ; message.oldMessage = true"
                        [ngClass]="{selectedButton: message.answer == 'Ei ole lääkitystä' }">Ei ole lääkitystä</button><br><br>
                        <button class="btn btn-secondary" [disabled]="message.oldMessage" (click)="!message.oldMessage && OnAnswerSelected(message) ; message.oldMessage = true">Lähetä</button>
      
                      </div>
                      </div>
                  </div>
                </div>
                
      
                <!-- TEMPLATE 11 ANSWER CHRONICAL ILLS  -->
                <div class="col-md-12 p-0" *ngIf=" message.template == 'TEMPLATE_6' && message.answer != null && message.oldMessage == true  " [ngClass]="{'disabled-input': message.oldMessage}">
                  <div class="row m-0">
                    <div class="col-md-7 col-3">
                      <p class="d-none">To take some space</p>
                    </div>
                    <div class="col-md-5 col-9">
                      <div class="d-flex justify-content-end">
                        <div class="text-message text-message-right me-2">
                          <p class="body2-w">{{message.answer}}</p>
                          <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                        </div>
                      </div>
                    </div>
    
                  </div>
                </div>
    
    
                <!-- TEMPLATE 12 Basic -->
                <div class="col-md-12 no-padding" *ngIf=" message.template == 'TEMPLATE_12' " [ngClass]="{'disabled-input': message.oldMessage}">
                  <div class="row m-0">
  <!--                   <div class="p-0">
                      <div class="avatar-icon-chat mt-4">
                        <img src="./../../assets/images/chat2Vet_some.png">
                        
                      </div>
                    </div> -->
      
                    <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                      <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                      <div class="text-message text-message-left whitespace-preline">
                        <p class="body3">{{message.actMessage | newlineHtml}}</p>
                          <br />
                        </div>
                        <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p>
      
                      </div>
                  </div>
                </div>
    
    
                <div class="col-md-12 p-0 mb-2" *ngIf=" message.template == 'TEMPLATE_13' ">
                  <div class="row m-0">
                    <div class="col-md-7 col-3">
                      <p class="d-none">To take some space</p>
                    </div>
                    <div class="col-md-5 col-9 p-0">
                      <div class="d-flex justify-content-end p-0">
                        <div class="text-message text-message-right whitespace-preline me-2">
  <!--                         <div *ngIf="message.actMessage.substring(0,10) == 'data:image';else teksti_viesti">
                            <img class="kuvaviesti" src="{{message.actMessage}}" alt="">
                          </div>
                          <ng-template #teksti_viesti> -->
                            <p class="body2-w mb-1 break-word">{{message.actMessage | newlineHtml}}</p>
       <!--                    </ng-template> -->
                          <p class="text-message-time text-end">{{message.createdDate | chatTimeIndicator}}</p>
  
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 m-0 ">
                </div>
                </div>
  
  
                <div class="col-md-12 p-0 mb-2" *ngIf=" message.template == 'TEMPLATE_33' ">
                  <div class="row m-0">
                    <div class="col-md-7 col-3">
                      <p class="d-none">To take some space</p>
                    </div>
                    <div class="col-md-5 col-9 p-0">
                      <div class="d-flex justify-content-end p-0">
                      <div class="text-message text-message-right whitespace-preline me-2">
                      <div>
                        <div class="image-container">
                          <img class="kuvaviesti" src="{{fileURL}}/{{message.actMessage.key}}" alt="">
                          <div class="overlay-image">
                            <a  (click)="openImageEnlarger(message.actMessage.key)" class="icon-image-en" title="Lähennä kuvaa">
                              <i class="fas fa-search-plus"></i>
                            </a>
                          </div>
                        </div>
    
                    </div>                      
                        <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                      </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 m-0 ">
                  </div>
                  </div>
  
                  <div class="col-md-12 p-0 mb-2" *ngIf=" message.template == 'TEMPLATE_43' ">
                    <div class="row m-0">
                      <div class="col-md-7 col-3">
                        <p class="d-none">To take some space</p>
                      </div>
                      <div class="col-md-5 col-9 p-0">
                        <div class="d-flex justify-content-end p-0">
                        <div class="text-message text-message-right whitespace-preline me-2">
                        <div>
                          <video class="kuvaviesti" controls alt="">
                            <source src="{{message.actMessage.location}}#t=0.001">  
                          </video>
      
                      </div>                      
                          <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                        </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 m-0 ">
                    </div>
                  </div>
  
                <div class="col-md-12" *ngIf=" message.template == 'TEMPLATE_34' ">
                  <div class="row m-0">
                    <div class="p-0 mt-4 col-0-nset">
                      <div class="avatar-icon-chat">
                        <div class="" *ngIf="activeConsultantData && activeConsultantData.wantedImage">
  
                        <img src="{{fileURL}}/{{this.activeConsultantData['wantedImage']['key']}}">
                        </div>
                      </div>
                    </div>
    
                    <div class="col-10 col-md-6 col-lg-6 col-xl-6 p-0 m-1">
                      <p class = "body3b m-0">Eläinlääkäri {{this.activeConsultantData['firstName']}}</p>
                      <div class="d-flex justify-content-start p-0">
                        <div class="text-message text-message-left whitespace-preline">
                          <div class="image-container">
                            <img class="kuvaviesti" src="{{fileURL}}/{{message.actMessage.key}}" alt="">
                            <div class="overlay-image">
                              <a  (click)="openImageEnlarger(message.actMessage.key)" class="icon-image-en" title="Lähennä kuvaa">
                                <i class="fas fa-search-plus"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-1 col-lg-5 col-xl-5"></div>
                    <p class="text-message-time-left mt-2 ms-2 ps-3">{{message.createdDate | chatTimeIndicator}}</p>
    
                  </div>
                </div>
                
    
                <div class="col-md-12" *ngIf=" message.template == 'TEMPLATE_44' ">
                  <div class="row m-0">
                    <div class="p-0 mt-4 col-0-nset">
                      <div class="avatar-icon-chat">
                        <div class="" *ngIf="activeConsultantData && activeConsultantData.wantedImage">
                          <img src="{{fileURL}}/{{this.activeConsultantData['wantedImage']['key']}}">
                        </div>                      
                      </div>
                    </div>
    
                    <div class="col-10 col-md-6 col-lg-6 col-xl-6 p-0 m-1">
                      <p class = "body3b m-0">Eläinlääkäri {{this.activeConsultantData['firstName']}}</p>
                      <div class="d-flex justify-content-start p-0">
                        <div class="text-message text-message-left whitespace-preline">
                          <div>
                            <video class="kuvaviesti" controls alt="">
                              <source src="{{message.actMessage.location}}#t=0.001">  
                            </video>
                        </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-1 col-lg-5 col-xl-5"></div>
                    <p class="text-message-time-left mt-2 ms-2 ps-3">{{message.createdDate | chatTimeIndicator}}</p>
    
                  </div>
                </div>
  
                <div id="message-id-{{message._id}}" class="col-md-12 p-0" *ngIf=" message.template == 'TEMPLATE_54' ">
                  <div class="row m-0">
                    <div class="p-0 mt-4 col-0-nset">
                      <div class="avatar-icon-chat">
                        <div class="" *ngIf="activeConsultantData && activeConsultantData.wantedImage">
  
                        <img src="{{fileURL}}/{{this.activeConsultantData['wantedImage']['key']}}">
                        </div>
                      </div>
                    </div>
                    <div class="col-10 col-md-6 col-lg-6 col-xl-6 p-0 m-1">
                      <p class = "body3b m-0">Eläinlääkäri {{this.activeConsultantData['firstName']}}</p>
                      <div class="d-flex justify-content-start p-0">
                        <div class="text-message text-message-left whitespace-preline me-2">
                        <div class="d-flex justify-content-center">
                          <i *ngIf="message.actMessage.mimetype == 'application/pdf'" class="fas fa-file-pdf att-icon pdf-icon"></i>
                          <i *ngIf="message.actMessage.mimetype == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'" class="fas fa-file-word att-icon word-icon"></i>
                          <i *ngIf="message.actMessage.mimetype == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'" class="fas fa-file-excel att-icon xls-icon"></i>
                          <i *ngIf="message.actMessage.mimetype == 'text/plain'" class="fas fa-file-alt att-icon icon-txt"></i>
                      </div>
                      <br>
                      <p class="body3-b">{{message.actMessage.originalname}}</p>
                      <div class="text-center">
                        <button class="btn btn-secondary" (click)="this.fileUploaderService.downloadAttachment(message.actMessage)"><i class="fas fa-download me-2"></i>Lataa</button>
  
                      </div>
                    </div>
    
                  </div>
                  </div>
                  </div>
                  </div>
    
                <div class="col-12 no-padding" (scroll)="onScroll()" *ngIf=" message.template == 'TEMPLATE_14' ">
                  <div class="col-md-12 p-0">
                  <div class="row m-0">
                    <div class="p-0 mt-4 col-0-nset">
                      <div class="avatar-icon-chat">
                        <div class="" *ngIf="activeConsultantData && activeConsultantData.wantedImage">
                          <img src="{{fileURL}}/{{this.activeConsultantData['wantedImage']['key']}}">
                        </div>
                      </div>
                    </div>
    
                    <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 mt-2 ms-2 me-2 mb-0">
                      <p class = "body3b mb-1">Eläinlääkäri {{this.activeConsultantData['firstName']}}</p>
                      <div class="d-flex justify-content-start p-0">
                      <div class="text-message text-message-left-doctor whitespace-preline">
  <!--                       <div *ngIf="message.actMessage.substring(0,10) == 'data:image';else teksti_viesti">
                          <img class="kuvaviesti" src="{{message.actMessage}}" alt="">
                      </div> -->
  
                        <div >
                          <div [innerHtml]="message.actMessage" class="body2 break-word text-container"></div>
                        </div>
  
  
                      </div>
                    </div>
                    </div>
                    <div class="col-1 col-lg-5 col-xl-6"></div>
                  </div>
                </div>
                <div class="col-12 p-0 m-0 row ps-4">
  
                  <p class="text-message-time-left mt-2 ms-4 mb-0">{{message.createdDate | chatTimeIndicator}}</p>
  
                </div>
                </div>
  
  <!--               <div class="col-12 no-padding" *ngIf="globalMessages[currentActiveDoctorName]?.typing">
                  <div class="col-md-12 p-0">
                  <div class="row m-0 ">
                    <div class="p-0 mt-4">
                      <div class="avatar-icon-chat">
                        <img src="{{this.activeConsultantData['wantedImage']}}">
                        
                      </div>
                    </div>
    
                    <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                      <p class = "body3b mb-1">Eläinlääkäri {{this.activeConsultantData['firstName']}}</p>
                      <div class="text-message text-message-left-doctor whitespace-preline">
  
                        <div class="body1 break-word">Kirjottaa..</div>
                      </div>
                    </div>
                    <div class="col-1 col-lg-5 col-xl-6"></div>
                  </div>
                </div>
                <div class="col-12 p-0 m-0 row ps-4">
  
                  <p class="text-message-time-left mt-2 ms-4">{{message.createdDate | chatTimeIndicator}}</p>
  
                </div>
                </div> -->
  
                  
  
    
                <div class="message-template-1 row col-12" *ngIf=" message.template == 'TEMPLATE_15' ">
                  <div class="col-3"><hr class="hr-chat"></div>
                  <div class="col-6"><p class="mt-1 mb-0 body2">Eläinlääkäri lopetti keskustelun</p></div>
                  <div class="col-3"><hr class="hr-chat"></div>
                </div>
  
                <div class="message-template-1 row col-12" *ngIf=" message.template == 'TEMPLATE_16' ">
                  <div class="col-3"><hr class="hr-chat"></div>
                  <div class="col-6"><p class="mt-1 mb-0 body2b-b">Eläinlääkäri siirsi sinut takaisin jonoon</p></div>
                  <div class="col-3"><hr class="hr-chat"></div>
                </div>
  
                <div class="message-template-1 row col-12" *ngIf=" message.template == 'TEMPLATE_24' ">
                  <div class="col-3"><hr class="hr-chat"></div>
                  <div class="col-6"><p class="mt-1 mb-0 body2b">Tunnistautumisesi onnistui</p></div>
                  <div class="col-3"><hr class="hr-chat"></div>
                </div>
  
                <div class="message-template-1 row col-12" *ngIf=" message.template == 'TEMPLATE_32' ">
                  <div class="col-3"><hr class="hr-chat"></div>
                  <div class="col-6"><p class="mt-1 mb-0 body2b">Tunnistautumisesi epäonnistui. Pyydä eläinlääkäriltä uusi linkki.</p></div>
                  <div class="col-3"><hr class="hr-chat"></div>
                </div>
  
                <div class="message-template-1 row col-12" *ngIf=" message.template == 'TEMPLATE_27' ">
                  <div class="col-3"><hr class="hr-chat"></div>
                  <div class="col-6"><p class="mt-1 mb-0 body2b">Valitsit apteekin: {{message.actMessage.name}}</p></div>
                  <div class="col-3"><hr class="hr-chat"></div>
                </div>
  
                <div id="message-id-{{message._id}}" class="col-md-12 p-0" *ngIf=" message.template == 'TEMPLATE_26' ">
                  <div class="row m-0">
                    <div class="p-0 mt-4 col-0-nset">
                      <div class="avatar-icon-chat">
                        <div class="" *ngIf="activeConsultantData && activeConsultantData.wantedImage">
  
                        <img src="{{fileURL}}/{{this.activeConsultantData['wantedImage']['key']}}">
                        </div>
                      </div>
                    </div>
                    <div class="col-10 col-md-6 col-lg-6 col-xl-6 p-0 m-1">
                      <p class = "body3b m-0">Eläinlääkäri {{this.activeConsultantData['firstName']}}</p>
                      <div class="d-flex justify-content-start p-0">
                        <div class="text-message text-message-left whitespace-preline me-2">
                          <p class="text-message-left-text">Valitse haluamasi apteekki:</p>
                          <div class="text-center">
                            <button (click)="setPharmacyData()" class="btn btn-secondary"><i class="fas fa-clinic-medical white-icon me-2"></i>Valitse</button>
                          </div>
                      <br>
  
                    </div>
    
                  </div>
                  </div>
                  </div>
                  </div>
    
                  <!-- TEMPLATE 10 CRONICAL ILLS --> <!-- message.template == 'TEMPLATE_19 -->
                  <div class="col-md-12 no-padding" *ngIf=" message.template == 'TEMPLATE_19' " [ngClass]="{'disabled-input': message.oldMessage}">
                    <div class="row m-0">
                
                      <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                        <p class = "body3b mb-1">Palautekysely</p>
                        <div class="text-message text-message-left whitespace-preline">
      
                          <p class="body2b-b">Kuinka onnistuimme tänään?</p>
                          <div class="d-flex col-12 m-0 p-0 row mb-2">
                            <div class="d-flex col-4 justify-content-start">
                              <i class="far fa-frown sad-smile fa-2x"></i>
                            </div>
                            <div class="d-flex col-4 p-0 justify-content-center">
                              <i class="far fa-meh meh-smile fa-2x"></i>
                            </div>
                            <div class=" col-4 p-0 d-flex justify-content-end">
                              <i class="far fa-smile fa-2x"></i>
                            </div>
                          </div>  
                          <p class="text-message-left-text"><i class="gray-italic">Antamani arvosana <span *ngIf="customerNPSRange != -1;else feedback_added">{{customerNPSRange}}/10</span> <ng-template #feedback_added><span>5/10</span></ng-template> (liu'uta)</i></p>
                                      
                          <div id="feedback-container" class="feedback-container">
                            <form [formGroup]="addFeedback"
                              (ngSubmit)="AddFeedBack()">
                              <div class="form-group">
                                <div class="slidecontainer">
                                  <input 
                                    (ngModelChange)="changeNpsRangeValue()"
                                    type="range" 
                                    min="0" 
                                    formControlName="npsRange"
                                    max="10"
                                    value="9"
                                    class="slider" 
                                    id="npsRange-{{message.id}}">
                                </div>
                              </div>
                              <div class="form-group">
                                <p class="text-message-left-text">Kerro hieman sanallisesti. </p>
                                <textarea 
                                  type="text" 
                                  class="form-control"
                                  formControlName="npsText"
                                  placeholder="Kirjoita vapaamuotoinen palautteesi tähän..."
                                  id="npsText-{{message.id}}" ></textarea>
                              </div>
                              <div class="form-group col-12 row mt-2">
                                <div class="col-10">
                                  <p class="text-message-left-text">Minuun saa olla yhteydessä palautteen tiimoilta.</p>
                                </div>
                                <div class="col-2 mt-3">
                                  <div class="squaredThree">
                                    <input id="acceptedContact-{{message.id}}" type="checkbox" class="unique-class" value="checked"/>
                                    <label for="acceptedContact-{{message.id}}"></label>
                                  </div>  
                                </div>
                              </div>
                              <div class="col-12 ps-0">
                                <button class="btn btn-secondary mb-3" [disabled]="addFeedback.invalid">Lähetä</button>
                              </div>          
                            <div class="col-12 ps-0 mb-3">
                              <a class="pt-4 pointer" (click)="changeSessionStatus()">En anna palautetta</a>
                            </div>
                          </form>
                        </div>
                        <div id="after-feedback-container" class="redirect-info">
                          <div class="alert alert-success">
                            <p>Kiitos, Palautteen antaminen onnistui! Sinut ohjataan automaattisesti aloitusnäkymään 10 sekunnin päästä.</p>
                            <button class="btn btn-secondary" (click)="changeSessionStatus()">Takaisin alkuun</button>
                          </div>
                        </div>
        
                        </div>
                          <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p>
                        </div>
                    </div>
                </div>
  
                <!-- TEMPLATE 20 Credit usage? -->
                <div class="col-md-12 no-padding" *ngIf=" message.template == 'TEMPLATE_20' " [ngClass]="{'disabled-input': message.oldMessage}">
                  <div class="row m-0">
    
                    <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                      <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                      <!-- <p class = "doctor-desigantion-text"style="color: black;">{{this.listUserRole}}</p> -->
                      <div class="text-message text-message-left whitespace-preline">
    
                        <p class="text-message-left-text">{{message.actMessage}}</p>
                        
                        
                        <button class="btn btn-secondary me-4" [disabled]="message.oldMessage" (click)="!message.oldMessage && setAnswer(message,true) && OnAnswerSelected(message) ; message.oldMessage = true"
                        [ngClass]="{selectedButton: message.answer == true }">Käytetään maksettua käyntiä</button>
    
                        <button class="btn btn-secondary mt-3" [disabled]="message.oldMessage" (click)="!message.oldMessage && setAnswer(message, false) && OnAnswerSelected(message) ; message.oldMessage = true"
                          [ngClass]="{selectedButton: message.answer == false }">Haluan ostaa uuden käynnin</button>
      
                      </div>
                        <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p>
                      </div>
                  </div>
                </div>
  
              <!-- TEMPLATE 20 ANSWER Which credit to use  -->
              <div class="col-md-12 p-0" *ngIf=" message.template == 'TEMPLATE_20' && message.answer != null && message.oldMessage == true  " [ngClass]="{'disabled-input': message.oldMessage}">
                <div class="row m-0">
                  <div class="col-md-7 col-3">
                    <p class="d-none">To take some space</p>
                  </div>
                  <div class="col-md-5 col-9">
                    <div class="d-flex justify-content-end p-0">
                      <div class="text-message text-message-right me-2">
                        <div class="col p-0">
                      <div *ngIf="message.answer == true;else not_true">
                        <p class="body2-w">Käytetään maksettua käyntiä</p>
                      </div>
                      <ng-template #not_true>
                        <p class="body2-w">Haluan ostaa uuden käynnin</p>
                      </ng-template>
                      <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                    </div>
                    </div>
                    </div>
                  </div>
    
                </div>
              </div>
  
             <!-- TEMPLATE 23 Lemmikki kastroitu -->
             <div class="col-md-12 no-padding" *ngIf=" message.template == 'TEMPLATE_23' " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
  <!--                 <div class="p-0">
                  <div class="avatar-icon-chat mt-4">
                    <img src="./../../assets/images/chat2Vet_some.png">
                    
                  </div>
                </div> -->
  
                <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                  <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                  <div class="text-message text-message-left whitespace-preline">
  
                    <p class="text-message-left-text">Onko lemmikki kastroitu tai steriloitu?</p>
                    
                    <div class="d-flex justify-content-between">
                    <button class="btn btn-secondary me-4" [disabled]="message.oldMessage" (click)="!message.oldMessage && setAnswer(message,true) && OnAnswerSelected(message) ; message.oldMessage = true"
                    [ngClass]="{selectedButton: message.answer == 'Kyllä' }">Kyllä</button>
  
                    <button class="btn btn-secondary mt-0" [disabled]="message.oldMessage" (click)="!message.oldMessage && setAnswer(message,false) && OnAnswerSelected(message) ; message.oldMessage = true"
                      [ngClass]="{selectedButton: message.answer == 'Ei' }">Ei</button>
                    </div>
  
                  </div>
                  <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p>
  
                  </div>
              </div>
            </div>
  
          <!-- TEMPLATE 21 ANSWER agreement  -->
          <div class="col-md-12 p-0" *ngIf=" message.template == 'TEMPLATE_23' && message.answer != null && message.oldMessage == true  " [ngClass]="{'disabled-input': message.oldMessage}">
            <div class="row m-0">
              <div class="col-md-7 col-3">
                <p class="d-none">To take some space</p>
              </div>
              <div class="col-md-5 col-9 p-0">
                <div class="d-flex justify-content-end p-0">
                <div class="text-message text-message-right me-2">
                  <div *ngIf="message.answer == true;else not_true">
                    <p class="body2-w">Kyllä</p>
                  </div>
                  <ng-template #not_true>
                    <p class="body2-w">Ei</p>
                  </ng-template>
                  <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                </div>
                </div>
              </div>
  
  <!--               <div class="p-0 d-flex align-items-end">
                <div class="avatar-icon-chat">
                  <img src="./../../assets/images/chat2Vet_some.png">
                </div>
              </div> -->
            </div>
          </div>
  
                <!-- TEMPLATE 21 Agreement with another doctor? -->
                <div class="col-md-12 no-padding" *ngIf=" message.template == 'TEMPLATE_4' " [ngClass]="{'disabled-input': message.oldMessage}">
                  <div class="row m-0">
  <!--                   <div class="p-0">
                      <div class="avatar-icon-chat mt-4">
                        <img src="./../../assets/images/chat2Vet_some.png">
                        
                      </div>
                    </div> -->
    
                    <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                      <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                      <div class="text-message text-message-left whitespace-preline">
    
                        <p class="text-message-left-text">Olitteko sopineet ajasta eläinlääkärin kanssa?</p>
                        
                        
                        <button class="btn btn-secondary me-4" [disabled]="message.oldMessage" (click)="!message.oldMessage && setAnswer(message,true) && OnAnswerSelected(message) ; message.oldMessage = true"
                        [ngClass]="{selectedButton: message.answer == true }">Kyllä olimme</button>
    
                        <button class="btn btn-secondary mt-3 mt-md-3 mt-xl-3" [disabled]="message.oldMessage" (click)="!message.oldMessage && setAnswer(message,false) && OnAnswerSelected(message) ; message.oldMessage = true"
                          [ngClass]="{selectedButton: message.answer == false }">Emme ole sopineet</button>
                        
      
                      </div>
                      <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p>
    
                      </div>
                  </div>
                </div>
  
              <!-- TEMPLATE 21 ANSWER agreement  -->
              <div class="col-md-12 p-0" *ngIf=" message.template == 'TEMPLATE_4' && message.answer != null && message.oldMessage == true  " [ngClass]="{'disabled-input': message.oldMessage}">
                <div class="row m-0">
                  <div class="col-md-7 col-3">
                    <p class="d-none">To take some space</p>
                  </div>
                  <div class="col-md-5 col-9 p-0">
                    <div class="d-flex justify-content-end p-0">
                    <div class="text-message text-message-right me-2">
                      <div *ngIf="message.answer == true;else not_true">
                        <p class="body2-w">Kyllä olimme</p>
                      </div>
                      <ng-template #not_true>
                        <p class="body2-w">Emme ole sopineet</p>
                      </ng-template>
                      <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
  
                <!-- TEMPLATE 22 Agreement with another doctor? -->
                <div class="col-md-12 no-padding" *ngIf=" message.template == 'TEMPLATE_22' " [ngClass]="{'disabled-input': message.oldMessage}">
                  <div class="row m-0">
  <!--                   <div class="p-0">
                      <div class="avatar-icon-chat mt-4">
                        <img src="./../../assets/images/chat2Vet_some.png">
                        
                      </div>
                    </div> -->
    
                    <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                      <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                      <div class="text-message text-message-left whitespace-preline">
    
                        <p class="text-message-left-text">{{message.actMessage}}</p>
                        
                        
                        <ul *ngIf="activeDoctors.length != 0;else no_doctors" class="list-group" id="menu">
                          <div class="p-list" *ngFor="let doc of activeDoctors">
          
                              <button class="list-group-item package-list-item list-group-item-link pt-3 col-12 ps-0 pe-0 pe-md-2 mb-2" [disabled]="message.oldMessage" (click)="!message.oldMessage && setAnswer(message,doc._id) && OnAnswerSelected(message) ; message.oldMessage = true"
                              [ngClass]="{selectedButton: message.answer == doc._id }">
                                <div class=" ps-0 pe-0 list-wrap-closed list-package-name row m-0  ">
                                  <div class="col-12 pe-1 ps-1 row m-0">
                                    <div class="col-2 col-md-1 pe-0 ps-0 ps-md-2 pe-md-2">
                                      <div class="img-cont" *ngIf="doc.image != null;else standard_image">
                                        <img class="small-pic-doc mt-1" src="{{fileURL}}/{{doc.image.key}}" alt="eläinlääkäri">
                                      </div>
                                      <ng-template #standard_image>
                                        <img class="small-pic-doc mt-1" src="../../assets/images/chat2Vet_some.png" alt="lemmikkisi">
                                      </ng-template>
                                    </div>
                                    <div class="col-9 ps-0 ps-md-2 text-start">
                                      <div class="d-flex flex-column">
                                        <div class="doc-name">
                                          <p class="ms-2 doc-show-text body214bb"> {{ doc.name }}</p>
                                        </div>
                                        <div class="doc-designation">
                                          <p class="ms-2 doc-show-text body2"> {{ doc.designation }}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
      
                                </div>
                              
                              
                            </button>
  
  
                            </div>
                          </ul>
                          <ng-template #no_doctors>
                            <div class="alert alert-warning">
                              <p>Eikä! Valitettavasti yhtään eläinlääkäriä ei ole paikalla. Asiasta ilmoitettu johtavalle eläinlääkärille. Voit jatkaa huolettomasti eteenpäin kyselyssä.  
                              </p>
                              <button class="btn btn-secondary" [disabled]="message.oldMessage" (click)="!message.oldMessage && setAnswer(message,false) && OnAnswerSelected(message) ; message.oldMessage = true"
                              [ngClass]="{selectedButton: message.answer == false }">OK</button>
                            </div>
                          </ng-template>
      
                      </div>
                      <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p>
                      </div>
                  </div>
                </div>
  
              <!-- TEMPLATE 22 ANSWER doctor  -->
              <div class="col-md-12 p-0" *ngIf=" message.template == 'TEMPLATE_22' && message.answer != null && message.oldMessage == true  " [ngClass]="{'disabled-input': message.oldMessage}">
                <div class="row m-0">
                  <div class="col-md-7 col-3">
                    <p class="d-none">To take some space</p>
                  </div>
                  <div class="col-md-5 col-9 p-0">
                    <div class="text-message text-message-right me-2">
                      <p class="body2-w">Valitsemasi eläinlääkäri</p>
                      <div  *ngFor="let doc of activeDoctors">
                        <div *ngIf="doc._id == message.answer">
                          <li class="list-group-item list-group-item-link pt-3 mb-2 active-doctors-wrap ">
                            <div class="list-wrap-closed d-inline-flex ">
                              <div class="img-cont" *ngIf="doc.image != null && doc.image.key != null;else standard_image">
                                <img class="small-pic-doc mt-1" src="{{fileURL}}/{{doc.image.key}}" alt="lemmikkisi">
                              </div>
                              <ng-template #standard_image>
                                <img class="small-pic-doc mt-1" src="../../assets/images/chat2Vet_some.png" alt="lemmikkisi">
                              </ng-template>
                              <div class="d-flex flex-column">
                                <div class="doc-name">
                                  <p class="ms-2 doc-show-text body1"> {{ doc.name }}</p>
                                </div>
                                <div class="doc-designation">
                                  <p class="ms-2 doc-show-text body3"> {{ doc.designation }}</p>
                                </div>
                              </div>
                            </div>
                        </li>
                        </div>
                      </div>
                      <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                    </div>
                  </div>
    
                </div>
              </div>
                
    
                
                <!-- TEMPLATE 17 Basic -->
                <div class="" *ngIf=" message.template == 'TEMPLATE_17' " [ngClass]="{'disabled-input': message.oldMessage}">
                    <div class="col-md-12 no-padding" *ngFor="let data of message.endcomes">
                        <div class="row-0 m-0" *ngIf="data.contact; else no_contact_content">
                            <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                                <div class="text-message text-message-left break-word w-100">
                                    <div class="col-12">
                                        <p class="text-message-left-text">{{data.title}}</p>
                                        <input placeholder="Sähköpostiosoite" type="email" class="form-control" id="user-email">
                                        <p class="text-message-left-text mt-3">Haluatko antaa meille lisätietoja?</p>
                                        <textarea placeholder="Kirjoita viestisi tähän..." class="form-control" id="user-text"></textarea>
                                        <button class="btn btn-secondary mt-4" (click)="sendContactDetails(message)">Lähetä</button>
                                        </div>
                                        </div>
                            </div>
                        </div>
                        <ng-template #no_contact_content>
                            <div class="row m-0" *ngIf="!data.imageURL; else no_img_content">
      
                                <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                                  <div class="text-message text-message-left break-word w-100">
                                        <p class="text-message-left-text">{{data.title}}</p>
                                        <p class="text-message-left-text" *ngIf="data.price">{{data.price}}€</p>
                                        <p class="text-message-left-text">{{data.description}}</p>
                                        <button *ngIf="data.url" class="btn btn-feedbacker"><a href="{{data.url}}" target="_blank" rel="noopener noreferrer">Katso</a></button>
                                    </div>
                                  </div>
                              </div>
                              <ng-template #no_img_content>
    
                                <div class="row m-0">
                    
                                    <div class="col-10 col-md-6 col-lg-6 col-xl-6 p-0 m-1">
                                      <div class="d-flex justify-content-start p-0">
                                        <div class="text-message text-message-left whitespace-preline">
                                          <div class="image-container">
                                            <h6 class="text-message-left-text">{{data.title}}</h6>
                                            <div class="image-container">
                                              <img class="kuvaviesti" src="{{data.imageURL}}" alt="">
                                            </div>
                                            <p class="text-message-left-text" *ngIf="data.price">{{data.price}}€</p>
                                            <p class="text-message-left-text"  *ngIf="data.description">{{data.description}}</p>
                                            <button *ngIf="data.url" class="btn btn-feedbacker"><a href="{{data.url}}" target="_blank" rel="noopener noreferrer">Katso</a></button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-1 col-lg-5 col-xl-5"></div>
                    
                                  </div>
                              </ng-template>
                        </ng-template>

                    </div>
                </div>

  
                
              
              </div>
            </div>

    
            </div><!-- chat message main container :: END -->
  
  
    
            <!-- Reply Box -->
              <div *ngIf="!(this.currentActiveDoctorName === DR_ASSISTANT_NAME || this.currentActiveDoctorName === HELPER_NAME)">
              <div class="row reply me-0 ms-0 mt-4 col-12 p-0 pt-1 pb-1 ps-2" *ngIf="showReplyBox">
                <div class="col-1 p-0 ps-md-2 me-3 me-md-0 text-center mt-2">
                  <div class="image-icon-wrapper mt-1">
                    <div class="btn-group dropup">
                      <label class="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" class="pull-right "><i class="fas fa-plus veteva-icon" aria-hidden="true" for="input"></i></label>
        
                      <ul class="dropdown-menu">
                        <li class="dropdown-item"><label class="pointer" for="files"><i class="fas fa-camera me-2"></i>Kuvatiedosto</label></li>
                        <li class="dropdown-item"><label class="pointer" for="videos"><i class="fas fa-video me-2"></i>Videotiedosto</label></li>
                      </ul>
                    </div>
                    <input style="display:none" (change)="handleFiles($event)" type="file" accept=".jpg, .jpeg, .png" id="files"/>
                    <input style="display:none" (change)="handleVideos($event)" type="file" accept="video/mp4, video/quicktime, .mov, .mp4" id="videos"/>
                  </div>
                </div>
                <div class="col-8 ps-0 reply-main ps-0 pe-0 mt-2">
                  <textarea class="send-message-input" autocomplete="off" rows="1"  id="comment" [(ngModel)]="replyMessage" placeholder="Kirjoita tähän..."
                  (keydown)="[UserTypingMessage(),autoTextarea($event) ]" (keyup.enter)="OnSendReply($event)"></textarea>
                </div>
                <div class="col-2 col-md-2 ps-2 pe-0 mt-1 mt-md-0">
                  <!-- <button class="send-btn" (click)="OnSendReply($event)">Lähetä</button> -->
                  <div class="send-btn-container pointer float-end text-center mt-1" (click)="OnSendReply($event)">
                    <i class="fas fa-arrow-up white-icon send-icon"></i>
                  </div>
                  <!-- <i class="fa fa-send fa-2x" aria-hidden="true" (click)="OnSendReply($event)"></i> -->
                </div>
      
            </div>
          </div>
          <p style="display:none">Kuva:</p>
          <canvas style="display:none" id="canvas" width=700 height=700></canvas>
            <!-- Reply Box End -->
    
    
          </div> <!-- chat windeow main container :: END -->
  
        </div>
        <!--Section two :: END-->
</div>
