<app-user-header></app-user-header>
<div class="bg-container header pnset-6 container-fluid pt-4 ps-4 pe-4">
    <div class="col-12 row pb-4">
        <div class="col-12 col-md-7 page-title">
            <p class="page-title h2 text-white d-inline-block mb-0">Vertaile kyselyitä</p> <br>
        </div>
        <div class="col">
            <nav class="d-none d-md-inline-block ml-md-4">
                <ol class="breadcrumb breadcrumb-links breadcrumb-dark">
                    <li class="breadcrumb-item">
                        <a routerLink="/{{businessID}}/users/home">Koti</a>
                    </li>
                    <li class="breadcrumb-item">
                        <a routerLink="/{{businessID}}/feedbacks">Palautekyselyt</a>
                    </li>
                    <li class="breadcrumb-item active">
                        <a href="#">Vertailu</a>
                    </li>
                </ol>
            </nav>  
        </div>
    </div>
    
</div>


<div class="col-12 p-0 p-md-4">
    <div class="card ">
        <div class="card-header">
            <div class="row-align-items-center">
                <div class="col">
                    <h6 class="text-dark text-uppercase ls-1 mb-1">Palautekysely</h6>
                    <h5 class="h3 text-black mb-0">Kehitys</h5>
                </div>
            </div>

            <h1 class="text-center"></h1>
            <div class="text-center">
            </div>
            <div [hidden]="!chart">
                <canvas class=" w-100 mt-4" id="my-chart" style="max-height:350px; min-height:250px"  >{{chart}}</canvas>
            </div>
        </div>
        
    </div>
</div>

<div class="col-12 p-0 p-md-4">
    <div class="card ">
        <div class="card-header">
            <div class="row-align-items-center">
                <div class="col">
                    <h6 class="text-dark text-uppercase ls-1 mb-1">Palautekysely</h6>
                    <h5 class="h3 text-black mb-0">Kehitys</h5>
                </div>
            </div>

            <h1 class="text-center"></h1>
            <div class="text-center">

            </div>
            <div [hidden]="!SMIchart">
                <canvas class=" w-100 mt-4" id="my-smi-chart" style="max-height:350px; min-height:250px"  >{{SMIchart}}</canvas>
            </div>
        </div>
        
    </div>
</div>