import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';

@Component({
  selector: 'app-business-certificates',
  templateUrl: './business-certificates.component.html',
  styleUrls: ['./business-certificates.component.css']
})
export class BusinessCertificatesComponent implements OnInit {

  errMessage = "";
  successMessage = "";
  token:string = this.route.snapshot.params.token;
  businessID: string = this.route.snapshot.params.businessID;
  type: string = this.route.snapshot.params.type;
  selection: string = this.route.snapshot.params.selection;
  company: string = this.route.snapshot.params.company;
  interval: string = this.route.snapshot.params.interval;
  typeText;
  myUUID;
  feedback;
  closeText = false;
  timer;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    withCredentials: false,
  };

  constructor(
    private router: Router,
    private http: HttpClient,
    private route:ActivatedRoute,
    private authService: AuthService) {
      this.authService.validateBusiness(this.businessID)

     }

  ngOnInit(): void {
    if(this.selection == "question"){
      console.log('cert')
      this.getQuestionCert();
    }
    else{
      this.getCert();
    }
  }

  getQuestionCert() {
    var req = {
      businessID: this.businessID,
      token: this.token,
      type: this.type,
      interval: this.interval,
    }

    this.http.post(environment.GENERATE_QUESTION_CERTIFICATE,req, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {
          console.log(res);

          if(this.type == 'nps'){
            this.typeText = "NPS"
          }
          else if(this.type == "average"){
            this.typeText = "Keskiarvo"
          }
          else if(this.type == "csat"){
            this.typeText = "CSAT"
          }

          this.feedback = res.message;

          this.waitStyle();



        }
        else if (res.status == 203){
          this.typeText = "Authentication failed"
        }
        else {
          var anElm = document.getElementById('notadded');
          anElm.style.display="block";
          console.log('ei ole kyseistä käyttäjää')
        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })

  }

  getCert() {
    var req = {
      businessID: this.businessID,
      token: this.token,
      type: this.type,
    }

    this.http.post(environment.GENERATE_CERTIFICATE,req, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {
          console.log(res);

          if(this.type == 'nps'){
            this.typeText = "NPS"
          }
          else if(this.type == "average"){
            this.typeText = "Keskiarvo"
          }

          this.feedback = res.message;

          this.waitStyle();



        }
        else {
          var anElm = document.getElementById('notadded');
          anElm.style.display="block";
          console.log('ei ole kyseistä käyttäjää')
        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })

  }


  styleChart(){
    var full = document.getElementById('mask-full');
    var fullFill = document.getElementById('mask-full-fill');
    var half = document.getElementById('mask-half');
    console.log(this.feedback.value)

    if(this.feedback.value < 20){
      full.style.backgroundColor = "#C31666"
      half.style.backgroundColor = "#C31666"
    }
    else if (this.feedback.value <= 40){
      full.style.backgroundColor = "#FDB92B"
      half.style.backgroundColor = "#FDB92B"
    }
    else if (this.feedback.value <= 60){
      full.style.backgroundColor = "#7F07C9"
      half.style.backgroundColor = "#7F07C9"
    }
    else if (this.feedback.value >= 80){
      full.style.backgroundColor = "#4BBD93"
      half.style.backgroundColor = "#4BBD93"
    }
    else if (this.feedback.value <= 100){
      full.style.backgroundColor = "#4ACC43"
      half.style.backgroundColor = "#4ACC43"
    }

    var number = Math.floor(180/100 *this.feedback.value );
    var str = "rotate("+number.toString()+"deg)";
    


    half.animate([
      // keyframes
      { transform: str },
    ], {
      // timing options
      duration: 1000,
    });

    full.animate([
      // keyframes
      { transform: str },
    ], {
      // timing options
      duration: 1000,
      iterations: 1,
    });

    half.animate([
      // keyframes
      { transform: str },
    ], {
      // timing options
      duration: 1000,
      iterations: 1,
    });

    fullFill.animate([
      // keyframes
      { transform: str },
    ], {
      // timing options
      duration: 1000,
      iterations: 1,
    });

    this.waitAnimate()




  }

  waitStyle() {
    var that = this;

    setTimeout(() =>{
      that.styleChart()
      
    },100)

  }

  waitAnimate() {
    var that = this;

    setTimeout(() =>{
      var full = document.getElementById('mask-full');
      var fullFill = document.getElementById('mask-full-fill');
      var half = document.getElementById('mask-half');

      var number = Math.floor(180/100 *this.feedback.value );
      var str = "rotate("+number.toString()+"deg)";
      
        fullFill.style.transform=str;
        full.style.transform=str;
        half.style.transform=str;
      
    },1000)

  }


}
