import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-business-details',
  templateUrl: './edit-business-details.component.html',
  styleUrls: ['./edit-business-details.component.css']
})
export class EditBusinessDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
