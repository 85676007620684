<app-user-header></app-user-header>
<div class="bg-container header pnset-6 container-fluid pt-4 ps-4 pe-4">
    <div class="col-12 row pb-4">
        <div class="col-12 col-md-7 page-title">
            <p class="page-title h2 text-white d-inline-block mb-0">Tilauksesi</p> <br>
        </div>
        <div class="col">
            <nav class="d-none d-md-inline-block ml-md-4">
                <ol class="breadcrumb breadcrumb-links breadcrumb-dark">
                    <li class="breadcrumb-item">
                        <a routerLink="/{{businessID}}/users/home">Koti</a>
                    </li>
                    <li class="breadcrumb-item active">
                        <a href="#">Tilaus</a>
                    </li>
                </ol>
            </nav>  
        </div>
    </div>
    </div>
<div class="full">


<div class="col-12 ps-4 row nset-card">
    <div class="col-xl-8">
        <div class="card card-bg-question">
            <div class="card-header">
                <div class="row align-items-center">
                    <div class="col">
                        <h6 class="text-light text-uppercase ls-1 mb-1">Lisäosat</h6>
                        <h5 class="h3 text-white mb-0">Kasvata pakettiasi</h5>
                    </div>
                    <div class="col">
                        <ul class="nav nav-pills justify-content-end">
                            <li class="nav-item mr-2 mr-md-0">
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table align-items-center table-flush">
                    <thead class="thead-dark">
                        <tr>
                            <th>Tieto</th>
                            <th>Vastaus</th>
                            <th>Hinta</th>
                        </tr>
                    </thead>
                    <tbody class="tbody-dark">
                        <tr *ngIf="!order.web.payingCustomer">
                            <td width="25%">Web paketti</td>
                            <td width="65%">
                                <p>Web-paketti sisältää analytiikan ja vertailun</p>
                            </td>
                            <td width="10%">4,9€/kk</td>
                        </tr>
                        <tr *ngIf="!order.certificates.webCertificate">
                            <td width="25%">Sertifikaatti Web</td>
                            <td width="65%">
                                <p>Web-sertifikaatti mahdollistaa NPS:n tai keskiarvon esittämisen verkkosivuilla.  </p>
                            </td>
                            <td width="10%">5€/kk/kpl</td>
                        </tr>
                        <tr *ngIf="!order.certificates.printCertificate">
                            <td width="25%">Sertifikaatti Tulostettava</td>
                            <td width="65%">
                                <p>Tulostettava-sertifikaatti mahdollistaa NPS:n tai keskiarvon esittämisen myymälässä tai muualla.  </p>
                            </td>
                            <td width="10%">5€/kk/kpl</td>
                        </tr>
                        <tr *ngIf="order.pos.deviceNumber == 0">
                            <td width="25%">Tabletti</td>
                            <td width="65%">
                                <p>Tabletti on liikkeeseen asennettava palautekyselijä. Sisältää tabletin ja telineen.</p>
                            </td>
                            <td width="10%">28€/kk</td>
                        </tr>
                    </tbody>

                </table>
            </div>
            
        </div>

    </div>
    <div class="col-xl-4">
        <div class="card ">
            <div class="card-header">
                <div class="row-align-items-center">
                    <div class="col">
                        <h6 class="text-dark text-uppercase ls-1 mb-1">Pakettiisi sisältyy</h6>
                        <h5 class="h3 text-black mb-0">Tiedot</h5>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table align-items-center table-flush">
                        <thead class="thead-light">
                            <tr>
                                <th>Tieto</th>
                                <th>Vastaus</th>
                            </tr>
                        </thead>
                        <tbody class="tbody-light" *ngIf="order && order.web">
                            <tr>
                                <th width="25%">Palautekyselyitä</th>
                                <td width="75%">{{order.web.questionNumber}}</td>
                            </tr>
                            <tr>
                                <th width="25%">Vertailu</th>
                                <td width="75%">
                                    <span *ngIf="order.web.payingCustomer == false">Ei</span>
                                    <span *ngIf="order.web.payingCustomer == true">Kyllä</span>
                                </td>
                            </tr>
                            <tr>
                                <th width="25%">Graafit</th>
                                <td width="75%">
                                    <span *ngIf="order.web.payingCustomer == false">Ei</span>
                                    <span *ngIf="order.web.payingCustomer == true">Kyllä</span>
                                </td>
                            </tr>
                            <tr>
                                <th width="25%">Sähköposti kampanja</th>
                                <td width="75%">
                                    0,03€/email (min.laskutus 5€)
                                </td>
                            </tr>
                            <tr>
                                <th width="25%">Tulostettavat sertifikaatit</th>
                                <td width="75%">
                                    <span *ngIf="order.certificates.printCertificate == false">Ei</span>
                                    <span *ngIf="order.certificates.printCertificate == true">Kyllä</span>
                                </td>
                            </tr>
                            <tr>
                                <th width="25%">Verkkoasivuille sertifikaatit</th>
                                <td width="75%">
                                    <span *ngIf="order.certificates.webCertificate == false">Ei</span>
                                    <span *ngIf="order.certificates.webCertificate == true">Kyllä</span>
                                </td>
                            </tr>
                            <tr>
                                <th width="25%">Palautekyselyitä</th>
                                <td width="75%">{{order.pos.deviceNumber}}</td>
                            </tr>

                        </tbody>

                    </table>
                </div>
            </div>
            
        </div>
    </div>
</div>

</div>