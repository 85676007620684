import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { LoginComponent } from './login/login.component';
import { NotAuthenticatedGuard } from './guards/not-authenticated.guard';
import { AlwaysAuthenticatedGuard } from './guards/always-authenticated.guard';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { UserHomeComponent } from './user-home/user-home.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ActivateUserComponent } from './activate-user/activate-user.component';
import { CreateFeedbackComponent } from './create-feedback/create-feedback.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { FeedbackListComponent } from './feedback-list/feedback-list.component';
import { FeedbackDetailsComponent } from './feedback-details/feedback-details.component';
import { CompanyDetailsComponent } from './company-details/company-details.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { RegisterUserComponent } from './register-user/register-user.component';
import { OrderComponent } from './order/order.component';
import { EditBusinessDetailsComponent } from './edit-business-details/edit-business-details.component';
import { EditCompanyComponent } from './edit-company/edit-company.component';
import { SelectBusinessComponent } from './select-business/select-business.component';
import { CreateFeedbacCampaignComponent } from './create-feedbac-campaign/create-feedbac-campaign.component';
import { FeedbackEmailAnswerComponent } from './feedback-email-answer/feedback-email-answer.component';
import { DeviceHubComponent } from './device-hub/device-hub.component';
import { DeviceComponent } from './device/device.component';
import { DevicesComponent } from './devices/devices.component';
import { EditDeviceComponent } from './edit-device/edit-device.component';
import { BusinessCertificatesComponent } from './business-certificates/business-certificates.component';
import { CompareFeedbacksComponent } from './compare-feedbacks/compare-feedbacks.component';
import { CreateSpinnerComponent } from './create-spinner/create-spinner.component';
import { SpinnersComponent } from './spinners/spinners.component';
import { SpinnerDetailsComponent } from './spinner-details/spinner-details.component';
import { BusinessSpinnersComponent } from './business-spinners/business-spinners.component';
import { CalendarComponent } from './calendar/calendar.component';
import { CustomerCalendarComponent } from './customer-calendar/customer-calendar.component';
import { CreateCalendarComponent } from './create-calendar/create-calendar.component';
import { PaymentRegisterComponent } from './payment-register/payment-register.component';
import { ReservationsComponent } from './reservations/reservations.component';
import { PaymentComponent } from './payment/payment.component';
import { CalendarsComponent } from './calendars/calendars.component';
import { HomeComponent } from './home/home.component';
import { ModelsComponent } from './models/models.component';
import { SingleModelComponent } from './single-model/single-model.component';
import { TypesComponent } from './types/types.component';
import { CreateTypeComponent } from './create-type/create-type.component';

const routes: Routes = [
  {
    path: ':businessID/login',
    component: LoginComponent,
    canActivate: [NotAuthenticatedGuard]
  },
  {
    path: 'select-business',
    component: SelectBusinessComponent,

  },
  {
    path: 'admin-login',
    component: AdminLoginComponent,
    canActivate: [NotAuthenticatedGuard]
  },
  {
    path: 'admin/dashboard',
    component: AdminDashboardComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':businessID/users/home',
    component: UserHomeComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':businessID/calendar/:id',
    component: CalendarComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':businessID/create-calendar',
    component: CreateCalendarComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':businessID/edit-calendar',
    component: CreateCalendarComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':businessID/payments',
    component: PaymentRegisterComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':businessID/payment-details/:id',
    component: PaymentComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':businessID/reservations',
    component: ReservationsComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':businessID/calendars',
    component: CalendarsComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':businessID/reset-password/:id',
    component: ResetPasswordComponent,
    canActivate: [NotAuthenticatedGuard]
  },
  {
    path: ':businessID/forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [NotAuthenticatedGuard]
  },
  {
    path: ':businessID/activate-user/:token',
    component: ActivateUserComponent,
    canActivate: [NotAuthenticatedGuard]
  },
  {
    path: ':businessID/create-feedback',
    component: CreateFeedbackComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':businessID/edit-feedback/:id',
    component: CreateFeedbackComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':businessID/edit-spinner/:id',
    component: CreateSpinnerComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':businessID/feedbacks',
    component: FeedbackListComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':businessID/compare-feedbacks/:feedbacks',
    component: CompareFeedbacksComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':businessID/feedback/:id',
    component: FeedbackDetailsComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':businessID/device/:id',
    component: DeviceComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },  
  {
    path: ':businessID/devices',
    component: DevicesComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':businessID/edit-device/:id',
    component: EditDeviceComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':businessID/create-campaign/:id',
    component: CreateFeedbacCampaignComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':businessID/create-spinner',
    component: CreateSpinnerComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':businessID/spinner-details/:id',
    component: SpinnerDetailsComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':businessID/models',
    component: ModelsComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':businessID/types',
    component: TypesComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':businessID/create-type',
    component: CreateTypeComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':businessID/single-model/:id',
    component: SingleModelComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':businessID/spinners',
    component: SpinnersComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':businessID/company-details',
    component: CompanyDetailsComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':businessID/user-details/:id',
    component: RegisterUserComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':businessID/register-user',
    component: RegisterUserComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':businessID/edit-business',
    component: EditCompanyComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':businessID/order',
    component: OrderComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: 'feedback/:businessID/:token/:spot',
    component: FeedbackComponent,
  },
  {
    path: 'feedback/email/:businessID/:token/:value',
    component: FeedbackEmailAnswerComponent,
  },
  {
    path: 'device-hub/:businessID/:deviceID',
    component: DeviceHubComponent,

  },
  {
    path: 'certificates/:company/:businessID/:token/:type/:interval/:selection',
    component: BusinessCertificatesComponent,
  },
  {
    path: 'spinners/:businessID/:token',
    component: BusinessSpinnersComponent,
  },
  {
    path: 'calendar/:businessID/:token',
    component: CustomerCalendarComponent,
  },
  {
    path: 'home/:businessToken/:typeID',
    component: HomeComponent,
  },

  {
    path: '',
    pathMatch: 'full',
    redirectTo: '1/login'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule {
  
 }
