
<app-user-header></app-user-header>


<div  class="wrapperi">

  <div class="bg-container header pnset-6 container-fluid pt-4 ps-4 pe-4">
    <div class="col-12 row pb-4">
        <div class="col-12 col-md-7 page-title">
            <p class="page-title h2 text-white d-inline-block mb-0">{{btnText}}</p> <br>
            <p class="desc-text text-white">Täytä alla olevat tiedot.</p>
        </div>
        <div class="col">
            <nav class="d-none d-md-inline-block ml-md-4">
                <ol class="breadcrumb breadcrumb-links breadcrumb-dark">
                    <li class="breadcrumb-item">
                        <a routerLink="/{{businessID}}/users/home">Koti</a>
                    </li>
                    <li class="breadcrumb-item">
                      <a routerLink="/{{businessID}}/spinners">Esittelyt</a>
                    </li>
                    <li class="breadcrumb-item active">
                        <a href="#">Luo </a>
                    </li>
                </ol>
            </nav>  
        </div>
    </div>
    <div class="col-12 row">

    </div>
  </div>


  <div class="col-12 p-0 row m-0">
    <div class="col-3"></div>
    <div class="col-12 col-md-6 p-0 input-cover mt-4 p-4">
      <div class="col-12 p-0 mt-4">

      </div>

      <div id="loader" class="loader-container mt-4" style="display:none">
        <div class="loader mx-auto"></div>
      </div>

      <div style="display: block;" id="form-container" class="d-flex flex-row justify-content-center ml-4">
        <form class="flex-row add-pet-form w-100" [formGroup]="form"
        (ngSubmit)="onSubmit()">
          <!-- Sähköpostiosoite ja puhelinnumero -->
          <div class="col-12 row m-0 pt-2">

            <div class="form-floating mb-2 col-12  p-0">
              <input type="text" placeholder="Palautteen nimi" formControlName="name"  class="form-control">
              <label for="name">Esittelyn nimi(pakollinen)</label>
            </div>
          </div>

          <div class="col-12 row m-0 pt-2">

            <div class="form-floating mb-2 col-12 col-md-6 p-0">
                <input type="color"  placeholder="Tekstinväri" formControlName="textColor" id="textColor" class="form-control" >
                <label for="textColor">Tekstinväri (pakollinen)</label>
            </div>

            <div class="form-floating ps-md-2  mb-2 col-12 col-md-6 p-0">
                <input type="color" placeholder="Taustaväri" formControlName="backgroundColor" id="backgroundColor" class="form-control">
                <label class="ps-md-4" for="backgroundColor">Taustaväri (pakollinen)</label>
            </div>
            </div>



          



          
                
            <div class="form-inline mt-2">
              <div class="form-group mb-2 flex-row">
                <button [disabled]="form.invalid" class="btn btn-feedbacker w-100"><i class="fas fa-sign-in-alt me-2"></i>{{btnText}}</button>
              </div>
            </div>
          </form>

        </div>
      </div>
      <div class="col-3"></div>
</div>