<!-- <div class="slideshow-container">

    <div class="mySlides">
      <q>I love you the more in that I believe you had liked me for my own sake and for nothing else</q>
      <p class="author">- John Keats</p>
    </div>
    
    <div class="mySlides">
      <q>But man is not made for defeat. A man can be destroyed but not defeated.</q>
      <p class="author">- Ernest Hemingway</p>
    </div>
    
    <div class="mySlides">
      <q>I have not failed. I've just found 10,000 ways that won't work.</q>
      <p class="author">- Thomas A. Edison</p>
    </div> -->

    <div id="nset-bg" class="slideshow-container" *ngIf="spinner && spinner.answers && spinner.answers.length > 0">

        <div class="" *ngFor="let data of spinner.answers; let i = index">
            <div class="nset-container mySlides pt-4">

               <q id="nset-text-{{i}}" class="pt-4">{{data.text }}  </q>
                <p id="nset-author-{{i}}" class="author">{{data.createdDate | formateDate}}</p>
         </div>
        </div>
        <div class="my-box">
            <img class="nset-img" src="../../assets/images/moodysetlogo_b.png" width="120px">
         </div>
    </div>
    
    <a class="prev" (click)="plusSlides(-1)"></a>
    <a class="next" (click)="plusSlides(1)"></a>
    
    
    <div class="dot-container">
        <span class=""*ngFor="let data of spinner.answers; let i = index">
            <span class="dot" (click)="currentSlide(i+1)"></span> 

        </span>
    </div>